<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<edit-member
			:edit-mode="true"
			:member="memberToEdit"
			:organization="organization"
			:show-slideover="showEditMember"
			@interface="showEditMember = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div
				v-if="orgMembers.length == 0"
				class="tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg"
			>
				<p>
					There are no members in this organization yet.
					<a
						v-if="canManageBilling"
						:href="'/orgs/'+organization.slug+'/settings/billing'"
						class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-cursor-pointer"
					>
						Add members to this organization now!
					</a>
				</p>
			</div>
			<div v-else>
				<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<label
							for="OrganizationsSearch"
							class="tw-sr-only"
						>
							Search
						</label>
						<div class="tw-relative tw-rounded-md tw-shadow-sm">
							<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
								<!-- Heroicon name: solid/search -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<input
								v-model="search"
								name="membersSearch"
								id="membersSearch"
								type="search"
								class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="Search"
							>
							<!-- Heroicon name: x -->
							<div
								v-if="search != ''"
								class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
							>
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									@click="clearSearch"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<div class="tw-flex tw-gap-3">
							<select
								v-model="statusFilter"
								id="type"
								name="type"
								class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
								<option value="all">
									All Members
								</option>
								<option value="pending">
									Pending
								</option>
								<option value="accepted">
									Accepted
								</option>
							</select>
							<button
								type="button"
								class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
								@click="resetFilters"
								v-tooltip="'Reset Filters'"
							>
								<!-- Heroicon name: solid/x -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="tw-sr-only">Reset Filters</span>
							</button>
							<a
								v-if="canManageBilling"
								:href="'/orgs/'+organization.slug+'/settings/billing'"
							>
								<button
									type="button"
									class="tw-w-48 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
								>
									Manage Members
								</button>
							</a>
						</div>
					</div>
				</div>

				<div
					v-if="filteredMembers.length != 0 && !loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						Showing <span class="tw-font-bold">{{ mainFilter }}</span> members
					</div>
					<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
						Total {{ statusFilter != "all" ? statusFilter : "" }} members: {{ filteredMembers.length }}
					</div>
				</div>

				<div
					v-else-if="loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						<div
							class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
						>
							<div
								class="tw-btn-spinner tw-mt-1"
							/>
							<span class="tw-ml-2">Loading...</span>
						</div>
					</div>
				</div>

				<div class="tw-bg-white">
					<!-- Packages table (small breakpoint and up) -->
					<div class="tw-mt-3">
						<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
							<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
								<thead>
									<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
										<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											<span class="lg:tw-pl-2">
												Name
											</span>
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Role
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Can Create Packages?
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Teams
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Joined On
										</th>
										<th class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
									</tr>
								</thead>
								<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
									<tr
										v-if="filteredMembers.length === 0 && !loading"
										class="tw-bg-gray-50"
									>
										<td
											colspan="7"
											class="tw-pb-6 tw-text-center"
										>
											<svg
												class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
											<p class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
												No members found
											</p>
										</td>
									</tr>
									<tr
										v-for="(thisMember) in filteredMembers"
										is="MemberRow"
										:organization="organization"
										:member="thisMember"
										:user="user"
										:can-manage-billing="canManageBilling"
										:can-change-role="canChangeRole"
										:key="thisMember.orgMemberId"
										class="hover:tw-bg-gray-50"
									/>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MemberRow from "./MemberRow";
import EditMember from "./Edit";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : { MemberRow, EditMember },
	data() {
		return {
			menu           : [ { label: "Members" } ],
			showEditMember : false,
			orgMembers     : this.organization.members,
			memberToEdit   : {},
			search         : "",
			loading        : false,
			errors         : [],
			notification   : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			},
			mainFilter   : "all",
			statusFilter : "all",
			pagination   : {
				startRow  	: 0,
				maxRow    	: 0,
				records   	: 0,
				page      	: 1,
				totalPages	: 1
			}
		};
	},
	mounted(){
		var app = this;
	},
	computed : {
		filteredMembers(){
			switch ( this.statusFilter ) {
			case "all":
				return this.orgMembers.filter( m => this.localSearch( m.user ) );;
			case "pending":
				return this.orgMembers.filter( m => !m.isAccepted && this.localSearch( m.user ) );
			case "accepted":
				return this.orgMembers.filter( m => m.isAccepted && this.localSearch( m.user ) );
			default:
				return this.orgMembers.filter( m => this.localSearch( m.user ) );
			}
		},
		canManageBilling() {
			return this.member.role.role == "Super Admin";
		},
		canChangeRole() {
			return this.member.role.role == "Super Admin" || this.member.role.role == "Admin";
		}
	},
	methods : {
		filterResults( filter ) {
			let app = this;
			app.mainFilter = filter;
		},
		clearSearch() {
			let app = this;
			app.search = "";
		},
		resetFilters() {
			let app = this;
			app.search = "";
			app.statusFilter = "all";
		},
		localSearch( user ) {
			var app = this;
			if ( ( user.username.toLowerCase().includes( app.search.toLowerCase() ) || user.fullName.toLowerCase().includes( app.search.toLowerCase() ) ) || app.search == "" ) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>