<template>
	<div>
		<version-form
			:edit-mode="true"
			:this-package="thisPackage"
			:package-version="packageVersion"
			:organization="organization"
			:is-org="isOrg"
		/>
	</div>
</template>

<script>
import VersionForm from "./VersionForm";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		packageVersion : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { VersionForm },
	data() {
		return {};
	},
	mounted(){
	},
	computed : {}
};
</script>