<template>
  <a
    href="##"
    class="btn btn-neutral btn-icon-only rounded-circle"
    data-toggle="tooltip"
    data-placement="top"
    data-original-title="Toggle Fullscreen"
    @click.prevent="toggleFullscreen()"
  >
    <span class="btn-inner--icon">
      <i class="fa fa-window-maximize" />
    </span>
  </a>
</template>
<script>
// import Vue from "vue";
export default {
	data(){
		return {
			fullscreen	: false
		};
	},
	methods : {
		toggleFullscreen(){
			this.$fullscreen.toggle( Vue.$el, {
			  	wrap   		: false,
			  	callback	: this.fullscreenChange
			} );
		},
		fullscreenChange( fullscreen ){
			this.fullscreen = fullscreen;
		}
	}
};
</script>