<template>
	<nav
		aria-label="Breadcrumb"
		class="tw-border-b tw-border-gray-200 tw-px-4 tw-flex tw-flex-row sm:tw-px-6 lg:tw-px-8 tw-h-16"
	>
		<ol class="tw-flex tw-w-full tw-flex-row">
			<li class="tw-flex">
				<div class="tw-flex tw-items-center">
					<a
						href="/myaccount"
						class="tw-text-gray-400 hover:tw-text-gray-500"
					>
						<!-- Heroicon name: solid/home -->
						<svg
							class="tw-flex-shrink-0 tw-h-5 tw-w-5"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
							/>
						</svg>
						<span class="tw-sr-only">Home</span>
					</a>
				</div>
			</li>
			<li
				v-for="item of menu"
				:key="item.label"
				class="tw-flex tw-self-center"
			>
				<div class="tw-flex tw-items-center">
					<svg
						class="tw-flex-shrink-0 tw-w-6 tw-h-16 tw-text-gray-200"
						viewBox="0 0 24 44"
						preserveAspectRatio="none"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
					</svg>
					<span v-if="item.url != ''">
						<a
							:href="item.url"
							class="tw-ml-4 tw-text-base tw-text-gray-500 hover:tw-text-gray-700"
						>{{ item.label }}
						</a>
					</span>
					<span
						v-else
						class="tw-ml-4 tw-text-base tw-text-gray-500"
					>
						{{ item.label }}
					</span>
				</div>
			</li>
		</ol>
		<slot name="buttons" />
	</nav>
</template>

<script>
export default {
	props : {
		menu : {
			type    : Array,
			default : function() {
				return [
					{
						label : "Dashboard",
						url   : ""
					}
				];
			}
		}
	},
	computed : {}
};
</script>
