<template>
	<div class="tw-fixed tw-z-50 tw-inset-0 tw-flex tw-items-end tw-justify-center tw-px-4 tw-py-6 tw-pointer-events-none sm:tw-mt-12 sm:tw-p-6 sm:tw-items-start sm:tw-justify-end">
		<transition
			enter-class="tw-opacity-0 tw-translate-y-2 sm:tw-translate-y-0 sm:tw-translate-x-2"
			enter-to-class="tw-opacity-100 tw-translate-y-0 sm:tw-translate-x-0"
			enter-active-class="tw-transform tw-ease-out tw-duration-300 tw-transition"
			leave-class="tw-opacity-100"
			leave-to-class="tw-opacity-0"
			leave-active-class="tw-transition tw-ease-in tw-duration-300"
		>
			<div
				v-if="show != ''"
				class="tw-w-full tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-lg tw-pointer-events-auto tw-mt-3"
			>
				<div class="tw-overflow-hidden tw-rounded-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
					<div class="tw-p-4">
						<div class="tw-flex tw-items-start">
							<div class="tw-flex-shrink-0">
								<svg
									v-if="type === 'success'"
									class="tw-w-6 tw-h-6 tw-text-green-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								<svg
									v-else-if="type === 'warning'"
									class="tw-w-6 tw-h-6 tw-text-yellow-400"
									fill="currentColor"
									viewBox="0 0 20 20"
									stroke="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								<svg
									v-else-if="type === 'error'"
									class="tw-w-6 tw-h-6 tw-text-red-400"
									fill="currentColor"
									viewBox="0 0 20 20"
									stroke="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								<!-- Otherwise show the and informative notification -->
								<svg
									v-else
									class="tw-w-6 tw-h-6 tw-text-blue-400"
									fill="currentColor"
									viewBox="0 0 20 20"
									stroke="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
								<p class="tw-text-sm tw-font-medium tw-text-gray-900 tw-leading-5">
									{{ title }}
								</p>
								<p class="tw-mt-1 tw-text-sm tw-text-gray-500 tw-leading-5">
									{{ message }}
								</p>
							</div>
							<div class="tw-flex tw-flex-shrink-0 tw-ml-4">
								<button
									class="tw-inline-flex tw-text-gray-400 focus:tw-outline-none focus:tw-text-gray-500 tw-transition tw-ease-in-out tw-duration-150"
									@click="show = false"
								>
									<svg
										class="tw-w-5 tw-h-5"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props : {
		// Flash Message Type (info, success, warning, error)
		type : {
			type    : String,
			default : "info"
		},
		title : {
			type     : String,
			required : false,
			default  : ""
		},
		message : {
			type     : String,
			required : true,
			default  : ""
		}
	},
	mounted() {
		this.show = this.message != "";
		if ( this.show ) {
			this.currentTimeout = setTimeout( () => {
				this.show = false;
			}, 6000 );
		}
	},
	data() {
		return {
			show           : false,
			currentTimeout : null
		};
	},
	computed : {}
};
</script>