<template>
	<div class="tw-overflow-hidden tw-divide-y tw-divide-x tw-divide-gray-200 sm:tw-divide-y-0 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px">
		<div>
			<div class="tw-bg-gray-100 tw-shadow tw-p-3 ">
				<p class="tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase">
					Collaborations
				</p>
			</div>
			<div
				v-if="collaborations.length == 0"
				class="tw-my-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						No collaboration invites
					</p>
					<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Currently, you don't have any pending packages collaboration invites.
						</p>
					</div>
				</div>
			</div>
			<div
				v-else
				class="tw-flow-root tw-mx-3"
			>
				<ul
					role="list"
					class="tw-divide-y tw-divide-gray-200"
				>
					<li
						v-for="collaboration in collaborations"
						:key="collaboration.collaborationID"
						class="tw-flex tw-items-center tw-py-2 tw-space-x-3"
					>
						<div class="tw-min-w-0 tw-flex-1">
							<p>
								<a
									class="tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-400"
									:href="`/view/${collaboration.entry.slug}`"
								>
									{{ collaboration.entry.title }}
								</a>
							</p>
							<p class="tw-text-sm tw-text-gray-500">
								Invite sent by:
								<a
									class="tw-text-blue-600 hover:tw-text-blue-500"
									:href="`/@${collaboration.entry.user.username}`"
								>
									@{{ collaboration.entry.user.username }}
								</a>
							</p>
						</div>
						<div class="tw-flex-shrink-0">
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-bg-green-500 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-green-400"
								v-tooltip="'Accept'"
								@click="acceptCollaboration(collaboration)"
							>
								<!-- Heroicon name: check -->
								<svg
									class="tw-text-center tw-h-5 tw-w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M5 13l4 4L19 7"
									/>
								</svg>
							</button>
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-500 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-red-400"
								v-tooltip="'Reject'"
								@click="rejectCollaboration(collaboration)"
							>
								<!-- Heroicon name: x -->
								<svg
									class="tw-text-center tw-h-5 tw-w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- Organization Invites -->
		<div>
			<div class="tw-bg-gray-100 tw-shadow tw-p-3 ">
				<p class="tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase">
					Organizations
				</p>
			</div>
			<div
				v-if="organizations.length == 0"
				class="tw-my-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						No organization invites
					</p>
					<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Currently, you don't have any pending invites to join any organization.
						</p>
					</div>
				</div>
			</div>
			<div
				v-else
				class="tw-flow-root tw-mx-3"
			>
				<ul
					role="list"
					class="tw-divide-y tw-divide-gray-200"
				>
					<li
						v-for="orgInvite in organizations"
						:key="orgInvite.orgMemberId"
						class="tw-flex tw-items-center tw-py-2 tw-space-x-3"
					>
						<div class="tw-min-w-0 tw-flex-1">
							<p class="tw-text-sm tw-font-medium tw-text-gray-500">
								{{ orgInvite.name }}
							</p>
							<p class="tw-text-xs tw-font-medium">
								Invite Sent On:
								<span class="tw-font-normal">
									{{ orgInvite.inviteDate | formatDate }}
								</span>
							</p>
							<!-- TODO: Show org owner here with gravatar -->
							<!-- <p>
								<a
									class="tw-text-sm tw-text-gray-500 hover:tw-text-gray-400"
									:href="'/@collaboration.entry.user.username'"
									v-tooltip="'Invite sent by '"
								>
									@{{ collaboration.entry.user.username }}
								</a>
							</p> -->
						</div>
						<div class="tw-flex-shrink-0">
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-bg-green-500 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-green-400"
								v-tooltip="'Accept'"
								@click="joinOrganization(orgInvite)"
							>
								<!-- Heroicon name: check -->
								<svg
									class="tw-text-center tw-h-5 tw-w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M5 13l4 4L19 7"
									/>
								</svg>
							</button>
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-500 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-red-400"
								v-tooltip="'Reject'"
								@click="rejectOrganization(orgInvite)"
							>
								<!-- Heroicon name: x -->
								<svg
									class="tw-text-center tw-h-5 tw-w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import swal from "sweetalert2";

export default {
	props : {
		collabs : {
			type     : Object,
			required : true
		},
		orgs : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			collaborations : Object.values( this.collabs ),
			organizations  : this.orgs,
			errors         : []
		};
	},
	methods : {
		acceptCollaboration( collaboration ) {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "Do you want to collaborate on the " + collaboration.entry.title + " package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.post(
						`/myaccount/collaborations/accept/${collaboration.collaborationID}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "Your are collaborating on the " + collaboration.entry.title + " package now.",
									show    : true
								};
								app.removeInvite( collaboration );
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error accepting the invite. Please try again",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		rejectCollaboration( collaboration ) {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "Do you want to reject the invitation to collaborate on the " + collaboration.entry.title + " package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/myaccount/collaborations/reject/${collaboration.collaborationID}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "You have rejected the invite to collaborate on the " + collaboration.entry.title + " package",
									show    : true
								};
								app.removeInvite( collaboration );
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error rejecting the invite. Please try again",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		joinOrganization( organization ) {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "Do you want to join the " + organization.name + " organization?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					// if not error
					window.location = `/orgs/${organization.slug}/confirm`;
				}
			} );
		},
		rejectOrganization( organization ) {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "Do you want to reject the invite to join the " + organization.name + " organization?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					if ( result.isConfirmed ) {
					// if not error
						window.location = `/orgs/${organization.slug}/reject`;
					}
				}
			} );
		},
		removeInvite( collaboration ) {
			var app = this;
			// delete app.collaborations[ collaboration.collaborationID ];
			app.collaborations = app.collaborations.filter(
				c => c.collaborationID !== collaboration.collaborationID
			);
		}
	}
};
</script>