<template>
	<tr>
		<td
			class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
		>
			<a :href="`/view/${collaboration.entry.slug}`">
				<span class="tw-text-sm tw-font-medium tw-text-gray-900">
					{{ collaboration.entry.title }}
				</span>
			</a>
		</td>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
						<span class="tw-inline-block tw-relative">
							<a
								:href="'/@'+(filter == 'inbox' ? collaboration.entry.user.username : collaboration.user.username)"
								class="tw-inline-block"
							>
								<Avatar
									:email="filter == 'inbox' ? collaboration.entry.user.email : collaboration.user.email"
									:px="40"
									:size="10"
								/>
							</a>
						</span>
					</div>
				</div>
				<div class="tw-ml-4">
					<div class="tw-text-sm tw-font-medium tw-text-gray-900">
						<svg
							v-if="filter == 'inbox'"
							xmlns="http://www.w3.org/2000/svg"
							class="tw-inline-block tw-h-3 tw-w-3 tw-rounded-full tw-ring-2 tw-ring-white"
							:class="{ 'tw-bg-green-500' : getStatusLabel() == 'Accepted', 'tw-bg-red-500' : getStatusLabel() == 'Rejected', 'tw-bg-yellow-400 tw-cursor-pointer' : getStatusLabel() == 'Pending' }"
							fill="none"
							viewBox="0 0 24 24"
							stroke="white"
							v-tooltip="getStatusLabel() == 'Accepted' ? 'Collaborating on this package' : getStatusLabel() == 'Pending' ? 'Pending Invite' : 'Rejected Invite'"
							@click="getStatusLabel() == 'Pending' ? acceptInviteDialog(collaboration) : ''"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								:d="getStatusLabel() == 'Accepted' ? 'M5 13l4 4L19 7' : getStatusLabel() == 'Pending' ? '' : 'M6 18L18 6M6 6l12 12'"
							/>
						</svg>
						{{ filter == 'inbox' ? collaboration.entry.user.fullName : collaboration.user.fullName }}
					</div>
					<div class="tw-text-sm tw-text-gray-500">
						@{{ filter == 'inbox' ? collaboration.entry.user.username : collaboration.user.username }}
					</div>
				</div>
			</div>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(collaboration.createdDate)"
		>
			{{ collaboration.createdDate | formatDate }}
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(collaboration.respondedDate)"
		>
			{{ collaboration.respondedDate | formatDate }}
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			<span
				:class="collaboration.publishAccess ? 'tw-bg-green-500' : 'tw-bg-red-500'"
				class="tw-w-3 tw-h-3 tw-mr-4 tw-rounded-full tw-inline-block tw-mx-auto tw-cursor-pointer"
				v-tooltip="collaboration.publishAccess ? 'Yes' : 'No'"
			/>
		</td>
		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							v-if="filter == 'inbox'"
							class="tw-py-1"
							role="none"
						>
							<span
								v-if="getStatusLabel() == 'Pending'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="acceptInviteDialog(collaboration)"
							>
								<!-- Heroicon name: solid/plane -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>

								</svg>
								Accept
							</span>
							<span
								v-if="getStatusLabel() == 'Pending'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="rejectInviteDialog()"
							>
								<!-- Heroicon name: color-x -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								Reject
							</span>

							<span
								v-if="getStatusLabel() == 'Accepted'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="leaveCollaborationDialog()"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
									/>

								</svg>
								Leave
							</span>
							<!-- <a
								v-if="getStatusLabel() == 'Rejected'"
								href="/request"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								Heroicon name: solid/trash
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
									/>
								</svg>
								Request Invite
							</a> -->
						</div>
						<div
							v-else
							class="tw-py-1"
							role="none"
						>
							<span
								v-if="getStatusLabel() == 'Pending' || getStatusLabel() == 'Rejected'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
							>
								<!-- Heroicon name: solid/plane -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
										clip-rule="evenodd"
									/>
								</svg>
								Resend
							</span>
							<span
								v-if="getStatusLabel() == 'Pending' || getStatusLabel() == 'Accepted'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="revokeInviteDialog"
							>
								<!-- Heroicon name: color-x -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								Revoke
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		collaboration : {
			type     : Object,
			required : true,
		},
		filter : {
			type     : String,
			required : true,
			default  : "inbox"
		}
	},
	data() {
		return {
			showDropdown  : false,
			publishAccess : !this.collaboration.publishAccess,
		};
	},
	directives : { ClickOutside },
	methods    : {
		getStatusLabel() {
			if ( !this.collaboration.accepted && this.collaboration.respondedDate == "" ) {
				return "Pending";
			}
			else if ( !this.collaboration.accepted && this.collaboration.respondedDate != "" ) {
				return "Rejected";
			}
			else if ( this.collaboration.accepted ) {
				return "Accepted";
			}
		},
		acceptInviteDialog( collaboration ) {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Accept Invite?",
				text               : "Do you want to collaborate on this package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					console.log( "Accept invite" );
					app.$http.post(
						`/myaccount/collaborations/accept/${collaboration.collaborationID}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "Your are collaborating on the " + collaboration.entry.title + " package now.",
									show    : true
								};
								app.updateCollaboration( collaboration, true );
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error accepting the invite. Please try again",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		revokeInviteDialog() {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Revoke Invite?",
				text               : "Are you sure you want to revoke this invite?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					console.log( "Revoke invite" );
					app.$http.delete(
						`/api/v1/collaborations/${app.collaboration.collaborationID}`
					)
						.then( ( response ) => {
							console.log( response );
							app.loading = false;
							if ( !response.data.error ) {
								app.$parent.removeCollaboration( app.collaboration.collaborationID );
								app.notification = {
									type    : "success",
									title   : "Success",
									message : "The invitation has been revoked!",
									show    : true
								};
							} else {
								app.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} ).catch( function( error ) {
							// handle error
							console.log( error );
							app.loading = false;
							// app.errors = [ error.response.data.messages[0] ];
							app.notification = {
								type    : "error",
								title   : "Error",
								message : "Oops something went wrong. Please try again",
								show    : true
							};
						} );
				}
			} );
		},
		rejectInviteDialog() {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Reject Invite?",
				text               : "Do you want to reject the invite to collaborate on this package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					// Reject logic goes here
				}
			} );
		},
		leaveCollaborationDialog() {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Leave Collaboration?",
				text               : "Do you want to leave the collaboration on this package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/myaccount/collaborations/leave/${app.collaboration.collaborationID}`
					)
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								// app.$parent.removeCollaboration( app.collaboration.collaborationID );
								app.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} ).catch( function( error ) {
							// handle error
							console.log( error );
							app.loading = false;
							// app.errors = [ error.response.data.messages[0] ];
							app.notification = {
								type    : "error",
								title   : "Error",
								message : "Oops something went wrong. Please try again",
								show    : true
							};
						} );
				}
			} );
		},
		updateCollaboration( collaboration,status ) {
			collaboration.accepted = status;
			if ( status ){
				collaboration.respondedDate = new Date();
			}
		}
	}
};
</script>
