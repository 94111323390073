<template>
	<div class="panel-generic">
		<div class="page-header package-title">
			<h4><i class="fa fa-certificate" /> Badges</h4>
		</div>
		<div class="my-4">
			<img
				id="version"
				class="copy-install-string tw-inline-block"
				data-toggle="tooltip"
				data-original-title="Copy Badge as HTML code"
				v-clipboard:copy="getVersionImageAsHTML"
				:src="versionLink"
			>
			<img
				id="downloads"
				class="copy-install-string tw-inline-block tw-float-right"
				data-toggle="tooltip"
				data-original-title="Copy Badge as HTML code"
				v-clipboard:copy="getInstallsImageAsHTML"
				:src="downloadsLink"
			>
		</div>
	</div>
</template>
<script>

export default {
	props : {
		badgesUrl : {
			type     : String,
			required : true,
			default  : ""
		}
	},
	data() {
		return {};
	},
	computed : {
		versionLink(){
			return this.badgesUrl + "version" ;
		},
		downloadsLink(){
			return this.badgesUrl + "downloads" ;
		},
		getVersionImageAsHTML(){
			return "<img src=\"" + this.badgesUrl + "version\" />" ;
		},
		getInstallsImageAsHTML(){
			return "<img src=\"" + this.badgesUrl + "downloads\" />" ;
		}
	}
};
</script>