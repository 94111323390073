<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Edit Package
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Update your package metadata information here
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<div v-if="isAdmin">
								<!-- Transfer Package -->
								<change-owner-form
									:this-package="thisPackage"
									:transfering-package="transferingPackage"
									:is-org="isOrg"
								/>

								<divider
									v-if="!transferingPackage"
									my="3"
								/>
							</div>

							<!-- Divider container -->
							<div
								v-if="!transferingPackage"
								class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200"
							>
								<!-- Title -->
								<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
									<div>
										<label
											for="title"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Title
											<span class="tw-text-red-500">*</span>
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.title"
											type="text"
											name="title"
											id="title"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
										>
									</div>
									<!-- Type -->
									<div>
										<label
											for="type"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Type
											<span class="tw-text-red-500">*</span>
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<select
											v-model="packageForm.entryType"
											id="type"
											name="type"
											class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
										>
											<option
												v-for="type in packageTypes"
												:value="type.typeID"
												:key="type.typeID"
											>
												{{ type.typeName }}
											</option>
										</select>
									</div>
									<!-- Summary -->
									<div>
										<label
											for="summary"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Summary
											<span class="tw-text-red-500">*</span>
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<textarea
											v-model="packageForm.summary"
											id="summary"
											name="summary"
											rows="3"
											placeholder="My package rocks!!!"
											class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
										/>
									</div>
								</div>

								<!-- Slug -->
								<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
									<div>
										<label
											for="slug"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Slug
											<span class="tw-text-red-500">*</span>
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.packageSlug"
											type="text"
											name="slug"
											id="slug"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											@input="debounceSlugCheck"
										>
										<p
											v-if="!validSlug"
											class="tw-mt-2 tw-text-sm tw-text-red-600"
										>
											The package slug is not valid or it's already in use.
										</p>
									</div>
									<div>
										<label
											for="slug"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Private Package?
										</label>
									</div>
									<div
										v-if="hasPrivatePackages || canPublishFreePrivatePackage || packageForm.isPrivate"
										class="sm:tw-col-span-2"
									>
										<span class="tw-text-sm tw-text-gray-500">
											If set to private, {{ !isOrg ? "your slug will have your username" : "the package slug will have the organization slug " }} appended upon saving:
											<span class="tw-text-red-500">{slug}@{{ !isOrg ? thisPackage.user.username : organization.slug }}</span>.
										</span>
										<button
											type="button"
											class="tw-ml-4 tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2"
											:class="{ 'tw-bg-blue-500 focus:tw-ring-blue-500': packageForm.isPrivate, 'tw-bg-gray-200 focus:tw-ring-gray-300': !(packageForm.isPrivate) }"
											@click="packageForm.isPrivate = !packageForm.isPrivate"
										>
											<span class="tw-sr-only">Private Package</span>
											<span
												aria-hidden="true"
												class="tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200"
												:class="{ 'tw-translate-x-5': packageForm.isPrivate, 'tw-translate-x-0': !(packageForm.isPrivate) }"
											/>
										</button>
									</div>
									<div
										v-else
										class="sm:tw-col-span-2"
									>
										<p
											v-if="!isOrg"
											class="tw-text-sm tw-text-gray-500 tw-mt-2"
										>
											Your current plan does not include private packages capabilities.
											<a
												href="/plans"
												class="tw-text-sm tw-text-blue-600 hover:tw-text-blue-500 tw-cursor-pointer"
											>
												Check out our plans here!
											</a>
										</p>
										<p
											v-else
											class="tw-text-sm tw-text-gray-500 tw-mt-2"
										>
											The private packages feature is not activated yet. Add members to the organization or subscribe to the pro plan to enable this feature.
										</p>
									</div>
									<div v-if="isAdmin">
										<label
											for="slug"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Unlisted Package?
										</label>
									</div>

									<div
										v-if="isAdmin"
										class="sm:tw-col-span-2"
									>
										<span class="tw-text-sm tw-text-gray-500">
											If you turn this feature on, your package won't show up in the search but it can be accessed by the direct URL or downloaded if you share it.
										</span>
										<button
											type="button"
											class="tw-ml-4 tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2"
											:class="{ 'tw-bg-blue-500 focus:tw-ring-blue-500': !packageForm.isListed, 'tw-bg-gray-200 focus:tw-ring-gray-300': packageForm.isListed }"
											@click="packageForm.isListed = !packageForm.isListed"
										>
											<span class="tw-sr-only">Private Package</span>
											<span
												aria-hidden="true"
												class="tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200"
												:class="{ 'tw-translate-x-5': !packageForm.isListed, 'tw-translate-x-0': packageForm.isListed }"
											/>
										</button>
									</div>
								</div>

								<!-- Package Links -->
								<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
									<div>
										<label
											for="homeURL"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Home URL
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.homeURL"
											type="text"
											name="homeURL"
											id="homeURL"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											placeholder="https://"
										>
									</div>
									<div>
										<label
											for="sourceCodeURL"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Source Code URL
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.sourceURL"
											type="text"
											name="sourceCodeURL"
											id="sourceCodeURL"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											placeholder="https://"
										>
									</div>
									<div>
										<label
											for="documentationURL"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Documentation URL
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.documentationURL"
											type="text"
											name="documentationURL"
											id="documentationURL"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											placeholder="https://"
										>
									</div>
									<div>
										<label
											for="bugsURL"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Bugs URL
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="packageForm.bugsURL"
											type="text"
											name="bugsURL"
											id="bugsURL"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											placeholder="https://"
										>
									</div>
								</div>
							</div>
							<!-- Alert Box -->
							<Alert
								class="tw-m-3"
								type="error"
								:messages="errors"
							/>
						</div>

						<!-- Action buttons -->
						<div
							v-if="!transferingPackage"
							class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6"
						>
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									label="Save"
									:loading="loading"
									:method="updatePackage"
									:disabled="!isValidForm || loading"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>
import debounce from "lodash/debounce";
import ChangeOwnerForm from "./ChangeOwnerForm.vue";

export default {
	props : {
		showslideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		packageTypes : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		hasPrivatePackages : {
			type     : Boolean,
			required : true,
			default  : false
		},
		canPublishFreePrivatePackage : {
			type     : Boolean,
			required : false,
			default  : false
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		isAdmin : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { ChangeOwnerForm },
	data() {
		return {
			showPanel   : false,
			validSlug   : true,
			packageForm : {
				title            : "",
				entryType        : "",
				summary          : "",
				packageSlug      : "",
				isPrivate        : "",
				isListed         : "",
				homeURL          : "",
				sourceURL        : "",
				documentationURL : "",
				bugsURL          : ""
			},
			transferingPackage : false,
			errors             : [],
			loading            : false
		};
	},
	computed : {
		isValidForm() {
			return this.packageForm.title != "" && this.packageForm.entryType != "" && this.packageForm.summary != "" && this.packageForm.packageSlug != "" && this.validSlug;
		}
	},
	mounted(){
		let app = this;
		app.populateItem();
	},
	watch : {
		showslideover( newVal ) {
			let app = this;
			if ( newVal != app.show ) {
				app.showPanel = newVal;
			}
		}
	},
	methods : {
		populateItem : function() {
			let app = this;
			app.packageForm.title = app.thisPackage.title;
			app.packageForm.entryType = app.thisPackage.entryType.typeID;
			app.packageForm.summary = app.thisPackage.summary;
			app.packageForm.packageSlug = app.thisPackage.slug;
			app.packageForm.isPrivate = app.thisPackage.isPrivate;
			app.packageForm.isListed = app.thisPackage.isListed;
			app.packageForm.homeURL = app.thisPackage.homeURL;
			app.packageForm.sourceURL = app.thisPackage.sourceURL;
			app.packageForm.documentationURL = app.thisPackage.documentationURL;
			app.packageForm.bugsURL = app.thisPackage.bugsURL;
		},
		debounceSlugCheck : debounce( function() {
			this.slugCheck();
		}, 500 ),
		slugCheck() {
			let app = this;
			app.validSlug = true;
			if ( app.packageForm.packageSlug != "" && app.packageForm.packageSlug != app.thisPackage.slug ) {
				app.errors = [];
				app.loading = true;
				app.$http.get( "/myaccount/packages/isValidSlug/"+ app.packageForm.packageSlug )
					.then( ( response ) => {
						if ( !response.data.unique ) {
							app.validSlug = false;
							app.error = response.data.messages;
						}
						app.loading = false;
					} )
					.catch( error => {
						app.error = error;
						app.loading = false;
					} );
			}
		},
		updatePackage() {
			var app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.notification.show = false;
			var url = !app.isOrg ? `/myaccount/packages/${app.thisPackage.entryID}` : `/orgs/${app.organization.slug}/packages/${app.thisPackage.entryID}`;
			app.$http.put( url, app.packageForm )
				.then( ( response ) => {
					if ( !response.data.error ) {
						app.loading = false;
						app.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "Package Saved Succesfully!",
							show    : true
						};
						var entryType = app.packageTypes.find( entryType => entryType.typeID === app.packageForm.entryType );
						app.$parent.thisPackage.title = app.packageForm.title;
						app.$parent.thisPackage.summary = app.packageForm.summary;
						app.$set( app.$parent.thisPackage,"entryType",{} );
						// TODO: Update the entry type here
						// app.$set( app.$parent.thisPackage,"entryType",entryType );
						// app.$parent.thisPackage.entryType.typeName = entryType.typeName;
						// Force object update. reference: https://vuejs.org/v2/guide/reactivity.html
						// app.$parent.thisPackage = Object.assign( {}, app.$parent.thisPackage, response.data.data );
        				// app.$delete( app.$parent, "thisPackage" );
						// app.$set( app.$parent,"thisPackage",{} );
						// console.log( entryType );
						app.updateFlag();
					} else {
						app.loading = false;
						app.errors.push( response.data.messages );
					}
				} )
				.catch( error => {
					app.loading = false;
					app.errors.push( error.response.data.messages[0] );
				} );
		},
		clearForm() {
			let app = this;
			app.packageForm = {
				title            : "",
				type             : "Modules",
				summary          : "",
				slug             : "",
				isPrivate        : "",
				homeURL          : "",
				sourceURL        : "",
				documentationURL : "",
				bugsURL          : ""
			};
		},
		updateFlag : function() {
			let app = this;
			app.showPanel = false;
			app.transferingPackage = false;
			app.$emit( "interface", app.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>