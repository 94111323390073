<template>
	<div>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>

		<div
			v-if="thisPackage.subscribersCount != 0 && !loading"
			class="tw-flex tw-mt-3"
		>
			<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
				Total Subscribers: {{ thisPackage.subscribersCount }}
			</div>
		</div>

		<div
			v-else-if="loading"
			class="tw-flex tw-mt-3"
		>
			<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
				<div
					class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
				>
					<div
						class="tw-btn-spinner tw-mt-1"
					/>
					<span class="tw-ml-2">Loading...</span>
				</div>
			</div>
		</div>

		<div
			v-if="thisPackage.subscribersCount == 0"
			class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
		>
			<div
				class="tw-px-4 tw-py-5 sm:tw-p-6"
			>
				<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
					No subscribers yet
				</h3>
				<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
					<p class="tw-text-sm">
						This package does not have any subscribers yet. Once someone subscribes to it, it will show up here.
					</p>
				</div>
			</div>
		</div>

		<div
			v-else
			class="tw-bg-white"
		>
			<!-- Packages table (small breakpoint and up) -->
			<div class="tw-mt-3">
				<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
					<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
						<thead>
							<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
								<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
									User
								</th>
							</tr>
						</thead>
						<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
							<tr
								v-for="(subscriber) in subscribers"
								is="SubscriberRow"
								:subscriber="subscriber"
								:key="subscriber.username"
								class="hover:tw-bg-gray-50"
							/>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SubscriberRow from "./SubscriberRow";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		user : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		}
	},
	components : { SubscriberRow },
	data() {
		return {
			subscribers  : [],
			loading      : false,
			error        : "",
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	mounted(){
		this.loadSubscribers();
	},
	computed : {},
	methods  : {
		loadSubscribers() {
			var app = this;
			app.loading = true;
			app.$http.get( `/viewPackageSubscribers/${app.thisPackage.slug}` )
				.then( function( response ) {
					app.loading = false;
					if ( response.data.error ) {
						app.subscribers = [];
						app.error = response.data.messages;
						return;
					}
					app.subscribers = response.data.data;
				} )
				.catch( function( response ) {
					app.error = response.messages;
				} );
		}
	}
};
</script>