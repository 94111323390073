<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Edit Role
										</h2>
										<p
											class="tw-text-sm tw-text-gray-500"
										>
											In this section you can update the team lead flag.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<!-- Divider container -->
							<div>
								<fieldset>
									<legend class="tw-sr-only">
										Member Roles
									</legend>
									<div class="tw-bg-white tw-rounded-md tw--space-y-px">
										<label
											class="tw-rounded-tl-md tw-rounded-tr-md tw-relative tw-border tw-p-4 tw-flex tw-cursor-pointer"
											:class="teamMember.isTeamLead ? 'tw-bg-blue-100 tw-border-blue-200 z-10' : 'tw-border-gray-200'"
										>
											<input
												v-model="teamMember.isTeamLead"
												type="radio"
												name="team-lead"
												value="true"
												class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
											>
											<div class="tw-ml-3 tw-flex tw-flex-col">
												<span
													class="tw-text-gray-900 tw-block tw-text-sm tw-font-medium"
												>
													Team Lead
												</span>
												<span
													class=" tw-block tw-text-sm"
													:class="teamMember.isTeamLead ? 'tw-text-blue-700' : 'tw-text-gray-500'"
												>
													Team member in charge of leading the team.
												</span>
											</div>
										</label>

										<label
											class="tw-rounded-tl-md tw-rounded-tr-md tw-relative tw-border tw-p-4 tw-flex tw-cursor-pointer"
											:class="!teamMember.isTeamLead ? 'tw-bg-blue-100 tw-border-blue-200 z-10' : 'tw-border-gray-200'"
										>
											<input
												v-model="teamMember.isTeamLead"
												type="radio"
												name="team-lead"
												value="false"
												class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
											>
											<div class="tw-ml-3 tw-flex tw-flex-col">
												<span
													class="tw-text-gray-900 tw-block tw-text-sm tw-font-medium"
												>
													Regular Member
												</span>
												<span
													class=" tw-block tw-text-sm"
													:class="!teamMember.isTeamLead ? 'tw-text-blue-700' : 'tw-text-gray-500'"
												>
													Member that is part of the team and reports to the team leader.
												</span>
											</div>
										</label>
									</div>
								</fieldset>
								<!-- Alert Box -->
								<Alert
									type="error"
									:messages="errors"
									class="m-3"
								/>
							</div>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>

								<action-button
									label="Save"
									:loading="loading"
									:method="updateTeamMember"
									:disabled="loading"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		member : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		teamMember : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		editMode : {
			type     : Boolean,
			required : false,
			default  : false
		},
		team : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		}
	},
	components : {},
	data() {
		return {
			showPanel : false,
			loading   : false,
			errors    : []
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	methods : {
		updateTeamMember() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.put(
				`/orgs/${app.organization.slug}/teams/members/${app.teamMember.teamMemberId}/changeRole`,
				{ isTeamLead: app.teamMember.isTeamLead, teamId: app.team.teamId }
			)
				.then( ( response, status ) => {
					if ( !response.data.error ) {
						// Update UI
						app.updateUI( response.data.data );
						app.updateFlag();
						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "The team member role has been updated successfully!",
							show    : true
						};
						app.loading = false;
					} else {
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.toString() );
					app.loading = false;
				} );
		},
		updateUI( updatedTeamMember ) {
			var app = this;
			app.teamMember.isTeamLead = updatedTeamMember.isTeamLead;
			app.$parent.teamMembers.forEach( function( member, index, arr ){
				if ( updatedTeamMember.isTeamLead && member.isTeamLead && member.teamMemberId != updatedTeamMember.teamMemberId ) {
					member.isTeamLead = false;
					return;
				}
			} );
		},
		updateFlag : function() {
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>