<template>
	<div class="tw-space-y-6">
		<page-header :menu="menu" />
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Personal API Tokens
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						A token gives your CLI the ability to do things like install,
						publish and unpublish packages, and manage owners and teams.
					</p>
				</div>
				<div
					class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg"
				>
					<div
						v-if="!generateNewToken"
						class="tw-text-center"
					>
						<Alert
							class="tw-my-3 tw-text-center"
							type="success"
							:messages="userFeedback"
							:dismiss="false"
						/>

						<div v-if="newToken != ''">
							<textarea
								class="tw-border tw-border-gray-300 tw-rounded-md tw-text-sm tw-w-full tw-resize-none tw-bg-gray-50 tw-mb-3"
								:value="newToken"
								disabled
							/>
						</div>

						<button
							type="button"
							class="tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700 sm:tw-order-1 sm:tw-ml-3 tw-transition tw-ease-in-out tw-duration-150"
							@click="
								generateNewToken = true;
								newToken = '';
								userFeedback = [];
							"
						>
							Generate New Token
						</button>
					</div>
					<div>
						<transition
							enter-class="tw-opacity-0 tw-transform tw-scale-95"
							enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
							enter-active-class="tw-transition ease-out duration-200"
							leave-class="tw-opacity-100 tw-transform tw-scale-100"
							leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
							leave-active-class="tw-transition tw-ease-in tw-duration-75"
						>
							<div v-if="generateNewToken">
								<label
									for="apiToken"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
								>
									API Token
								</label>
								<p class="tw-mb-3 tw-text-sm tw-text-gray-500">
									When you log into ForgeBox via CommandBox (CLI), we create a
									unique identifier that we give to your user account, which is
									stored in your
									<span class="tw-text-red-500">/.CommandBox</span> settings
									file. You can share the API Key with, for example, CI systems
									to allow them to download and publish your packages.
								</p>
								<div class="tw-grid tw-grid-cols-6 tw-gap-6">
									<div class="tw-col-span-6 sm:tw-col-span-3">
										<label
											for="tokenName"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
										>
											Name
										</label>
										<input
											v-model="form.tokenName"
											type="text"
											name="tokenName"
											id="tokenName"
											class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
										/>
									</div>

									<div class="tw-col-span-6 sm:tw-col-span-3">
										<label
											for="expirationDate"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
										>
											Expires
										</label>
										<date-picker
											class="tw-flex tw-content-start md:tw-col-span-6 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
											v-model="form.expirationDate"
											type="date"
											placeholder="Never"
											:disabled-date="disabledBeforeToday"
										/>
									</div>
								</div>
								<!-- Scopes -->
								<div class="tw-mt-5">
									<label
										for="readScope"
										class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
									>
										Scopes
									</label>
									<div class="tw-mt-4 sm:tw-mt-0">
										<div class="tw-grid tw-grid-cols-6 tw-gap-6">
											<div
												class="tw-relative tw-flex tw-items-start tw-col-span-6 sm:tw-col-span-3"
											>
												<div class="tw-flex tw-items-center tw-h-5">
													<input
														id="readScope"
														name="readScope"
														type="checkbox"
														class="tw-h-4 tw-w-4 tw-text-blue-600 tw-border-gray-300 tw-rounded tw-opacity-50"
														disabled
														checked
													/>
												</div>
												<div class="tw-ml-3 tw-text-sm">
													<label
														for="readScope"
														class="tw-font-medium tw-text-gray-700 tw-mb-0"
													>
														Read
													</label>
													<p class="tw-text-sm tw-text-gray-500">
														Assign read permissions to this token.
													</p>
												</div>
											</div>
											<div
												class="tw-relative tw-flex tw-items-start tw-col-span-6 sm:tw-col-span-3"
											>
												<div class="tw-flex tw-items-center tw-h-5">
													<input
														v-model="form.canWrite"
														id="writeScope"
														name="writeScope"
														type="checkbox"
														class="focus:tw-ring-blue-500 tw-h-4 tw-w-4 tw-text-blue-600 tw-border-gray-300 tw-rounded"
													/>
												</div>
												<div class="tw-ml-3 tw-text-sm">
													<label
														for="writeScope"
														class="tw-font-medium tw-text-gray-700 tw-mb-0"
													>
														Write
													</label>
													<p class="tw-text-sm tw-text-gray-500">
														Assign write permissions to this token.
													</p>
												</div>
											</div>
										</div>
									</div>

									<!-- Alert Box -->
									<Alert
										class="tw-mt-3"
										type="error"
										:messages="errors"
									/>

									<div class="tw-mt-6 tw-text-right tw-space-x-2">
										<button
											type="button"
											class="tw-order-1 tw-ml-3 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
											@click="clearForm"
										>
											Cancel
										</button>
										<action-button
											label="Create API Token"
											:loading="loading"
											:method="createToken"
											:disabled="!isValidForm || loading"
										/>
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>

			<divider />

			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Active Access Tokens
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						Active tokens will be listed here, you can revoke your tokens any
						time.
					</p>
				</div>
				<div
					class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg"
				>
					<div
						class="tw-overflow-x-scroll lg:tw-overflow-x-hidden tw-border tw-border-gray-200 tw-rounded-lg"
					>
						<table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
							<thead>
								<tr>
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Default
									</th>
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Name
									</th>
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Token
									</th>
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Last Used
									</th>
									<!--
									`relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
									-->
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Expires
									</th>
									<th
										scope="col"
										class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Scopes
									</th>
									<th
										scope="col"
										class="tw-relative tw-px-6 tw-py-1 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										<span class="tw-sr-only">Revoke Token</span>
									</th>
								</tr>
							</thead>
							<tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
								<tr
									v-for="token in tokens"
									:key="token.token"
								>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900 tw-text-center"
									>
										<svg
											v-tooltip="
												token.isDefault ? 'Default Token' : 'Change Default'
											"
											class="tw-w-5 tw-h-5 tw-inline-block"
											:class="
												token.isDefault
													? 'tw-text-yellow-400'
													: 'tw-text-gray-400 hover:tw-text-gray-300 tw-cursor-pointer'
											"
											xmlns="http://www.w3.org/2000/svg"
											:fill="token.isDefault ? 'currentColor' : 'none'"
											viewBox="0 0 24 24"
											stroke="currentColor"
											@click="changeDefaultToken(token)"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
											/>
										</svg>
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
									>
										{{ token.name }}
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
									>
										xxxxxxxxxxxxxxxxx-{{ token.token | last4Digits }}
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
									>
										{{ token.lastUsed | dateLabel }}
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
									>
										{{ token.expirationDate | dateLabel }}
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
									>
										{{ token.canWrite | scopeLabel }}
									</td>
									<td
										class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium"
									>
										<button
											type="button"
											class="tw-px-4 tw-py-1 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white "
											:class="
												token.isDefault
													? 'tw-bg-gray-200 tw-cursor-not-allowed tw-border tw-border-gray-300 tw-text-gray-500'
													: 'tw-bg-red-600 hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500'
											"
											@click="revokeTokenDialog(token.tokenID)"
											:disabled="token.isDefault"
										>
											Revoke Token
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		tokens : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		}
	},
	components : { DatePicker },
	data() {
		return {
			menu : [ { label: "Access Tokens" } ],
			form : {
				tokenName      : "",
				expirationDate : "",
				canWrite       : false
			},
			defaultToken     : null,
			generateNewToken : false,
			newToken         : "",
			loading          : false,
			userFeedback     : [],
			errors           : [],
			notification     : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	computed : {
		isValidForm() {
			return this.form.tokenName != "";
		},
		filteredTokens() {
			return this.tokens;
		}
	},
	mounted() {
		var app = this;
		app.setDefaultToken();
	},
	filters : {
		last4Digits( value ) {
			return value ? value.slice( -4 ) : "";
		},
		scopeLabel( value ) {
			return value ? "Read, Write" : "Read";
		},
		dateLabel( value ) {
			if ( value == "" || value === null || value === undefined ) {
				return "Never";
			}
			value = new Date( value );
			if ( value ) {
				return moment( value ).format( "ll" );
			}
		}
	},
	methods : {
		createToken() {
			var app = this;
			app.errors = [];
			app.userFeedback = [];
			const data = new FormData();
			data.append( "name", app.form.tokenName );
			if (
				app.form.expirationDate != "" &&
				!( app.form.expirationDate === null )
			) {
				data.append( "expirationDate", app.form.expirationDate );
			}
			data.append( "canWrite", app.form.canWrite );
			app.$http
				.post( "/myaccount/settings/createToken", data )
				.then( response => {
					if ( !response.data.error ) {
						app.loading = false;
						app.tokens.push( response.data.data );
						// Copy the generated token to the clipboard
						app.copyStringToClipboard( response.data.data.token );
						app.notification = {
							type    : "success",
							title   : "Success",
							message : "Your token has been created!",
							show    : true
						};
						app.clearForm();
						app.userFeedback.push(
							"Your token has been created and copied to your clipboard. This token will only be shown once. Make sure you store it in a safe place."
						);
						app.newToken = response.data.data.token;
					} else {
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					app.loading = false;
					app.errors.push( error.response.data.messages[0] );
				} );
		},
		resetTokenDialog() {
			var app = this;
			app.notification.show = false;
			swal
				.fire( {
					title : "Are you sure?",
					text  :
						"You are about to reset your token. Please note that the token generation process is irreversible. Your old token will be discarded and unusable.",
					icon               : "warning",
					confirmButtonText  : "Reset Token",
					confirmButtonColor : "#e02424",
					showCancelButton   : true,
					reverseButtons     : true
				} )
				.then( result => {
					if ( result.isConfirmed ) {
						app.$http
							.post( "/myaccount/settings/account/resetToken" )
							.then( response => {
								if ( !response.data.error ) {
									app.loading = false;
									app.last4Token = response.data.data;
									app.notification = {
										type    : "success",
										title   : "Success",
										message : "Your token has been regenerated!",
										show    : true
									};
								} else {
									app.loading = false;
									app.errors.push( response.data.messages[0] );
									app.notification = {
										type    : "error",
										title   : "Error",
										message : response.data.messages[0],
										show    : true
									};
								}
							} )
							.catch( error => {
								app.loading = false;
								app.notification = {
									type    : "error",
									title   : "Error",
									message : error.response.data.messages[0],
									show    : true
								};
								app.errors = [ error.response.data.messages[0] ];
							} );
					}
				} );
		},
		revokeTokenDialog( tokenID ) {
			var app = this;
			app.notification.show = false;
			swal
				.fire( {
					title : "Are you sure?",
					text  :
						"You are about to revoke this token. Please note that this process is irreversible. Your old token will be discarded and unusable.",
					icon               : "warning",
					confirmButtonText  : "Revoke Token",
					confirmButtonColor : "#e02424",
					showCancelButton   : true,
					reverseButtons     : true
				} )
				.then( result => {
					if ( result.isConfirmed ) {
						app.$http
							.put( "/myaccount/settings/account/revokeToken", { tokenID: tokenID } )
							.then( response => {
								if ( !response.data.error ) {
									app.loading = false;

									app.notification = {
										type    : "success",
										title   : "Success",
										message : "Your token has been revoked!",
										show    : true
									};
									app.removeToken( tokenID );
								} else {
									app.loading = false;
									app.errors.push( response.data.messages[0] );
									app.notification = {
										type    : "error",
										title   : "Error",
										message : response.data.messages[0],
										show    : true
									};
								}
							} )
							.catch( error => {
								app.loading = false;
								app.errors = [ error.response.data.messages[0] ];
								app.notification = {
									type    : "error",
									title   : "Error",
									message : error.response.data.messages[0],
									show    : true
								};
							} );
					}
				} );
		},
		removeToken( tokenID ) {
			var app = this;

			for ( let [
				index,
				token
			] of app.tokens.entries() || [] ) {
				if ( token.tokenID == tokenID ) {
					app.tokens.splice( index, 1 );
					return;
				}
			}
		},
		setDefaultToken() {
			var app = this;
			app.defaultToken = app.tokens.find( token => token.isDefault );
		},
		changeDefaultToken( token ) {
			if ( token.isDefault ) {
				return;
			}
			var app = this;
			app.notification.show = false;
			swal
				.fire( {
					title : "Are you sure?",
					text  :
						"You are about to change your default token. This token will be used by CommandBox to execute actions on your behalf.",
					icon               : "question",
					confirmButtonText  : "Yes",
					confirmButtonColor : "#297492",
					showCancelButton   : true,
					reverseButtons     : true
				} )
				.then( result => {
					if ( result.isConfirmed ) {
						app.$http
							.put( "/myaccount/settings/account/changeDefaultToken", { tokenID: token.tokenID } )
							.then( response => {
								if ( !response.data.error ) {
									app.loading = false;

									// Update table
									app.tokens.find( token => token.isDefault ).isDefault = false;
									token.isDefault = true;
									app.notification = {
										type    : "success",
										title   : "Success",
										message : response.data.data,
										show    : true
									};
								} else {
									app.loading = false;
									app.notification = {
										type    : "error",
										title   : "Error",
										message : response.data.messages[0],
										show    : true
									};
									app.errors.push( response.data.messages[0] );
								}
							} )
							.catch( error => {
								app.loading = false;
								app.notification = {
									type    : "error",
									title   : "Error",
									message : error.response.data.messages[0],
									show    : true
								};
								app.errors = [ error.response.data.messages[0] ];
							} );
					}
				} );
		},
		disabledBeforeToday( date ) {
			var today = new Date();
			var yesterday = today.setDate( today.getDate() - 1 );
			return date < yesterday;
		},
		copyStringToClipboard( str ) {
			// Create new element
			var el = document.createElement( "textarea" );
			// Set value (string to be copied)
			el.value = str;
			// Set non-editable to avoid focus and move outside of view
			el.setAttribute( "readonly", "" );
			el.style = { position: "absolute", left: "-9999px" };
			document.body.appendChild( el );
			// Select text inside element
			el.select();
			// Copy text to clipboard
			document.execCommand( "copy" );
			// Remove temporary element
			document.body.removeChild( el );
		},
		clearForm() {
			let app = this;
			app.form.tokenName = "";
			app.form.expirationDate = "";
			app.form.canWrite = false;
			app.generateNewToken = false;
		}
	}
};
</script>
