<template>
	<div class="tw-text-sm">
		<div class="tw-rounded-lg tw-bg-blue-200 tw-text-center tw-py-2">
			<span
				:class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium ${ characters.uppercase }`"
			>
				ABC
			</span>
			<span :class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium ${ characters.lowercase }`">
				abc
			</span>
			<span :class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium ${ characters.numbers }`">
				123
			</span>
			<!-- <span :class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium  ${ characters.symbols }`">
				@#$
			</span> -->
			<span :class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium ${ characters.minLength }`">
				{{ newPassword.length }}
			</span>
			<span
				v-if="changingPassword"
				:class="`tw-px-2 tw-mx-1 tw-inline-flex tw-text-xs tw-leading-5 tw-rounded-full tw-font-medium  ${ characters.samePassword }`"
			>
				=
			</span>
		</div>

		<div class="tw-my-3">
			<p class="tw-text-sm tw-italic">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke="currentColor"
					fill="none"
					aria-hidden="true"
					class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
				><path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/></svg>
				Your password must contain at least
				<span class="tw-text-ternary tw-font-medium"> 8 characters</span>, at least
				<span class="tw-text-ternary tw-font-medium"> 1 uppercase</span> and at least
				<!-- <span class="tw-text-ternary tw-font-medium">  1 symbol</span> and at least -->
				<span class="tw-text-ternary tw-font-medium"> 1 digit</span>.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		newPassword : {
			type     : String,
			required : true
		},
		confirmPassword : {
			type     : String,
			required : false,
			default  : ""
		},
		changingPassword : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	data() {
		return {
			inValid    : "tw-text-muted tw-bg-muted",
			valid      : "tw-text-white tw-bg-green-500",
			characters : {
				uppercase    : "tw-text-muted tw-bg-muted",
				lowercase    : "tw-text-muted tw-bg-muted",
				numbers      : "tw-text-muted tw-bg-muted",
				// symbols      : "tw-text-muted tw-bg-muted",
				minLength    : "tw-text-muted tw-bg-muted",
				samePassword : "tw-text-muted tw-bg-muted"
			}
		};
	},
	watch : {
		newPassword( value ) {
			this.validatePassword( value );
		},
		confirmPassword( value ) {
			this.checkPasswords();
		}
	},
	computed : {
		isSamePassword() {
			return this.checkPasswords();
		}
	},
	methods : {
		validatePassword( value ){

			//Declare Regex
			var uppercase = /[A-Z]/,
				lowercase = /[a-z]/,
				numbers = /[0-9]/,
				symbols = /[!@#\$%\^&\*]/,
				minLength = /(?=.{8,})/;

			// Evaluate Regex
			this.characters.uppercase =	uppercase.test( value ) ?  this.valid : this.inValid;
			this.characters.lowercase =	lowercase.test( value ) ?  this.valid : this.inValid;
			this.characters.numbers   =	numbers.test( value )   ?  this.valid : this.inValid;
			// this.characters.symbols   =	symbols.test( value )   ?  this.valid : this.inValid;
			this.characters.minLength =	minLength.test( value ) ?  this.valid : this.inValid;

			// Check for passwords
			// this.checkPasswords();

			return uppercase.test( value ) && lowercase.test( value ) && numbers.test( value ) && minLength.test( value ) && this.checkPasswords();
		},
		checkPasswords(){
			// Check if passwords are the same and are not empty strings
			var samePasswords = this.changingPassword && ( this.newPassword == this.confirmPassword ) && ( this.newPassword != "" );
			this.characters.samePassword = samePasswords ? this.valid : this.inValid;

			return samePasswords;
		}
	}
};
</script>

