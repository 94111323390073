<template>
	<tr>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div>
					<div class="tw-text-sm tw-font-medium tw-text-gray-900">
						<a
							:href="`@${subscriber.username}`"
							class="tw-text-gray-700 hover:tw-text-gray-900"
						>
							{{ getFullName( subscriber ) }}
						</a>
					</div>
					<div class="tw-text-sm tw-text-gray-500">
						@{{ subscriber.username }}
					</div>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>

export default {
	props : {
		subscriber : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			loading : false,
			errors  : []
		};
	},
	methods : {
		getFullName( subscriber ){
			return subscriber.fname + " " + subscriber.lname;
		}
	}
};
</script>
