<template>
	<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
		<div class="tw-flex-1">
			<!-- Header -->
			<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
				<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
					<div class="tw-space-y-1">
						<h2
							id="slide-over-heading"
							class="tw-text-lg tw-font-medium tw-text-gray-900"
						>
							Edit Member
						</h2>
						<p
							class="tw-text-sm tw-text-gray-500"
						>
							In this section you can update the member role and permissions.
						</p>
					</div>
					<div class="tw-h-7 tw-flex tw-items-center">
						<button
							type="button"
							class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
							@click="updateFlag"
						>
							<span class="tw-sr-only">Close panel</span>
							<!-- Heroicon name: outline/x -->
							<svg
								class="tw-h-6 tw-w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>

			<!-- Divider container -->
			<div>
				<fieldset>
					<legend class="tw-sr-only">
						Member Role
					</legend>
					<label
						for="name"
						class="tw-block tw-text-sm tw-font-medium tw-text-gray-700  tw-m-3"
					>
						Member Role
					</label>
					<div
						class="tw-bg-white tw-rounded-md tw-space-y-0"
					>
						<!-- TODO: Check if a logged in user can assign a higher role -->
						<div>
							<label
								class="tw-rounded-tl-md tw-rounded-tr-md tw-relative tw-border tw-p-3 tw-flex tw-cursor-pointer tw-mb-0"
								:class="memberRole == 'Super Admin' ? 'tw-bg-blue-100 tw-border-blue-200 z-10' : 'tw-border-gray-200'"
							>
								<input
									v-model="memberRole"
									type="radio"
									name="member-role"
									value="Super Admin"
									class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
								>
								<div class="tw-ml-3 tw-flex tw-flex-col">
									<span
										class="tw-text-gray-900 tw-block tw-text-sm tw-font-medium"
									>
										Super Admin
									</span>
									<span
										class="tw-block tw-text-sm"
										:class="memberRole == 'Super Admin' ? 'tw-text-blue-700' : 'tw-text-gray-500'"
									>
										Controls billing & adding and removing people in the org.
									</span>
								</div>
							</label>
						</div>

						<div>
							<label
								class="tw-rounded-tl-md tw-rounded-tr-md tw-relative tw-border tw-p-4 tw-flex tw-cursor-pointer"
								:class="memberRole == 'Admin' ? 'tw-bg-blue-100 tw-border-blue-200 z-10' : 'tw-border-gray-200'"
							>
								<input
									v-model="memberRole"
									type="radio"
									name="member-role"
									value="Admin"
									class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
								>
								<div class="tw-ml-3 tw-flex tw-flex-col">
									<span
										class="tw-text-gray-900 tw-block tw-text-sm tw-font-medium"
									>
										Admin
									</span>
									<span
										class="tw-block tw-text-sm"
										:class="memberRole == 'Admin' ? 'tw-text-blue-700' : 'tw-text-gray-500'"
									>
										Manages teams & package access.
									</span>
								</div>
							</label>
						</div>

						<div>
							<label
								class="tw-rounded-tl-md tw-rounded-tr-md tw-relative tw-border tw-p-4 tw-flex tw-cursor-pointer"
								:class="memberRole == 'Member' ? 'tw-bg-blue-100 tw-border-blue-200 z-10' : 'tw-border-gray-200'"
							>
								<input
									v-model="memberRole"
									type="radio"
									name="member-role"
									value="Member"
									class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
								>
								<div class="tw-ml-3 tw-flex tw-flex-col">
									<span
										class="tw-text-gray-900 tw-block tw-text-sm tw-font-medium"
									>
										Member
									</span>
									<span
										class="tw-block tw-text-sm"
										:class="memberRole == 'Member' ? 'tw-text-blue-700' : 'tw-text-gray-500'"
									>
										Works on packages they are given access to.
									</span>
								</div>
							</label>
						</div>
					</div>
				</fieldset>

				<div class="tw-m-3">
					<label
						for="permissions"
						class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-my-3"
					>
						Permissions
					</label>
					<div class="tw-flex tw-justify-between">
						<span class="tw-text-sm tw-text-gray-500">
							Can this member create new packages for the organization?
						</span>
						<div class="tw-flex tw-justify-between">
							<div class="tw-mr-3">
								<input
									v-model="canCreateNewPackages"
									type="radio"
									name="canCreateNewPackages"
									:value="true"
									class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
								>
								<label
									class="tw-text-sm tw-text-gray-500"
									for="canCreateNewPackages"
								>
									Yes
								</label>
							</div>
							<div>
								<input
									v-model="canCreateNewPackages"
									type="radio"
									name="canCreateNewPackages"
									:value="false"
									class="tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-text-blue-600 tw-border-gray-300 focus:tw-ring-blue-500"
								>
								<label
									class="tw-text-sm tw-text-gray-500"
									for="canCreateNewPackages"
								>
									No
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- Alert Box -->
				<Alert
					type="error"
					:messages="errors"
					class="tw-m-3"
				/>
			</div>
		</div>

		<!-- Action buttons -->
		<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
			<div class="tw-space-x-3 tw-flex tw-justify-end">
				<button
					type="button"
					@click="updateFlag"
					class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
				>
					Cancel
				</button>
				<action-button
					:label="'Save'"
					:loading="loading"
					:method="save"
					:disabled="loading"
				/>
			</div>
		</div>
	</form>
</template>

<script>

export default {
	props : {
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		member : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		}
	},
	data() {
		return {
			memberRole           : "",
			canCreateNewPackages : false,
			loading              : false,
			errors               : []
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {},
	mounted(){
		var app = this;
		app.populateItem();
	},
	methods : {
		populateItem() {
			let app = this;
			app.memberRole = app.member.role.role;
			app.canCreateNewPackages = app.member.canCreateNewPackages;
		},
		save() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.put(
				`/orgs/${app.organization.slug}/members/${app.member.orgMemberId}/update`,
				{
					role                 : app.memberRole,
					canCreateNewPackages : app.canCreateNewPackages
				}
			)
				.then( ( response, status ) => {
					// Update UI
					app.updateUI( response.data.data.role.role, app.canCreateNewPackages );

					app.updateFlag();
					app.$parent.$parent.notification = {
						type    : "success",
						title   : "Success",
						message : "The member has been updated successfully!",
						show    : true
					};
					app.loading = false;
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.toString() );
					app.loading = false;
				} );
		},
		updateUI( role, canCreateNewPackages ) {
			var app = this;
			app.member.role.role = role;
			app.member.canCreateNewPackages = canCreateNewPackages;
		},
		updateFlag : function() {
			var app = this;
			app.showPanel = false;
			app.errors = [];
			app.$parent.updateFlag();
		}
	}
};
</script>