<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Change Plan
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Choose the plan that fills your needs. Below you will find the features included in each plan.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<!-- Divider container -->
							<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
								<fieldset>
									<legend class="tw-sr-only">
										Pricing plans
									</legend>
									<ul class="tw-relative tw-bg-white tw-rounded-md tw--space-y-px tw-list-disc">
										<!-- Current Plan -->
										<li>
											<div
												class="tw-relative tw-border-2 tw-bg-gray-100 tw-border-gray-200 tw-rounded-tl-md tw-rounded-tr-md tw-p-4 tw-flex tw-flex-col md:tw-pl-4 md:tw-pr-6 md:tw-grid md:tw-grid-cols-12"
											>
												<label
													class="tw-flex tw-items-center tw-text-sm tw-col-span-3"
												>
													<span class="tw-ml-3 tw-font-medium tw-text-gray-900">
														{{ subscription.plan.name }}
														<span
															class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-blue-100 tw-text-blue-800 tw-border-blue-200 tw-border"
														>
															Current Plan
														</span>
													</span>
												</label>
												<div
													class="tw-flex tw-ml-6 tw-pl-1 tw-text-sm md:tw-ml-0 md:tw-pl-0 md:tw-text-center tw-col-span-2"
												>
													<label
														class="tw-flex tw-items-center tw-text-sm tw-col-span-3"
													>
														<span
															class="tw-font-medium tw-mx-auto"
														>
															{{ subscription.plan.isFree ? "Free!" : "$"+subscription.plan.price }}
														</span>
													</label>
												</div>
												<div
													v-html="subscription.plan.featuresHtml"
													class="tw-ml-6 tw-pl-1 tw-text-sm md:tw-ml-0 md:tw-pl-0 tw-col-span-7 list-features"
												/>
											</div>
										</li>
										<li
											v-for="plan in filteredPlans"
											:key="plan.planId"
										>
											<div
												class="tw-relative tw-border-2 tw-rounded-tl-md tw-rounded-tr-md tw-p-4 tw-flex tw-flex-col md:tw-pl-4 md:tw-pr-6 md:tw-grid md:tw-grid-cols-12"
												:class="{ 'tw-bg-gray-100 tw-border-gray-200' : subscription.plan.slug == plan.slug, 'tw-bg-blue-100 tw-border-blue-200' : plan.slug == selectedPlan && (selectedPlan != subscription.plan.slug) }"
											>
												<label
													class="tw-flex tw-items-center tw-text-sm tw-cursor-pointer tw-col-span-3"
													:class="{ 'tw-cursor-pointer' : subscription.plan.slug != plan.slug }"
												>
													<input
														v-model="selectedPlan"
														name="forgebox-plan"
														type="radio"
														:value="plan.slug"
														:checked="subscription.plan.slug == plan.slug"
														:disabled="subscription.plan.slug == plan.slug"
														class="tw-h-4 tw-w-4 tw-text-blue-500 focus:tw-ring-blue-500 tw-border-blue-300"
														:class="{ 'tw-cursor-pointer' : subscription.plan.slug != plan.slug }"
													>
													<span class="tw-ml-3 tw-font-medium tw-text-gray-900">
														{{ plan.name }}
													</span>
												</label>
												<div
													class="tw-flex tw-ml-6 tw-pl-1 tw-text-sm md:tw-ml-0 md:tw-pl-0 md:tw-text-center tw-col-span-2"
												>
													<label
														class="tw-flex tw-items-center tw-text-sm tw-col-span-3"
														:class="{ 'tw-cursor-pointer' : subscription.plan.slug != plan.slug }"
													>
														<span
															class="tw-font-medium tw-mx-auto"
														>
															{{ plan.isFree ? "Free!" : "$"+plan.price }}
														</span>
													</label>
												</div>
												<!-- On: "text-blue-700", Off: "text-gray-500" -->
												<div
													v-html="plan.featuresHtml"
													class="tw-ml-6 tw-pl-1 tw-text-sm md:tw-ml-0 md:tw-pl-0 tw-col-span-7 list-features"
												/>
											</div>
										</li>
									</ul>
								</fieldset>
							</div>
							<!-- Compare plans link -->
							<div class="tw-mt-3 tw-text-sm">
								<a
									href="/about#plan-comparison"
									rel="noopener"
									target="_blank"
									class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500 tw-ml-3"
								>
									Compare Plans <span aria-hidden="true">→</span>
								</a>
							</div>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-ml-3 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<div v-if="selectedPlan == 'community'">
									<span
										class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700 tw-cursor-pointer"
										@click="downgradePlan"
									>
										Change Plan
									</span>
								</div>
								<div v-else>
									<a
										:href="`/subscribe/${selectedPlan}`"
										class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									>
										Subscribe
									</a>
								</div>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>


<script>
import swal from "sweetalert2";
import moment from "moment";

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		subscription : {
			type     : Object,
			required : true
		},
		subscriptionDetails : {
			type     : Object,
			required : true,
			default  : function() {
				return { content: { "current_period_end": new Date() } };
			}
		}
	},
	data() {
		return {
			showPanel    : false,
			plans        : [],
			selectedPlan : this.subscription.plan.slug == "pro" ? "community" : "pro",
			loading      : false,
			errors       : []
		};
	},
	computed : {
		filteredPlans() {
			var app = this;
			return app.plans.filter( function( plan ) {
				return ( plan.slug == "community" || plan.slug == "pro" ) && plan.slug != app.subscription.plan.slug;
			} );
		},
		nextBillDate() {
			var app = this;
			return moment( new Date( app.subscriptionDetails.content.current_period_end ) ).format( "ll" );
		}
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	mounted(){
		this.$nextTick( () => {
			this.loadPlans();
		} );
	},
	methods : {
		loadPlans() {
			var app = this;
			app.$http.get( "/ui/plans/list" )
				.then( response => {
					app.plans = response.data.data;
				} )
				.catch( err => {
					// Show error
				} );
		},
		downgradePlan() {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "Downgrading to the community plan will disable all the features of the pro plan on " + app.nextBillDate + ".",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/api/v1/subscriptions/${app.subscription.providerId}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "Your change plan has been processed. You can resubscribe before " + app.nextBillDate + " to not lose your current features.",
									show    : true
								};
								app.updateUI();
								app.updateFlag();
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error changing your plan. Please try again",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		updateUI() {
			var app = this;
			app.$parent.subscription.isActive = false;
			app.$parent.subscription.endDate = app.nextBillDate;
		},
		updateFlag : function() {
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>