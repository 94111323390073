<template>
	<div>
		<p v-if="!editmode">
			Here are all the versions for this package. Please note that you can leverage CommandBox package versioning to install any package you like. Please refer to our
			<a
				href="https://commandbox.ortusbooks.com/package-management/managing-version"
				rel="noopener"
				target="_blank"
			>
				managing package version guide
			</a>
			for more information.
		</p>
		<div class="d-md-flex">
			<div class="flex-1 input-group">
				<input
					class="form-control"
					placeholder="Search"
					type="text"
					@input="debounceSearch"
					v-model="keyword"
				>
				<div class="input-group-append">
					<span
						class="input-group-text close-icon"
					>
						<i
							v-show="keyword.length"
							class="fa fa-close pointer"
							title="Clear Search"
							@click="clearSearch"
						/>
					</span>
				</div>
			</div>
			<div class="px-0 flex-1 m-0">
				<select
					v-model="filter"
					class="form-control form-control-md w-100"
					@change="loadVersions()"
				>
					<option value="all">
						All
					</option>
					<option value="stable">
						Stable
					</option>
					<option value="snapshot">
						Snapshot
					</option>
				</select>
			</div>
		</div>

		<div class="row">
			<div class="col-12 my-3">
				<div v-if="loading">
					Loading Versions
					<i
						class="fa fa-spinner fa-spin"
					/>
				</div>
				<div v-else>
					<div
						v-if="versions.length > 0 && pagination.totalPages > 0"
						class="mb-2"
					>
						Showing {{ pagination.startRow+1 }} to {{ pagination.startRow + versions.length }} of {{ pagination.records }}
						<strong>{{ filter == "stable" ? 'stable' : '' }}</strong> version{{ pagination.records > 1 ? 's' : '' }}
						({{ pagination.totalPages }} page{{ pagination.totalPages > 1 ? 's' : '' }})
					</div>
					<div
						v-if="versions.length == 0"
						class="mb-2"
					>
						No versions were found
					</div>
				</div>
			</div>
		</div>
		<div class="table-responsive">
			<table class="table table-flush table-hover">
				<thead class="thead-light">
					<tr>
						<th class="col-xs-3">
							Version
						</th>
						<th class="col-xs-3 text-center">
							Created
						</th>
						<th class="col-xs-3 text-center">
							Last Update
						</th>
						<th class="col-xs-3 text-center">
							Installs
						</th>
						<th class="col-xs-3 text-center">
							User
						</th>
						<th class="col-xs-1 text-center">
							Stable
						</th>
						<th class="col-xs-1 text-center">
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					<!--- Version History --->
					<tr v-if="keyword.length">
						<th
							colspan="7"
							class="versionsLabel"
						>
							{{ versions.length ? 'Search Results' : 'No results were found for the keyword ' + keyword }}
						</th>
					</tr>
					<tr
						v-for="thisVersion in versions"
						:key="thisVersion.entryVersionID"
					>
						<th class="version-table-content">
							<span>
								<svg
									v-if="latestVersion.version == thisVersion.version"
									v-tooltip="'Current Version'"
									class="tw-w-5 tw-h-5 tw-inline-block tw-text-yellow-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
									/>
								</svg>
								<a
									:href="'/view/'+entry.slug+'/version/'+thisVersion.version"
									v-tooltip="'View Package Version'"
								>
									{{ thisVersion.version }}
								</a>
							</span>
						</th>
						<th
							class="version-table-content text-center"
							v-tooltip="getFullDate(thisVersion.createdDate)"
						>
							{{ thisVersion.createdDate | formatDate }}
						</th>
						<th
							class="version-table-content text-center"
							v-tooltip="getFullDate(thisVersion.updatedDate)"
						>
							{{ thisVersion.updatedDate | formatDate }}
						</th>
						<th
							class="version-table-content text-center"
						>
							{{ thisVersion.installs | formatNumber }}
						</th>
						<th class="version-table-content text-center">
							<a
								:href="'@' + thisVersion.publisherUsername"
								v-tooltip="thisVersion.publisherFirstname + ' (' + thisVersion.publisherUsername + ')'"
								:data-original-title="thisVersion.publisherFirstname"
							>
								<Avatar
									:email="thisVersion.publisherEmail"
									:px="25"
									custom-class="tw-mx-auto"
								/>
							</a>
						</th>
						<th class="text-center">
							<i
								v-if="thisVersion.isStable"
								class="fa fa-check text-success mt-2"
							/>
							<i
								v-else
								class="fa fa-times mt-2"
							/>
						</th>
						<th class="text-center">
							<div class="dropdown">
								<a
									class="btn btn-sm btn-icon-only text-light"
									href="##"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<i class="fa fa-ellipsis-v" />
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a
										v-if="isValidURL(thisVersion.downloadURL)"
										:href="'/download/' + entry.entryID + '/' + thisVersion.version"
										rel="noopener"
										target="_blank"
										class="dropdown-item"
									>
										<i class="fa fa-download" /> Download
									</a>
									<button
										v-if="!editmode"
										class="dropdown-item copy-install-string"
										v-clipboard:copy="getInstallString(entry.typeSlug, entry.slug, thisVersion.version )"
									>
										<i class="fa fa-copy" /> Copy Install String
									</button>
									<button
										v-if="editmode"
										class="dropdown-item"
									>
										<a
											:name="'editVersionButton' + thisVersion.entryVersionID"
											:href="`/package/${entry.entryID}/editVersion/${thisVersion.version}`"
											:id="'editVersionButton' + thisVersion.entryVersionID"
											class="dropdown-item p-0"
											title="Edit Version"
										>
											<i class="fa fa-edit" /> Edit
										</a>
									</button>
									<button
										v-if="editmode"
										class="dropdown-item"
									>
										<a
											:name="'removeVersionButton' + thisVersion.entryVersionID"
											href="javascript:void(0);"
											:id="'removeVersionButton' + thisVersion.entryVersionID"
											class="dropdown-item p-0"
											@click="removeVersion(thisVersion.entryVersionID)"
											title="Remove Version"
										>
											<i class="fa fa-trash-o" /> Remove
										</a>
									</button>
								</div>
							</div>
						</th>
					</tr>
				</tbody>
			</table>
			<!-- Loading -->
			<TableLoader :visible="loading" />
			<!-- Pagination -->
			<Pagination
				:total-pages="pagination.totalPages"
				:total-records="versions.length"
				:current-page="pagination.page"
				:loader="loadVersions"
			/>
		</div>
	</div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
	props : {
		entry : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		latestVersion : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		editmode : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : {},
	data() {
		return {
			versions   : [],
			keyword    : "",
			filter     : "all",
			loading    : false,
			error      : "",
			pagination : {
				startRow  	: 0,
				maxRow    	: 0,
				records   	: 0,
				page      	: 1,
				totalPages	: 1
			},
		};
	},
	mounted(){
		this.$nextTick( () => {
			var currentURL = new URL( window.location.href );
			var searchParams = new URLSearchParams( currentURL.search );
			if (
				searchParams.has( "filter" )
						&&
					( searchParams.get( "filter" ) == "stable" || searchParams.get( "filter" ) == "snapshot" )
			) {
				this.filter = searchParams.get( "filter" );
			}
			if ( searchParams.has( "keyword" ) ) {
				this.keyword = searchParams.get( "keyword" );
			}
			this.loadVersions( 1, true );
		} );
	},
	computed : {},
	methods  : {
		loadVersions( page = 1, firstLoad = false ) {
			this.loading = true;
			var pageNum = page || 1;
			var params = { filter: this.filter, page: pageNum };
			var searchParams = new URLSearchParams();
			if ( this.keyword != "" ){
				params[ "keyword" ] = this.keyword;
				searchParams.append( "keyword", this.keyword );
			}
			if ( this.filter == "stable" || this.filter == "snapshot" ){
				searchParams.append( "filter", this.filter );
			}
			if ( !firstLoad ) {
				history.pushState( { filters: "" }, "filters", "?"+searchParams.toString()+"#versions" );
			}
			this.$http
				.get( "/api/v1/entry/" + this.entry.slug + "/versions", { params: params } )
				.then( response => {
					if ( response.data.data.error ) {
						this.loading = false;
						this.error = "The versions list couldn't be retrieved. Please try again";
					} else {
						this.loading = false;
						this.versions = response.data.data;
						this.pagination = response.data.pagination;
					}
					app.loading = false;
				} )
				.catch( error => {
					app.error.push( error );
					app.loading = false;
				} );
		},
		getFullName( fname, lname ) {
			return fname + lname;
		},
		editVersion( entryVersionID ) {
			app.editVersion( entryVersionID );
		},
		removeVersion( entryVersionID ) {
			app.removeVersion( entryVersionID );
		},
		getInstallString( type, slug, version ) {
			return type === "cf-engines" ? "box server start cfengine="+ slug + "@" + version : "box install " + slug + "@" + version;
		},
		debounceSearch : debounce( function() {
			this.loadVersions();
		}, 300 ),
		clearSearch() {
			this.keyword = "";
			this.loadVersions();
		}
	}
};
</script>