<template>
	<div>
		<page-header
			:menu="menu"
			:primary-button="{ label: 'Save', type: 'button' }"
			:execute-method="true"
			:method="save"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Basic Information
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						In this section you will be able to update your name and gravatar. Keep in mind that this information will be displayed publicly.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
					<div class="tw-flex-grow tw-space-y-6">
						<div>
							<label
								for="first_name"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								First Name
							</label>
							<input
								v-model="user.fname"
								type="text"
								name="fname"
								id="fname"
								autocomplete="given-name"
								maxlength="100"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div>
							<label
								for="last_name"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Last Name
							</label>
							<input
								v-model="user.lname"
								type="text"
								name="lname"
								id="lname"
								autocomplete="family-name"
								maxlength="100"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>
					</div>
					<div class="tw-mt-6 tw-flex-grow lg:tw-mt-0 lg:tw-ml-6 lg:tw-flex-grow-0 lg:tw-flex-shrink-0">
						<label
							for="gravatar"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-text-center"
						>
							Gravatar
						</label>
						<div class="tw-relative tw-rounded-full tw-overflow-hidden">
							<a
								href="https://en.gravatar.com/emails/"
							>
								<Avatar
									:email="user.email"
									:px="140"
									:size="32"
									tooltip="Change Gravatar"
									custom-class="hover:tw-opacity-75 tw-mx-10"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>

			<!-- Divider -->
			<divider />

			<!-- About me section -->
			<div class="md:tw-grid lg:tw-my-3 md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						About Me
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						You can share a brief description about yourself with the community.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<div class="tw-grid tw-grid-cols-6 tw-gap-6">
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="jobTitle"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Job Title
							</label>
							<input
								v-model="user.jobTitle"
								type="text"
								name="jobTitle"
								id="jobTitle"
								placeholder="Software Developer"
								maxlength="100"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="organization"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Organization
							</label>
							<input
								v-model="user.organization"
								type="text"
								name="organization"
								id="organization"
								placeholder="Google"
								maxlength="100"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6">
							<label
								for="aboutMe"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								About Me
							</label>
							<textarea
								v-model="user.aboutMe"
								id="aboutMe"
								name="aboutMe"
								rows="3"
								placeholder="I am a rock star!!!"
								maxlength="2000"
								class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							/>
						</div>
					</div>
				</div>
			</div>

			<!-- Divider -->
			<divider />

			<!-- Social Media section -->
			<div class="md:tw-grid lg:tw-my-3 md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Social Media
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						Your social media links will be displayed on your public profile page.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<div class="tw-grid tw-grid-cols-6 tw-gap-6">
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="otherURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Homepage URL
							</label>
							<input
								v-model="user.otherURL"
								type="text"
								name="otherURL"
								id="otherURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="githubURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Github URL
							</label>
							<input
								v-model="user.githubURL"
								type="text"
								name="githubURL"
								id="githubURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="twitterURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Twitter URL
							</label>
							<input
								v-model="user.twitterURL"
								type="text"
								name="twitterURL"
								id="twitterURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="facebookURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Facebook URL
							</label>
							<input
								v-model="user.facebookURL"
								type="text"
								name="facebookURL"
								id="facebookURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="linkedinURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								LinkedIn URL
							</label>
							<input
								v-model="user.linkedinURL"
								type="text"
								name="linkedinURL"
								id="linkedinURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="blogURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Blog URL
							</label>
							<input
								v-model="user.blogURL"
								type="text"
								name="blogURL"
								id="blogURL"
								placeholder="https://"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : {},
	data() {
		return {
			menu         : [ { label: "Profile" } ],
			loading      : false,
			errors       : [],
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	methods : {
		save() {
			var app = this;
			app.notification.show = false;
			const data = new FormData();
			data.append( "fname", app.user.fname );
			data.append( "lname", app.user.lname );
			data.append( "jobTitle", app.user.jobTitle );
			data.append( "organization", app.user.organization );
			data.append( "aboutMe", app.user.aboutMe );
			data.append( "otherURL", app.user.otherURL );
			data.append( "githubURL", app.user.githubURL );
			data.append( "twitterURL", app.user.twitterURL );
			data.append( "facebookURL", app.user.facebookURL );
			data.append( "linkedinURL", app.user.linkedinURL );
			data.append( "blogURL", app.user.blogURL );
			app.$http.post(
				"/myaccount/settings/profile/save", data
			)
				.then( ( response ) => {
					app.loading = false;
					if ( !response.data.error ) {
						app.notification = {
							type    : "success",
							title   : "Success",
							message : response.data.data,
							show    : true
						};
					} else {
						app.notification = {
							type    : "error",
							title   : "Error",
							message : "There was an error saving the user profile",
							show    : true
						};
					}
				} ).catch( function( error ) {
					// handle error
					app.loading = false;
					app.errors = [ error.response.data.messages[0] ];
					app.notification = {
						type    : "error",
						title   : "Error",
						message : "Oops something went wrong. Please try again",
						show    : true
					};
				} );
		}
	}
};
</script>