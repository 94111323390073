<template>
	<div
		v-if="messages.length || hasSlotData"
		class="tw-rounded-md"
		:class="`tw-bg-${style.baseColor}-50 tw-p-4`"
	>
		<div class="tw-flex">
			<div class="tw-flex-shrink-0">
				<!-- Heroicon name: solid/check-circle -->
				<svg
					class="tw-h-5 tw-w-5"
					:class="`tw-text-${style.baseColor}-400`"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						:d="style.icon"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
			<div class="tw-ml-3 tw-w-full">
				<p
					class="tw-text-sm tw-font-medium"
					:class="`tw-text-${style.baseColor}-800`"
				>
					<span v-html="renderMessages" />
					<slot />
				</p>
			</div>
			<div
				v-if="dismiss"
				class="tw-ml-auto tw-pl-3"
			>
				<div class="tw--mx-1.5 tw--my-1.5">
					<button
						type="button"
						class="tw-inline-flex tw-rounded-md tw-p-1.5 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2"
						:class="`tw-bg-${style.baseColor}-50 tw-text-${style.baseColor}-500 hover:tw-bg-${style.baseColor}-100 focus:tw-ring-offset-${style.baseColor}-50 focus:tw-ring-${style.baseColor}-600`"
						@click="close"
					>
						<span class="tw-sr-only">Dismiss</span>
						<!-- Heroicon name: solid/x -->
						<svg
							class="tw-h-5 tw-w-5"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props : {
		// Alert Type (info, success, warning, error)
		type : {
			type    : String,
			default : "info"
		},
		// An array of messsages
		messages : {
			type : Array,
			default(){
				return [];
			}
		},
		// Dismiss button
		dismiss : {
			type    : Boolean,
			default : true
		},
		// Is the alert hidable after a timeout in ms
		hidable : {
			type    : Boolean,
			default : false
		},
		// Timeout in ms, default is 3000
		hideTimeout : {
			type    : Number,
			default : 3000
		}
	},
	data() {
		return {};
	},
	created(){
		if ( this.hidable ){
			setTimeout( () => {
				this.close();
			}, this.hideTimeout );
		}
	},
	computed : {
		// info is the default type
		style(){
			switch ( this.type ) {
			case "success":
				return {
					baseColor : "green",
					icon      : "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
				};
			case "error":
				return {
					baseColor : "red",
					icon      : "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
				};
			case "warning":
				return {
					baseColor : "yellow",
					icon      : "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
				};
			default:
				return {
					baseColor : "blue",
					icon      : "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" //"M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
				};
			}
		},
		hasSlotData(){
			return this.$slots.default;
		},
		renderMessages(){
			return this.messages.join( "<br>" );
		}
	},
	methods : {
		close(){
			this.$slots.default = "";
			this.$parent.errors = [];
		}
	}
};
</script>