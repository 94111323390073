<template>
	<div>
		<!-- Prod dependencies -->
		<h3 class="tw-mb-0">
			Dependencies ({{ prodDependencies.length }})
			<hr class="tw-mt-1 tw-mb-3">
		</h3>
		<tree
			v-if="production.length > 0"
			:data="production"
			:options="{ checkbox: false }"
			@node:selected="onNodeSelected"
		/>

		<!-- Dev dependencies -->
		<h3 class="tw-mb-0">
			Dev Dependencies ({{ devDependencies.length }})
			<hr class="tw-mt-1 tw-mb-3">
		</h3>
		<tree
			v-if="development.length > 0"
			:data="development"
			:options="{ checkbox: false }"
			@node:selected="onNodeSelected"
		/>
	</div>
</template>

<script>

export default {
	props : {
		prodDependencies : {
			type     : Array,
			required : true
		},
		devDependencies : {
			type     : Array,
			required : true
		}
	},
	components : {},
	data() {
		return {
			production  : [],
			development : [],
			loading     : false,
			errors      : []
		};
	},
	mounted(){
		this.initData();
	},
	computed : {},
	methods  : {
		initData() {
			var app = this;
			app.production = app.prodDependencies.map(
				function( dependency ){
					return { "text": dependency.name + " " + app.formatVersion( dependency.version ), "children": [] };
				} );

			app.development = app.devDependencies.map(
				function( dependency ){
					return { "text": dependency.name + " " + app.formatVersion( dependency.version ), "children": [] };
				} );
		},
		onNodeSelected( node ) {
			// If the node has children already, do not load them again
			if ( node.hasChildren() ){
				return;
			}
			var app = this;
			var slug = node.text.split( " " )[0];

			app.$http.get(
				`/view/${slug}/dependencies`
			)
				.then( response => {
					node.addChild( { "text": "License: " + app.getLicenseText( response.data.data.licenses ), "showChildren": true, "state": { "expanded": false, "selectable": false, "disabled": true } } );
					for ( var dependency of response.data.data.prodDependencies ) {
						node.addChild( { "text": dependency.name + " " + app.formatVersion( dependency.version ) } );
					}
					node.expand();
				} )
				.catch( err => {
					app.loading = false;
					app.errors = [ "The dependencies couldn't be retrieved. Please try again." ];
				} );
		},
		formatVersion( version ) {
			return version.replace( "^", "" );
		},
		getLicenseText( licenses ) {
			var text = licenses.reduce( ( text, license ) => {
				if ( license.type != "" ){
					return text.concat( "  ", license.type );
				}
				return text;
			}, "" );
			if ( text == "" ){
				return "Not specified";
			} else {
				return text;
			}
		}
	}
};
</script>

<style lang="scss">
	.tree-anchor {
		// background-color: cyan !important;
	}
</style>