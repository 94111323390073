<template>
	<div class="container bg-white py-3 my-3">
		<form
			enctype="multipart/form-data"
			@submit.prevent="submit"
		>
			<div class="d-md-flex">
				<div class="flex-1">
					<nav
						aria-label="breadcrumb"
						role="navigation"
					>
						<ol class="breadcrumb mb-0">
							<li class="breadcrumb-item">
								<a :href="`/@${publisher}`">
									<i class="fas fa-home info" />
								</a>
							</li>
							<li class="breadcrumb-item">
								<a :href="`/package/${entryid}/edit`">{{ packagename }}</a>
							</li>
							<li
								v-if="editMode"
								class="breadcrumb-item active"
								aria-current="page"
							>
								v{{ packageVersion }}
							</li>
							<li
								v-else
								class="breadcrumb-item active"
								aria-current="page"
							>
								Create Version
							</li>
						</ol>
					</nav>
				</div>
				<div class="flex-1 mt-1">
					<div class="float-right">
						<!--- Save Button --->
						<button
							:disabled="loading"
							type="submit"
							class="btn btn-primary btn-sm m-1"
						>
							<span class="btn-inner--icon">
								<i
									v-show="loading"
									id="loader"
									class="fa fa-circle-o-notch fa-spin"
								/>
								<i
									v-show="!loading"
									class="fa fa-save mr-1"
								/>
								Save
							</span>
						</button>
						<!--- View Package Version Button --->
						<a
							v-if="editMode"
							:href="`/view/${slug}/version/${packageVersion}`"
							class="btn btn-info btn-sm m-1"
							title="View Package Version"
						>
							<span class="btn-inner--icon">
								<i class="fa fa-eye" /> View Package Version
							</span>
						</a>
					</div>
				</div>
			</div>

			<!-- Alerts -->
			<div>
				<div
					v-if="errors.length > 0"
					class="text-center mt-3"
				>
					<!-- Errors -->
					<Alert
						class="mt-3"
						type="danger"
						:messages="errors.toString()"
					/>
				</div>

				<div
					v-else-if="confirmation != ''"
					class="text-center mt-3"
				>
					<!-- Success -->
					<Alert
						class="mt-3"
						type="success"
						:messages="confirmation"
					/>
				</div>
			</div>

			<hr class="my-3">

			<div>
				<div class="nav-links mx-0 position-sticky">
					<ul
						class="nav nav-pills nav-fill flex-column flex-sm-row"
						id="tabs-text"
						role="tablist"
					>
						<li class="nav-item">
							<a
								href="#details"
								data-toggle="tab"
								title="Public Packages"
								class="nav-link mb-sm-3 mb-md-0 active"
								role="tab"
								aria-controls="public"
								aria-selected="true"
							><i class="fa fa-bookmark mr-2" />Details</a>
						</li>
						<li
							class="nav-item"
						>
							<a
								href="#boxjson"
								data-toggle="tab"
								title="box.json file"
								class="nav-link mb-sm-3 mb-md-0"
								role="tab"
								aria-controls="boxjson"
								aria-selected="false"
							><i class="fa fa-code mr-2" />Box.json</a>
						</li>
						<li
							class="nav-item"
						>
							<a
								href="#zip"
								data-toggle="tab"
								title="Zip file"
								class="nav-link mb-sm-3 mb-md-0"
							><i class="fa fa-file-archive mr-2" />Package zip</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="card mt-2">
				<div>
					<div
						class="tab-content"
						id="package-version-details"
					>
						<div
							class="tab-pane fade active show"
							id="details"
							role="tabpanel"
							aria-labelledby="details-tab"
						>
							<div>
								<div class="card-body">
									<div class="d-flex mb-2">
										<label class="flex-1 h4 mt-2">
											Description
										</label>
										<select
											v-model="form.descriptionFormat"
											name="descriptionFormat"
											id="descriptionFormat"
											class="form-control form-control-sm flex-1"
										>
											<option
												v-for="option in formatOptions"
												:key="option.value"
												:value="option.value"
											>
												{{ option.name }}
											</option>
										</select>
									</div>

									<div class="form-group-alternative">
										<simplemde
											v-model="form.description"
											class="form-control"
											id="description"
											name="description"
											rows="8"
											placeholder="Enter description here"
										/>
									</div>

									<hr>

									<div class="d-flex mb-2">
										<label class="flex-1 h4">
											Install Instructions
										</label>
										<select
											v-model="form.installInstructionsFormat"
											name="installInstructionsFormat"
											id="installInstructionsFormat"
											class="form-control form-control-sm flex-1"
										>
											<option
												v-for="option in formatOptions"
												:key="option.value"
												:value="option.value"
											>
												{{ option.name }}
											</option>
										</select>
									</div>

									<div class="form-group-alternative">
										<simplemde
											v-model="form.installInstructions"
											class="form-control"
											id="installInstructions"
											name="installInstructions"
											rows="4"
											placeholder="Enter install instructions here"
										/>
									</div>

									<hr>

									<div class="d-flex mb-2">
										<label class="flex-1 h4">
											Changelog
										</label>
										<select
											v-model="form.changelogFormat"
											name="changelogFormat"
											id="changelogFormat"
											class="form-control form-control-sm flex-1"
										>
											<option
												v-for="option in formatOptions"
												:key="option.value"
												:value="option.value"
											>
												{{ option.name }}
											</option>
										</select>
									</div>

									<div class="form-group-alternative">
										<simplemde
											v-model="form.changelog"
											class="form-control"
											id="changelog"
											name="changelog"
											rows="4"
											placeholder="Enter changelog here"
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							class="tab-pane fade"
							id="boxjson"
							role="tabpanel"
							aria-labelledby="boxjson-tab"
						>
							<v-jsoneditor
								v-model="form.boxjson"
								class="mb-3"
								:options="options"
								:plus="true"
								mode="code"
								height="600px"
								@error="onError"
							/>
						</div>
						<div
							class="tab-pane fade"
							id="zip"
							role="tabpanel"
							aria-labelledby="zip-tab"
						>
							<div
								class="flex text-center"
								id="app"
							>
								<div
									id="fileUploadContainter"
									class="border pb-4"
									@dragover="dragover"
									@dragleave="dragleave"
									@drop="drop"
								>
									<input
										type="file"
										name="zip"
										id="assetsFieldHandle"
										style="display: none;"
										@change="form.zip = $event.target.files[0]"
										ref="file"
										accept=".zip"
									/>

									<label
										for="assetsFieldHandle"
										class="d-block pointer mt-5"
									>
										<div
											v-if="!this.form.zip != ''"
											class="d-block"
										>
											<i class="fa fa-file-archive fa-3x" />
										</div>
										<div v-else>
											<i class="fa fa-check-circle fa-3x" />
										</div>
										<div class="pointer mt-4">
											<span v-if="!this.form.zip != ''">
												<strong>Upload a file</strong> or drag and drop
											</span>
											<span v-else>
												The file <strong>{{ form.zip.name }}</strong> is ready to be uploaded
											</span>
										</div>
									</label>
									<div v-if="this.form.zip != ''">
										<button
											class="btn btn-secondary btn-sm mt-2"
											type="button"
											@click="removeFile()"
											title="Remove file"
										>
											<i class="fa fa-close" />
											Remove
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="text-center mt-3">
				<a
					:href="`/package/${entryid}/edit`"
					class="btn btn-secondary mt-2"
					title="Cancel"
				>
					Cancel
				</a>
				<button
					type="submit"
					class="btn btn-primary mt-2"
					:disabled="loading"
				>
					<i
						v-show="loading"
						id="loader"
						class="fa fa-circle-o-notch fa-spin"
					/>
					Save
				</button>
			</div>
		</form>
	</div>
</template>

<script>

export default {
	name  : "PackageVersionForm",
	props : {
		slug : {
			type     : String,
			required : true
		},
		entryid : {
			type     : String,
			required : true
		},
		packagename : {
			type     : String,
			required : true
		},
		versionid : {
			type     : String,
			required : true
		},
		publisher : {
			type     : String,
			required : true
		}
	},
	components : {  },
	data() {
		return {
			loading       : false,
			confirmation  : "",
			errors        : [],
			options       : { mode: "code" },
			formatOptions : [
				{ value: "html", name: "HTML" },
				{ value: "md", name: "Markdown" },
				{ value: "text", name: "Text" }
			],
			packageVersion : "",
			form           : {
				versionID                 : "",
				description               : "",
				descriptionFormat         : "md",
				installInstructions       : "",
				installInstructionsFormat : "md",
				changelog                 : "",
				changelogFormat           : "md",
				boxjson                   : {},
				zip                       : ""
			}
		};
	},
	computed : {
		editMode(){
			return this.versionid != "" ? true : false;
		}
	},
	mounted(){
		this.$nextTick( () => {
			if ( this.editMode ) {
				this.loadPackageVersion();
			}
		} );
	},
	methods : {
		loadPackageVersion() {
			var app = this;
			app.$http.get( "/package/getVersion", { params: { versionID: this.versionid } } )
				.then( function( response ) {
					// handle success
					app.packageVersion = response.data.version;
					app.form.versionID = response.data.entryVersionID;
					app.form.description = response.data.description;
					app.form.descriptionFormat = response.data.descriptionFormat;
					app.form.installInstructions = response.data.installInstructions;
					app.form.installInstructionsFormat = response.data.installInstructionsFormat;
					app.form.changelog = response.data.changelog;
					app.form.changelogFormat = response.data.changelogFormat;
					app.form.boxjson = JSON.parse( response.data.boxJSON );

				} )
				.catch( function( error ) {
					// handle error
					app.errors = [ "The package version could not be found" ];
				} );
		},
		submit() {
			var app = this;
			app.loading = true;
			app.confirmation = "";
			app.errors = [];
			const data = new FormData();
			data.append( "versionID", app.form.versionID );
			data.append( "slug", app.slug );
			data.append( "description", app.form.description );
			data.append( "descriptionFormat", app.form.descriptionFormat );
			data.append( "installInstructions", app.form.installInstructions );
			data.append( "installInstructionsFormat", app.form.installInstructionsFormat );
			data.append( "changelog", app.form.changelog );
			data.append( "changelogFormat", app.form.changelogFormat );
			data.append( "boxjson", JSON.stringify( app.form.boxjson ) );

			if ( this.form.zip != "" ) {
				data.append( "zip", this.form.zip );
			}
			if ( this.editMode ) {
				data.append( "editMode", true );
			}
			app.$http.post( "/package/saveVersion", data )
				.then( function( response ) {
					// handle success
					if ( response.data.error ) {
						app.errors = response.data.messages;
						app.loading = false;
						$( window ).scrollTop( 0 );
					} else {
						app.loading = false;
						if ( app.editMode ) {
							app.confirmation = "The package version has been saved successfully.";
							$( window ).scrollTop( 0 );
						} else {
							app.confirmation = "The package version has been created successfully.";
							window.location = "/package/" + app.entryid + "/edit";
						}
					}
				} )
				.catch( function( error ) {
					// handle error
					app.loading = false;
					app.errors = [ "Oops something went wrong. Please try again" ];
					$( window ).scrollTop( 0 );
					console.log( error );
				} );
		},
		onInputFileChange( event ) {
			this.form.zip = this.$refs.file.files[0];
			$( "#fileUploadContainter" ).addClass( "alert-forgebox" );
			$( "#fileUploadContainter" ).removeClass( "bg-white" );
		},
		removeFile() {
			this.form.zip = "";
			$( "#fileUploadContainter" ).addClass( "bg-white" );
			$( "#fileUploadContainter" ).removeClass( "alert-forgebox" );
		},
		dragover( event ) {
			event.preventDefault();
			// Add some visual fluff to show the user can drop its files
			if ( !event.currentTarget.classList.contains( "bg-secondary" ) ) {
				event.currentTarget.classList.remove( "alert-forgebox" );
				event.currentTarget.classList.remove( "bg-white" );
				event.currentTarget.classList.add( "bg-secondary" );
			}
		},
		dragleave( event ) {
			// Clean up
			event.currentTarget.classList.add( "bg-white" );
			event.currentTarget.classList.remove( "bg-secondary" );
		},
		drop( event ) {
			event.preventDefault();
			this.$refs.file.files = event.dataTransfer.files;
			this.onInputFileChange(); // Trigger the onInputFileChange event manually
			// Clean up
			event.currentTarget.classList.add( "alert-forgebox" );
			event.currentTarget.classList.remove( "bg-secondary" );
		},
		onError() {
			// this.errors = [ "Please enter a valid box.json" ];
		}
	}
};

</script>

<style>
</style>