<template>
	<div
		class="modal fade"
		id="downgradeModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="Downgrade Plan"
		aria-hidden="true"
	>
		<div
			class="modal-dialog"
			role="document"
		>
			<div class="modal-content">
				<form>
					<div class="modal-header">
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<h1
							class="modal-title"
							id="exampleModalLabel"
						>
							Are you sure?
						</h1>
					</div>
					<div class="modal-body">
						Downgrading to the <strong>{{ newplan | capitalize }}</strong>
						plan will disable all the features of the  <strong>{{ current | capitalize }}</strong> plan
						{{ newplan == 'community' ? 'on the subscription end date' : 'immediately' }}
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							type="button"
							class="btn btn-danger"
							@click="changePlan(newplan)"
							:disabled="isLoading"
						>
							<i
								v-show="isLoading"
								class="fa fa-spinner fa-spin"
							/>
							<span v-if="isLoading">Downgrading...</span>
							<span v-else>Downgrade Now</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		current : {
			type     : String,
			required : true
		},
		newplan : {
			type     : String,
			required : true
		},
		subscriptionid : {
			type     : String,
			required : true
		}
	},
	data() {
		return { isLoading: false };
	},
	mounted(){
	},
	computed : {},
	methods  : {
		changePlan( plan ) {
			this.isLoading = true;
			var url = "/api/v1/changePlan/"+plan;
			var method = "post";
			// If downgrading to community then delete the subscription
			if ( plan == "community" ) {
				url = "/api/v1/subscriptions/"+this.subscriptionid;
				method = "delete";
			}
			$.ajax( {
				url    : url,
				method : method
			} )
				.then( function( result ) {
					this.isLoading = false;
					location.reload();
				} )
				.catch( function( err ) {
					this.isLoading = false;
					alert( "An error has occurred in the downgrade process" );
				} );
		}
	},
	filters : {
		capitalize : function( value ) {
			if ( !value ) return "";
			value = value.toString();
			return value.charAt( 0 ).toUpperCase() + value.slice( 1 );
		}
	}
};
</script>