<template>
	<div>
		<main>
			<div class="lg:tw-border-t lg:tw-border-b lg:tw-border-gray-200 tw-bg-gray-100 tw-border-2">
				<nav
					class="tw-mx-auto"
					aria-label="Stats"
				>
					<ol class="tw-rounded-md tw-overflow-hidden lg:tw-flex lg:tw-border-l lg:tw-border-r lg:tw-border-gray-200 lg:tw-rounded-none">
						<li class="tw-relative tw-overflow-hidden lg:tw-flex-1">
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden tw-border-b-0 tw-rounded-t-md lg:tw-border-0">
								<!-- Overview Tab -->
								<div
									class="tw-group tw-border-r"
									:class="{ 'tw-cursor-pointer' : activeTab != 'overview' }"
									@click="changeTab('overview')"
								>
									<span
										:class="activeTab == 'overview' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium">
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'overview' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Overview
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">
												Global stats summary
											</span>
										</span>
									</span>
								</div>
							</div>
						</li>

						<li
							class="tw-relative tw-overflow-hidden lg:tw-flex-1"
						>
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden lg:tw-border-0">
								<!-- Top Packages Tab -->
								<div
									class="tw-group tw-border-r"
									:class="{ 'tw-cursor-pointer' : activeTab != 'top' }"
									@click="changeTab('top')"
								>
									<span
										:class="activeTab == 'top' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium lg:tw-pl-9">
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'top' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Top Packages
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">
												Top downloaded and rated packages
											</span>
										</span>
									</span>
								</div>
							</div>
						</li>

						<li
							class="tw-relative tw-overflow-hidden lg:tw-flex-1"
						>
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden lg:tw-border-0">
								<!-- Most Recent Packages Tab -->
								<div
									class="tw-group"
									:class="{ 'tw-cursor-pointer' : activeTab != 'recent' }"
									@click="changeTab('recent')"
								>
									<span
										:class="activeTab == 'recent' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium lg:tw-pl-9">
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'recent' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Most Recent Packages
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">
												Packages created and updated this month
											</span>
										</span>
									</span>
								</div>
							</div>
						</li>
					</ol>
				</nav>
			</div>
			<!-- Overview Tab -->
			<div
				v-if="activeTab == 'overview'"
				class="md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-p-5 tw-rounded-lg"
			>
				<dl class="tw-divide-y tw-divide-gray-200">
					<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
						<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
							Total Authors
						</dt> <dd class="tw-mt-1 tw-flex tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
							<span class="tw-flex-grow">
								{{ totalAuthors }}
							</span>
						</dd>
					</div> <div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
						<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
							Total Packages
						</dt> <dd class="tw-mt-1 tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
							<span>
								{{ totalPackages }}
							</span>
						</dd>
					</div>
					<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
						<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
							<span>
								Packages published in the last {{ publishedPackagesValue }} {{ publishedPackagesFilter }}
							</span>
						</dt>
						<dd class="tw-mt-1 tw-flex tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
							<span class="tw-flex-grow">
								{{ dynamicPackagesCount }}
							</span>
							<span class="tw-ml-4 tw-flex-shrink-0">
								<span>
									<input
										v-model="publishedPackagesValue"
										@change="validateFilters('packages')"
										class="tw-bg-transparent tw-text-sm tw-border-transparent tw-text-gray-500 tw-w-20 tw-h-6"
										type="number"
										min="1"
										:max="getMaxValue( 'packages' )"
									>
								</span>
								<select
									v-model="publishedPackagesFilter"
									@change="validateFilters('packages')"
									class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-h-full tw-py-0 tw-pl-2 tw-border-transparent tw-bg-transparent tw-text-gray-500 sm:tw-text-sm tw-rounded-md"
								>
									<option value="days">
										Days
									</option>
									<option value="months">
										Months
									</option>
									<option value="years">
										Years
									</option>
								</select>
							</span>
						</dd>
					</div>
					<!-- Registrations stats available only for admins -->
					<div
						v-if="isAdmin"
						class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4"
					>
						<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
							<span>
								Registrations in the last {{ newRegistrationsValue }} {{ newRegistrationsFilter }}
							</span>
						</dt> <dd class="tw-mt-1 tw-flex tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
							<span class="tw-flex-grow">
								{{ dynamicRegistrationsCount }}
							</span>
							<span class="tw-ml-4 tw-flex-shrink-0">
								<span>
									<input
										v-model="newRegistrationsValue"
										@change="validateFilters('registrations')"
										class="tw-bg-transparent tw-text-sm tw-border-transparent tw-text-gray-500 tw-w-20 tw-h-6"
										type="number"
										min="1"
										:max="getMaxValue( 'registrations' )"
									>
								</span>
								<select
									v-model="newRegistrationsFilter"
									@change="validateFilters('registrations')"
									class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-h-full tw-py-0 tw-pl-2 tw-border-transparent tw-bg-transparent tw-text-gray-500 sm:tw-text-sm tw-rounded-md"
								>
									<option value="days">
										Days
									</option>
									<option value="months">
										Months
									</option>
									<option value="years">
										Years
									</option>
								</select>
							</span>
						</dd>
					</div>
				</dl>
			</div>
			<!-- Top Packages content -->
			<div
				v-if="activeTab == 'top'"
				class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-my-6"
			>
				<div class="tw-mt-6">
					<div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-2">
						<div>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								Top 10 downloaded packages
							</h2>
							<div
								class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3"
							>
								<top-packages
									:packages="topInstalledPackages"
									filter="installs"
								/>
							</div>
						</div>

						<div>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								Top 10 rated packages
							</h2>
							<div class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3">
								<top-packages
									:packages="topRatedPackages"
									filter="rating"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Most recent packages content -->
			<div
				v-if="activeTab == 'recent'"
				class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-my-6"
			>
				<div class="tw-mt-6">
					<div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-2">
						<div>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								This month's new packages
							</h2>
							<div
								class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3"
							>
								<top-packages
									:packages="topNewPackages"
									filter="new"
								/>
							</div>
						</div>

						<div>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								This month's updated packages
							</h2>
							<div class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3">
								<top-packages
									:packages="topUpdatedPackages"
									filter="updated"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
		<!-- Alert Box -->
		<Alert
			type="error"
			:messages="errors"
			class="m-3"
		/>
	</div>
</template>

<script>
import TopPackages from "./TopPackages.vue";

export default {
	props : {
		isAdmin : {
			type     : Boolean,
			required : true,
			default  : false
		},
		topInstalledPackages : {
			type     : Array,
			required : true
		},
		topRatedPackages : {
			type     : Array,
			required : true
		},
		topNewPackages : {
			type     : Array,
			required : true
		},
		topUpdatedPackages : {
			type     : Array,
			required : true
		},
		totalAuthors : {
			type     : Number,
			required : true,
			default  : 0
		},
		totalPackages : {
			type     : Number,
			required : true,
			default  : 0
		},
		filteredPackages : {
			type     : Number,
			required : true,
			default  : 0
		},
		filteredRegistrations : {
			type     : Number,
			required : true,
			default  : 0
		}
	},
	components : { TopPackages },
	data() {
		return {
			activeTab                 : "overview",
			loading                   : false,
			errors                    : [],
			publishedPackagesValue    : 30,
			publishedPackagesFilter   : "days",
			newRegistrationsValue     : 30,
			newRegistrationsFilter    : "days",
			dynamicPackagesCount      : this.filteredPackages,
			dynamicRegistrationsCount : this.filteredRegistrations
		};
	},
	methods : {
		validateFilters( type ) {
			if ( type == "packages" ) {
				var maxValue = this.getMaxValue( "packages" );
				if ( Number( this.publishedPackagesValue ) > maxValue ) {
					this.publishedPackagesValue = maxValue;
				} else if ( Number( this.publishedPackagesValue ) < 1  ) {
					this.publishedPackagesValue = 1;
				}
				this.getPublishedPackages();
			} else if ( type == "registrations" ){
				var maxValue = this.getMaxValue( "registrations" );
				if ( Number( this.newRegistrationsValue ) > maxValue ) {
					this.newRegistrationsValue = maxValue;
				} else if ( Number( this.newRegistrationsValue ) < 1  ) {
					this.newRegistrationsValue = 1;
				}
				this.getRegistrations();
			}
		},
		changeTab( tab ) {
			this.activeTab = tab;
		},
		getPublishedPackages() {
			var app = this;
			app.loading = true;
			app.errors = [];
			if ( app.publishedPackagesValue > 0 ) {
				app.$http.get(
					"/stats/publishedPackages",
					{ params: { value: app.publishedPackagesValue, filterBy: app.publishedPackagesFilter } } )
					.then( function( response ) {
						app.loading = false;
						if ( !response.data.error ){
							app.dynamicPackagesCount = response.data.count;
						} else {
							// set error messages
							app.errors = response.data.messages;
						}
					} )
					.catch( function( response ) {
						app.errors = response.messages;
					} );
			} else {
				app.dynamicPackagesCount = 0;
			}
		},
		getRegistrations() {
			var app = this;
			app.loading = true;
			app.errors = [];
			if ( app.newRegistrationsValue > 0 ) {
				app.$http.get(
					"/stats/registrations",
					{ params: { value: app.newRegistrationsValue, filterBy: app.newRegistrationsFilter } } )
					.then( function( response ) {
						app.loading = false;
						if ( !response.data.error ){
							app.dynamicRegistrationsCount = response.data.count;
						} else {
							// set error messages
							app.errors = response.data.messages;
						}
					} )
					.catch( function( response ) {
						app.errors = response.messages;
					} );
			} else {
				app.dynamicRegistrationsCount = 0;
			}
		},
		getMaxValue( type ) {
			// The first package was created on 2009
			var totalYears = ( new Date().getFullYear() - new Date( "2009-01-01" ).getFullYear() );
			var maxValueInDays = Math.floor( totalYears * 365 );

			if ( type == "packages" ) {
				if ( this.publishedPackagesFilter == "months" ) {
					return Math.floor( maxValueInDays/30 );
				} else if ( this.publishedPackagesFilter == "years" )  {
					return Math.floor( maxValueInDays/365 );
				}
				return maxValueInDays;
			} else {
				if ( this.newRegistrationsFilter == "months" ) {
					return Math.floor( maxValueInDays/30 );
				} else if ( this.newRegistrationsFilter == "years" )  {
					return Math.floor( maxValueInDays/365 );
				}
				return maxValueInDays;
			}

		}
	}
};
</script>
