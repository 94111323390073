<template>
	<div>
		<div class="card-body subscription-body-card">
			<div class="row">
				<div class="col-lg-12">
					<label class="form-control-label">Choose Plan: </label>
					<span class="float-right"><small>
						<i class="fa fa-info-circle" />
						<a
							href="plans"
							rel="noopener"
							target="_blank"
						>Compare plans</a>
					</small>
					</span>
					<select
						name="selectedPlan"
						id="selectedPlan"
						class="form-control form-control-sm"
						v-model="selectedPlan"
						:disabled="cancelled"
					>
						<option
							v-for="plan in plansList"
							:value="plan.slug"
							:key="plan.slug"
						>
							{{ plan.name }}
						</option>
					</select>
				</div>
			</div>
			<hr class="my-2">
			<div v-if="!cancelled">
				<div class="row">
					<div class="col-lg-12">
						<label v-if="isUpgrading">Upgrading from <strong>{{ current | capitalize }}</strong> plan to the <strong>{{ selectedPlan | capitalize }}</strong> plan  will enable:</label>
						<label v-else>Downgrading from <strong>{{ current | capitalize }}</strong> plan to the <strong>{{ selectedPlan | capitalize }}</strong> plan  will disable:</label>
					</div>
				</div>
				<div :class="isUpgrading ? 'features-upgrade' : 'features-downgrade'">
					<div v-html="planFeatures" />
				</div>
				<hr class="my-2">

				<div v-if="current === 'community'">
					<a
						:href="'subscribe/'+selectedPlan"
						title="Subscribe Now!"
						class="btn btn-primary mt-3"
					>
						<i class="fa fa-rocket" />
						Subscribe Now (${{ selectedPlanDetails.price }}/month)
					</a>
				</div>
				<div v-else-if="isUpgrading">
					<button
						v-if="!complimentary"
						type="button"
						href="##"
						class="btn btn-primary mt-3"
						data-toggle="modal"
						data-target="#upgradeModal"
					>
						<i class="fa fa-rocket" />
						Upgrade to {{ selectedPlanDetails.name }}
						(${{ selectedPlanDetails.price }}/month)
					</button>
					<a
						v-if="complimentary"
						type="button"
						:href="'subscribe/'+selectedPlan"
						class="btn btn-primary mt-3"
					>
						<i class="fa fa-rocket" />
						Upgrade to {{ selectedPlanDetails.name }}
						(${{ selectedPlanDetails.price }}/month)
					</a>
				</div>
				<!-- Downgrading -->
				<div v-else>
					<a
						v-if="complimentary && selectedPlan != 'community'"
						type="button"
						:href="'subscribe/'+selectedPlan"
						class="btn btn-danger mt-3"
					>
						<i class="fa fa-arrow-down" />
						Downgrade to {{ selectedPlan | capitalize }} Plan
						<span v-show="selectedPlanDetails.slug !== 'community'">
							(${{ selectedPlanDetails.price }}/month)
						</span>
					</a>
					<button
						v-if="!complimentary || selectedPlan == 'community'"
						type="button"
						href="##"
						class="btn btn-danger mt-3"
						data-toggle="modal"
						data-target="#downgradeModal"
					>
						<i class="fa fa-arrow-down" />
						Downgrade to {{ selectedPlan | capitalize }} Plan
						<span v-show="selectedPlanDetails.slug !== 'community'">
							(${{ selectedPlanDetails.price }}/month)
						</span>
					</button>
				</div>
			</div>
			<div v-else>
				<Alert
					type="forgebox mt-4"
					messages="You must be in an active subscription to make a change plan."
					:dismiss="false"
				/>
			</div>
		</div>
		<downgrade-plan
			:current="current"
			:newplan="selectedPlan"
			:subscriptionid="subscriptionid"
		/>
		<upgrade-plan
			:current="current"
			:newplan="selectedPlan"
			:subscriptionid="subscriptionid"
		/>
	</div>
</template>

<script>
import DowngradePlan from "./DowngradePlan";
import UpgradePlan from "./UpgradePlan";

export default {
	props : {
		current : {
			type     : String,
			required : true
		},
		subscriptionid : {
			type     : String,
			required : true
		},
		complimentary : {
			type    : Boolean,
			default : false
		},
		cancelled : {
			type    : Boolean,
			default : false
		}
	},
	components : {
		DowngradePlan,
		UpgradePlan
	},
	data() {
		return {
			plans        : [],
			selectedPlan : this.current == "pro" ? "community" : "pro"
		};
	},
	mounted(){
		this.$nextTick( () => {
			this.loadPlans();
		} );
	},
	computed : {
		isUpgrading() {
			switch ( this.current ) {
			case "community":
				return this.selectedPlan !== "community" ? true : false;
				break;
			case "pro":
				return this.selectedPlan !== "pro" ? false : false;
				break;
			default:
				return true;
			}
		},
		plansList() {
			return this.plans.filter( plan => plan.slug !== this.current );
		},
		selectedPlanDetails() {
			var planDetails = this.plans.find( plan => plan.slug === this.selectedPlan );
			return planDetails !== undefined ? planDetails : { price: 0 };
		},
		planFeatures() {
			if ( this.isUpgrading ) {
				return this.selectedPlanDetails.featuresHtml !== undefined ? this.selectedPlanDetails.featuresHtml : "";
			} else {
				var planDetails = this.plans.find( plan => plan.slug === this.current );
				return planDetails !== undefined ? planDetails.featuresHtml : "";
			}
		},
	},
	methods : {
		loadPlans() {
			$.ajax( {
				url    : "/ui/plans/list",
				method : "get"
			} )
				.then( response => {
					this.plans = response.data;
				} )
				.catch( err => {
					// Show error
				} );
		},
		getPlanDetails( slug ) {
			return this.plans.find( plan => plan.slug === slug );
		}
	},
	filters : {
		capitalize : function( value ) {
			if ( !value ) return "";
			value = value.toString();
			return value.charAt( 0 ).toUpperCase() + value.slice( 1 );
		}
	}
};
</script>