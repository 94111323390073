<template>
	<div>
		<div
			v-if="display"
			class="lg:tw-hidden tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->

		<transition
			enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-200 sm:tw-duration-200"
			enter-class="tw-translate-x-full"
			enter-to-class="tw-translate-x-0"
			leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-200 sm:tw-duration-200"
			leave-class="tw-translate-x-0"
			leave-to-class="tw-translate-x-full"
		>
			<div
				v-if="display"
				class="lg:tw-hidden"
			>
				<div class="tw-fixed tw-inset-0 tw-flex tw-z-40">
					<!--
					Off-canvas menu overlay, show/hide based on off-canvas menu state.

					Entering: "transition-opacity ease-linear duration-300"
					From: "opacity-0"
					To: "opacity-100"
					Leaving: "transition-opacity ease-linear duration-300"
					From: "opacity-100"
					To: "opacity-0"

				<div class="tw-fixed tw-inset-0" aria-hidden="true">
					<div class="tw-absolute tw-inset-0 tw-bg-gray-600 tw-opacity-75"></div>
				</div>-->
					<!--
					Off-canvas menu, show/hide based on off-canvas menu state.

					Entering: "transition ease-in-out duration-300 transform"
					From: "-translate-x-full"
					To: "translate-x-0"
					Leaving: "transition ease-in-out duration-300 transform"
					From: "translate-x-0"
					To: "-translate-x-full"
				-->
					<div
						class="tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-xs tw-w-full tw-pt-5 tw-pb-4 bg-forgebox"
					>
						<a href="/">
							<div class="tw-flex tw-flex-row tw-ml-4">
								<img
									class="tw-flex tw-h-9 tw-w-auto"
									src="/includes/images/ForgeBoxIcon100.png"
									alt="FORGEBOX"
								/>

								<span
									class="tw-ml-4 tw-mt-2 tw-text-white tw-text-sm tw-font-medium"
								>
									FORGEBOX
								</span>
							</div>
						</a>
						<div class="tw-absolute tw-top-0 tw-right-0 tw--mr-12 tw-pt-2">
							<button
								class="tw-ml-1 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white"
								@click="display = !display"
							>
								<span class="tw-sr-only">Close sidebar</span>
								<!-- Heroicon name: x -->
								<svg
									class="tw-h-6 tw-w-6 tw-text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div class="tw-mt-5 tw-flex-1 tw-h-0 tw-overflow-y-auto">
							<nav class="tw-px-2">
								<div class="tw-space-y-1">
									<a
										href="/myaccount"
										:class="
											isActivePage('dashboard')
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-500"
									>
										<!-- Heroicon name: home -->
										<svg
											:class="
												isActivePage('dashboard')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
											/>
										</svg>
										Dashboard
									</a>

									<a
										href="/myaccount/packages"
										:class="
											isActivePage('packages')
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
									>
										<!-- Heroicon name: cube -->
										<svg
											:class="
												isActivePage('packages')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
											/>
										</svg>
										Packages
									</a>

									<a
										href="/myaccount/subscriptions"
										:class="
											isActivePage('subscriptions')
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
									>
										<!-- Heroicon name: bell -->
										<svg
											:class="
												isActivePage('subscriptions')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
											/>
										</svg>
										Subscriptions
									</a>

									<a
										href="/myaccount/collaborations"
										:class="
											isActivePage('collaborations')
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
									>
										<!-- Heroicon name: cube-transparent -->
										<svg
											:class="
												isActivePage('collaborations')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
											/>
										</svg>
										Collaborations
									</a>

									<a
										href="/myaccount/organizations"
										:class="
											isActivePage('organizations')
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
									>
										<!-- Heroicon name: office-building -->
										<svg
											:class="
												isActivePage('organizations')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
											/>
										</svg>
										Organizations
									</a>

									<span
										:class="
											isActiveSubMenu
												? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
												: 'tw-text-white hover:tw-bg-gray-50'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
										@click="showSettings = !showSettings"
									>
										<div class="tw-w-0 tw-flex-1">
											<!-- Heroicon name: cog -->
											<svg
												:class="showSettings ? 'tw-text-gray-500' : ''"
												class="tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-500 tw-ml-2 tw-mr-1 tw-inline"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
												/>
											</svg>
											Settings
										</div>
										<div class="tw-flex-shrink-0">
											<svg
												class="tw-text-gray-400 group-hover:tw-text-gray-500 tw-h-4 tw-w-4"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													v-if="showSettings"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M5 15l7-7 7 7"
												/>
												<path
													v-else
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 9l-7 7-7-7"
												/>
											</svg>
										</div>
									</span>
									<transition
										enter-class="tw-opacity-0 tw-transform tw-scale-95"
										enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
										enter-active-class="tw-transition ease-out duration-200"
										leave-class="tw-opacity-100 tw-transform tw-scale-100"
										leave-to-class="tw-opacity-0 tw-transform tw-scale-y-0"
										leave-active-class="tw-transition tw-ease-in tw-duration-75"
									>
										<div
											v-if="showSettings"
											class="bg-forgebox"
										>
											<a
												href="/myaccount/settings/profile"
												:class="
													isActivePage('profile')
														? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
														: 'tw-text-white hover:tw-bg-gray-50'
												"
												class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
											>
												<!-- Heroicon name: user-circle -->
												<svg
													:class="
														isActivePage('profile')
															? 'tw-text-blue-600 tw-font-bold tw-mx-1'
															: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
													"
													class="tw-h-6 tw-w-6"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
													/>
												</svg>
												Profile
											</a>

											<a
												href="/myaccount/settings/account"
												:class="
													isActivePage('account')
														? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
														: 'tw-text-white hover:tw-bg-gray-50'
												"
												class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
											>
												<!-- Heroicon name: identification -->
												<svg
													:class="
														isActivePage('account')
															? 'tw-text-blue-600 tw-font-bold tw-mx-1'
															: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
													"
													class="tw-h-6 tw-w-6"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
													/>
												</svg>
												Account
											</a>

											<!-- Billing -->
											<a
												href="/myaccount/settings/billing"
												:class="
													isActivePage('billing')
														? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
														: 'tw-text-white hover:tw-bg-gray-50'
												"
												class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
											>
												<!-- Heroicon name: currency-dollar -->
												<svg
													:class="{
														'tw-text-blue-600 tw-font-bold tw-mx-1': isActivePage(
															'billing'
														),
														'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2': !isActivePage(
															'billing'
														)
													}"
													class="tw-h-6 tw-w-6"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
													/>
												</svg>
												Plan & Billing
											</a>

											<a
												href="/myaccount/settings/tokens"
												:class="
													isActivePage('tokens')
														? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
														: 'tw-text-white hover:tw-bg-gray-50'
												"
												class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
											>
												<!-- Heroicon name: shield-check -->
												<svg
													:class="
														isActivePage('tokens')
															? 'tw-text-blue-600 tw-font-bold tw-mx-1'
															: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
													"
													class="tw-h-6 tw-w-6"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
													/>
												</svg>
												Access Tokens
											</a>

											<a
												href="/myaccount/activityLog"
												:class="
													isActivePage('activity-log')
														? 'tw-text-gray-300 hover:tw-bg-gray-50 hover:tw-text-white tw-font-bold'
														: 'tw-text-white hover:tw-bg-gray-50'
												"
												class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
											>
												<!-- Heroicon name: document-report -->
												<svg
													:class="
														isActivePage('activity-log')
															? 'tw-text-blue-600 tw-font-bold tw-mx-1'
															: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
													"
													class="tw-h-6 tw-w-6"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
													/>
												</svg>
												Activity Log
											</a>
										</div>
									</transition>
								</div>
							</nav>
						</div>
					</div>
					<div
						class="tw-flex-shrink-0 tw-w-14"
						aria-hidden="true"
					>
						<!-- Dummy element to force sidebar to shrink to fit close icon -->
					</div>
				</div>
			</div>
		</transition>
		<!-- Static sidebar for desktop -->
		<div class="tw-hidden tw-h-full lg:tw-flex lg:tw-flex-shrink-0">
			<div
				class="tw-flex tw-flex-col tw-w-64 tw-border-r tw-border-gray-200 tw-pb-4 tw-bg-gray-100"
			>
				<!-- Sidebar component, swap this element with another sidebar if you like -->
				<div class="tw-h-0 tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto">
					<!-- Switch context dropdown -->
					<div
						class="tw-px-1 tw-h-16 tw-pt-1 tw-relative tw-inline-block tw-text-left tw-border-b-2 tw-border-gray-200"
					>
						<context-switcher
							:orgs="orgs"
							:user="user"
						/>
					</div>
					<!-- Navigation -->
					<nav class="tw-mt-3">
						<div class="tw-space-y-1">
							<a
								href="/myaccount"
								:class="
									isActivePage('dashboard')
										? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
										: 'tw-text-gray-700 hover:tw-bg-gray-50'
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
							>
								<!-- Heroicon name: home -->
								<svg
									:class="
										isActivePage('dashboard')
											? 'tw-text-blue-600 tw-font-bold tw-mx-1'
											: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
									"
									class="tw-h-6 tw-w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
									/>
								</svg>
								Dashboard
							</a>

							<a
								href="/myaccount/packages"
								:class="
									isActivePage('packages')
										? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
										: 'tw-text-gray-700 hover:tw-bg-gray-50'
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
							>
								<!-- Heroicon name: cube -->
								<svg
									:class="
										isActivePage('packages')
											? 'tw-text-blue-600 tw-font-bold tw-mx-1'
											: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
									"
									class="tw-h-6 tw-w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
									/>
								</svg>
								Packages
							</a>

							<a
								href="/myaccount/subscriptions"
								:class="
									isActivePage('subscriptions')
										? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
										: 'tw-text-gray-700 hover:tw-bg-gray-50'
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
							>
								<!-- Heroicon name: bell -->
								<svg
									:class="
										isActivePage('subscriptions')
											? 'tw-text-blue-600 tw-font-bold tw-mx-1'
											: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
									"
									class="tw-h-6 tw-w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
									/>
								</svg>
								Subscriptions
							</a>

							<a
								href="/myaccount/collaborations"
								:class="
									isActivePage('collaborations')
										? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
										: 'tw-text-gray-700 hover:tw-bg-gray-50'
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
							>
								<!-- Heroicon name: cube-transparent -->
								<svg
									:class="
										isActivePage('collaborations')
											? 'tw-text-blue-600 tw-font-bold tw-mx-1'
											: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
									"
									class="tw-h-6 tw-w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
									/>
								</svg>
								Collaborations
							</a>

							<a
								href="/myaccount/organizations"
								:class="
									isActivePage('organizations')
										? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
										: 'tw-text-gray-700 hover:tw-bg-gray-50'
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900"
							>
								<!-- Heroicon name: office-building -->
								<svg
									:class="
										isActivePage('organizations')
											? 'tw-text-blue-600 tw-font-bold tw-mx-1'
											: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
									"
									class="tw-h-6 tw-w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
									/>
								</svg>
								Organizations
							</a>

							<span
								:class="
									isActiveSubMenu
										? 'tw-bg-gray-200 tw-text-gray-900 hover:tw-bg-gray-200'
										: ''
								"
								class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-text-gray-900 hover:tw-bg-gray-50 tw-cursor-pointer"
								@click="showSettings = !showSettings"
							>
								<div class="tw-w-0 tw-flex-1">
									<!-- Heroicon name: cog -->
									<svg
										:class="showSettings ? 'tw-text-gray-500' : ''"
										class="tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-500 tw-ml-2 tw-mr-1 tw-inline"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
										/>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
									</svg>
									Settings
								</div>
								<div class="tw-flex-shrink-0">
									<svg
										class="tw-text-gray-400 group-hover:tw-text-gray-500 tw-h-4 tw-w-4"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											v-if="showSettings"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M5 15l7-7 7 7"
										/>
										<path
											v-else
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M19 9l-7 7-7-7"
										/>
									</svg>
								</div>
							</span>
							<transition
								enter-class="tw-opacity-0 tw-transform tw-scale-95"
								enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
								enter-active-class="tw-transition ease-out duration-200"
								leave-class="tw-opacity-100 tw-transform tw-scale-100"
								leave-to-class="tw-opacity-0 tw-transform tw-scale-y-0"
								leave-active-class="tw-transition tw-ease-in tw-duration-75"
							>
								<div
									v-if="showSettings"
									class="tw-bg-gray-50"
								>
									<a
										href="/myaccount/settings/profile"
										:class="
											isActivePage('profile')
												? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
												: 'tw-text-gray-700 hover:tw-bg-white'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900 tw-pl-5"
									>
										<!-- Heroicon name: user-circle -->
										<svg
											:class="
												isActivePage('profile')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										Profile
									</a>

									<a
										href="/myaccount/settings/account"
										:class="
											isActivePage('account')
												? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
												: 'tw-text-gray-700 hover:tw-bg-white'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900 tw-pl-5"
									>
										<!-- Heroicon name: identification -->
										<svg
											:class="
												isActivePage('account')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
											/>
										</svg>
										Account
									</a>

									<!-- Billing -->
									<a
										href="/myaccount/settings/billing"
										:class="{
											'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4': isActivePage(
												'billing'
											),
											'tw-text-gray-700 hover:tw-bg-white':
												activePage != 'billing'
										}"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900 tw-pl-5"
									>
										<!-- Heroicon name: currency-dollar -->
										<svg
											:class="{
												'tw-text-blue-600 tw-font-bold tw-mx-1': isActivePage(
													'billing'
												),
												'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2': !isActivePage(
													'billing'
												)
											}"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										Plan & Billing
									</a>

									<a
										href="/myaccount/settings/tokens"
										:class="
											isActivePage('tokens')
												? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
												: 'tw-text-gray-700 hover:tw-bg-white'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900 tw-pl-5"
									>
										<!-- Heroicon name: shield-check -->
										<svg
											:class="
												isActivePage('tokens')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
											/>
										</svg>
										Access Tokens
									</a>

									<a
										href="/myaccount/activityLog"
										:class="
											isActivePage('activity-log')
												? 'tw-bg-blue-200 tw-border-blue-600 tw-text-blue-600 hover:tw-text-blue-500 tw-font-bold tw-border-l-4'
												: 'tw-text-gray-700 hover:tw-bg-white'
										"
										class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-text-gray-900 tw-pl-5"
									>
										<!-- Heroicon name: document-report -->
										<svg
											:class="
												isActivePage('activity-log')
													? 'tw-text-blue-600 tw-font-bold tw-mx-1'
													: 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2'
											"
											class="tw-h-6 tw-w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
											/>
										</svg>
										Activity Log
									</a>
								</div>
							</transition>
						</div>
					</nav>
				</div>
				<div
					class="tw-flex-shrink-0 tw-flex tw-border-t tw-border-gray-200 tw-pt-3"
				>
					<a
						href="/support"
						class="tw-group tw-flex tw-w-full tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
					>
						<!-- Heroicon name: cube-transparent -->
						<svg
							class="tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-500 tw-mx-2"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
							/>
						</svg>
						Support
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContextSwitcher from "../Shared/ContextSwitcher.vue";
import { EventBus } from "../../../app.js";

export default {
	props : {
		activePage : {
			type     : String,
			required : false,
			default  : ""
		},
		user : {
			type     : Object,
			required : true
		},
		orgs : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		show : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { ContextSwitcher },
	data() {
		return {
			showMainMenu      : false,
			showSwitchContext : false,
			showSettings      : false,
			display           : false
		};
	},
	mounted() {
		this.collapseSubmenu();
	},
	computed : {
		isActiveSubMenu() {
			if (
				this.activePage == "profile" ||
				this.activePage == "account" ||
				this.activePage == "tokens" ||
				this.activePage == "billing" ||
				this.activePage == "activity-log" ||
				this.showSettings
			) {
				return true;
			} else {
				return false;
			}
		}
	},
	methods : {
		collapseSubmenu() {
			if (
				this.activePage == "profile" ||
				this.activePage == "account" ||
				this.activePage == "tokens" ||
				this.activePage == "billing" ||
				this.activePage == "activity-log"
			) {
				this.showSettings = true;
			}
		},
		isActivePage( page ) {
			return this.activePage.includes( page );
		}
	},
	created() {
		this.display = this.show;
		EventBus.$on( "openUserSideBar", () => {
			this.display = !this.display;
		} );
	},
	destroyed() {
		EventBus.$off( "openUserSideBar" );
	}
};
</script>
