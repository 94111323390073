// Import Libs
import Vue from "vue";
import axios from "axios";
import VueTruncate from "vue-truncate-filter";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";
import VTooltip from "v-tooltip";
import Notifications from "vue-notification";
import VJsoneditor from "v-jsoneditor";
export const EventBus = new Vue();

// Import Custom Libs
import SharedComponents from "./components/shared";
import SharedFilters from "./filters";

// Make axios available globally
Vue.prototype.$http = axios;

// Import All UI Components
import * as components from "./components/ui";

// Register Vue Plugins
Vue.use( SharedComponents );
Vue.use( VueTruncate );
Vue.use( SharedFilters );
Vue.use( Notifications );
Vue.use( VueTruncate );
Vue.use( Vuelidate );
Vue.use( VueClipboard );
Vue.use( VTooltip );
Vue.use( VJsoneditor );

// Define Vue Model
new Vue( {
	el         : "#app",
	components : { ...components },
	data() {
		return {
			 globalDropdown  : false,
			 showUserSideBar : false
		};
	},
	methods : {
		buildCopyString( data ){
			return data;
		}
	}
} );
