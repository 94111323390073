<template>
	<!-- v-click-outside="() => (showDropdown = false)" -->
	<div>
		<div v-if="!isLoggedIn">
			<a
				href="/login"
				class="tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-ring-offset-2"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="gray"
					fill="none"
					class="tw-w-6 tw-h-6 tw-mr-2"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
					/>
				</svg>
				<span class="tw-text-gray-500">
					Subscribe
				</span>
			</a>
		</div>
		<div v-else>
			<button
				type="button"
				class="tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-ring-offset-2"
				aria-expanded="false"
				aria-haspopup="true"
				@click="closeMenu"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="gray"
					:fill="isSubscribed ? 'currentColor' : 'none'"
					class="tw-w-6 tw-h-6 tw-mr-2"
					:class="
						isSubscribed
							? 'tw-text-yellow-400'
							: 'tw-text-gray-400'
					"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
					/>
				</svg>
				{{ !isSubscribed ? "Subscribe" : "Subscribed" }}
			</button>
		</div>

		<transition
			enter-active-class="tw-transition ease-out duration-200"
			enter-class="tw-opacity-0 tw-transform tw-scale-95"
			enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
			leave-active-class="tw-transition tw-ease-in tw-duration-75"
			leave-class="tw-opacity-100 tw-transform tw-scale-100"
			leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
		>
			<div
				v-if="showDropdown"
				class="tw-mx-3 tw-absolute tw-right-10 tw-top-24 tw-w-80 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<div
					v-if="!showWebhookTab"
					class="tw-divide-y tw-divide-gray-200"
				>
					<div
						class="tw-py-1 tw-flex tw-items-center tw-justify-between"
						role="none"
					>
						<div class="tw-inline-flex">
							<span
								class="tw-block tw-px-4 tw-pt-2 tw-font-semibold tw-text-sm tw-text-gray-700"
							>
								Notifications
							</span>
						</div>
						<div class="tw-inline-flex tw-px-4 tw-pt-2">
							<!-- Close Button -->
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="tw-w-5 tw-h-5 hover:tw-text-gray-500 tw-cursor-pointer"
								@click="closeMenu"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</div>
					</div>

					<div
						class="tw-py-0"
						role="none"
					>
						<div
							class="tw-px-4 tw-py-4 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
							@click="toggleEmail"
						>
							<span
								v-if="subscribedToEmail"
								class="tw-inline-block"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="tw-h-5 tw-w-5 tw-inline-block"
									:class="{ 'tw-font-bold': true }"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
							</span>
							<span
								v-else
								class="tw-inline-block tw-w-5"
							/>
							<span
								class="tw-text-sm tw-font-semibold tw-text-gray-700"
							>
								Email
							</span>
							<span
								class="tw-block tw-ml-6 tw-text-xs tw-text-gray-500"
							>
								Get email notifications when new versions are published or the package gets unpublished.
							</span>
						</div>
					</div>

					<div
						v-if="false"
						class="tw-py-0"
						role="none"
					>
						<div
							class="tw-px-4 tw-py-4 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
							@click="showWebhookTab = true"
						>
							<div
								class="tw-py-0"
							>
								<span
									v-if="false"
									class="tw-inline-block"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="tw-h-5 tw-w-5 tw-inline-block"
										:class="{ 'tw-font-bold': true }"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
											clip-rule="evenodd"
										/>
									</svg>
								</span>
								<span
									v-else
									class="tw-inline-block tw-w-5"
								/>

								<div class="tw-inline-block">
									<span
										class="tw-text-sm tw-font-semibold tw-text-gray-700"
									>
										Webhook
									</span>
								</div>
								<div class="tw-inline-block float-right">
									<!-- Arrow right -->
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="tw-w-3 tw-h-3 tw-mt-1"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
										/>
									</svg>
								</div>
							</div>
							<span
								class="tw-block tw-ml-6 tw-text-xs tw-text-gray-500"
							>
								Setup your webhook and we will sent the version data every time a new version is published.
							</span>
						</div>
					</div>
					<div
						v-if="errors.length > 0"
						class="tw-py-0"
						role="none"
					>
						<Alert
							class="tw-mt-3"
							:type="alertType"
							:messages="errors"
						/>
					</div>
				</div>
				<div
					v-else
				>
					<div
						class="tw-divide-y tw-divide-gray-200"
						role="none"
					>
						<div class="tw-px-4 tw-py-2 ">
							<div class="tw-inline-block">
								<span
									class="tw-font-semibold tw-text-sm tw-text-gray-700"
								>
									Webhook
								</span>
							</div>
							<div class="tw-inline-block float-right">
								<!-- Arrow Left (Back) -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="tw-w-5 tw-h-5 hover:tw-text-gray-500 tw-cursor-pointer"
									@click="showWebhookTab = false"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
									/>
								</svg>
							</div>
							<span
								class="tw-block tw-mt-1 tw-text-xs tw-text-gray-500"
							>
								Enter a valid webhook and make sure it can take requests from FORGEBOX.
							</span>
						</div>
						<div class="tw-px-4 tw-py-2">
							<span
								class="tw-text-sm tw-font-semibold tw-text-gray-700"
							>
								Webhook URL
							</span>
							<input
								type="text"
								name="webhookURL"
								placeholder="https://"
								maxlength="255"
								class="tw-my-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>
						<div class="tw-my-3 tw-px-4">
							<div class="tw-space-x-3 tw-flex tw-justify-end tw-my-3">
								<button
									type="button"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
									@click="showWebhookTab = false"
								>
									Cancel
								</button>
								<action-button
									label="Save"
									:loading="false"
									:disabled="false"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		slug : {
			type     : String,
			required : true
		},
		isLoggedIn : {
			type     : Boolean,
			required : true,
			default  : false
		},
		isSubscriber : {
			type     : Boolean,
			required : true,
			default  : false
		}
	},
	data() {
		return {
			showDropdown        : false,
			showWebhookTab      : false,
			subscribedToEmail   : this.isSubscriber,
			subscribedToWebhook : false,
			loading             : false,
			errors              : [],
			alertType           : "success"
		};
	},
	created(){

	},
	directives : { ClickOutside },
	computed   : {
		isSubscribed() {
			return this.subscribedToEmail || this.subscribedToWebhook;
		}
	},
	methods : {
		closeMenu() {
			var app = this;
			app.errors = [];
			app.showDropdown = !app.showDropdown;
			app.alertType = "success";
		},
		toggleEmail() {
			var app = this;
			app.errors = [];
			app.alertType = app.subscribedToEmail ? "warning" : "success";
			var actionText = app.subscribedToEmail ? "unsubscribe from the" : "subscribe to receive";

			swal.fire( {
				title              : "Are you sure?",
				text               : "Do you want to " + actionText + " email notifications for this package?",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.loading = true;
					app.$http.post(
						`/myaccount/packages/${app.slug}/subscribe/email/${!app.subscribedToEmail}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								// Update flag
								app.subscribedToEmail = !app.subscribedToEmail;
								// show confirmation message
								app.errors = [ response.data.data ];
							} else {
								app.loading = false;
								// show error message
								app.alertType = "error";
								app.errors = response.data.messages;
							}
						} )
						.catch( error => {
							app.loading = false;
							// show error message
							app.alertType = "error";
							app.errors = [ "Oops something went wrong. Please try again." ];
						} );
				}
			} );
		}
	}
};
</script>