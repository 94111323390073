<template>
	<div>
		<div class="tw-align-middle tw-min-w-full tw-border-b tw-border-gray-100">
			<div
				v-if="packages.length == 0"
				class="tw-my-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						No packages found
					</p>
				</div>
			</div>
			<div
				class="tw-overflow-x-scroll md:tw-overflow-x-hidden"
				v-else
			>
				<table class="tw-min-w-full md:tw-overflow-x-hidden">
					<thead>
						<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-text-center"
							>
								#
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								Package
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								{{ getDynamicColumnLabel() }}
							</th>
							<th
								class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								View
							</th>
						</tr>
					</thead>
					<tbody
						class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
					>
						<tr
							v-for="(thisPackage, index) in packages"
							:key="thisPackage.entryID"
							class="hover:tw-bg-gray-50"
						>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								{{ index + 1 }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-font-semibold"
							>
								<a
									:href="'/view/' + thisPackage.slug"
									class="tw-text-sm hover:tw-text-gray-600 tw-inline-block"
									role="menuitem"
								>
									{{ thisPackage.title }}
								</a>
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
								v-tooltip="getTooltipValue( thisPackage )"
							>
								{{ getDynamicColumnValue(thisPackage) }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<!-- View button -->
								<a
									:href="'/view/' + thisPackage.slug"
									class="tw-text-sm tw-text-gray-700 hover:tw-text-gray-900 tw-inline-block"
									role="menuitem"
								>
									<!-- Heroicon name: eye -->
									<svg
										class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										aria-hidden="true"
										v-tooltip="'View'"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
										/>
									</svg>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import numeral from "numeral";
import moment from "moment";

export default {
	props : {
		packages : {
			type     : Array,
			required : true
		},
		filter : {
			type     : String,
			required : true,
			default  : "installs"
		}
	},
	methods : {
		getDynamicColumnLabel() {
			switch ( this.filter ) {
			case "installs":
				return "Installs";
			case "rating":
				return "Rating";
			case "new":
				return "Created Date";
			case "updated":
				return "Updated Date";
			default:
				return "Installs";
			}
		},
		getDynamicColumnValue( thisPackage ) {
			switch ( this.filter ) {
			case "installs":
				return this.formatNumber( thisPackage.installs );
			case "rating":
				return this.formatNumber( thisPackage.avgRating );
			case "new":
				return this.formatDate( thisPackage.packageCreatedDate );
			case "updated":
				// We need to add the UTC timezone since this value comes from a sql formula
				return this.formatDate( thisPackage.latestVersionUpdatedDate + "+0000" );
			default:
				return this.formatNumber( thisPackage.installs );
			}
		},
		getTooltipValue( thisPackage ) {
			switch ( this.filter ) {
			case "new":
				return this.formatDate( thisPackage.packageCreatedDate, "long" );
			case "updated":
				// We need to add the UTC timezone since this value comes from a sql formula
				return this.formatDate( thisPackage.latestVersionUpdatedDate + "+0000", "long" );
			default:
				return "";
			}
		},
		formatNumber( number ) {
			if ( number && number != 0 ) {
				return numeral( number ).format( "0,0" );
			} else {
				return number;
			}
		},
		formatDate( date, format = "short" ){
			if ( date == "" ) {
				return "N/A";
			}
			date = new Date( date );
			if ( date ) {
				if ( format == "short" ) {
					return moment( date ).format( "ll" );
				} else {
					return moment( date ).format( "LLL" );
				}
			} else {
				return date;
			}
		}
	}
};
</script>
