<template>
	<div>
		<div class="tw-align-middle tw-min-w-full tw-border-b tw-border-gray-100">
			<div
				v-if="logsCount == 0 && !loading"
				class="tw-bg-gray-100 tw-shadow tw-rounded-lg tw-px-4 tw-py-5 sm:tw-p-6"
			>
				<p class="tw-text-md tw-font-bold tw-text-gray-500">
					No activity log yet
				</p>
				<div>
					<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Right now you don't have any activity recorded in the system.
						</p>
					</div>
				</div>
			</div>
			<div
				v-else-if="loading"
				class="tw-flex tw-my-3"
			>
				<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
					<div class="tw-btn-spinner tw-mt-1" />
					<span class="tw-ml-2">Loading...</span>
				</div>
			</div>
			<div
				class="tw-overflow-x-scroll lg:tw-overflow-x-hidden"
				v-else
			>
				<table class="tw-min-w-full">
					<thead>
						<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-0"
							>
								User
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								Activity
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
							>
								Action
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
							>
								Date
							</th>
						</tr>
					</thead>
					<tbody
						class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
					>
						<tr
							v-for="log in logs"
							:key="log.id"
							class="hover:tw-bg-gray-50"
						>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
							>
								<div v-if="!log.isSystem">
									<Avatar
										:email="'user' in log ? log.user.email : ''"
										:px="40"
										:size="6"
										:tooltip="isOrg ? '@' + log.user.username : 'You'"
									/>
								</div>
								<div v-else>
									<img
										src="/includes/images/ForgeBoxIcon100.png"
										class="tw-rounded-full tw-w-6 tw-h-6"
										v-tooltip.right="'System'"
									/>
								</div>
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
							>
								{{ log.activity }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<span
									class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-capitalize"
									:class="
										`tw-bg-${getBadgeColor(
											log.action
										)}-100 tw-text-${getBadgeColor(log.action)}-800`
									"
								>
									{{ log.action }}
								</span>
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								{{ getFullDate(log.createdDate) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
	props : {
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	data() {
		return {
			logs      : [],
			logsCount : 0,
			loading   : false,
			errors    : []
		};
	},
	mounted() {
		var app = this;
		app.loadActivityLogs();
	},
	methods : {
		loadActivityLogs( page = 1 ) {
			var app = this;
			app.loading = true;
			var url = !app.isOrg
				? "/myaccount/activityLog/list"
				: `/orgs/${app.organization.slug}/activityLog/list`;
			app.$http
				.get( url, { params: { max: 10 } } )
				.then( response => {
					if ( response.data.error ) {
						app.errors.push( response.data.messages );
					} else {
						app.logs = response.data.data.records;
						app.logsCount = response.data.data.count;
						app.pagination = response.data.pagination;
					}
					app.loading = false;
				} )
				.catch( error => {
					app.error.push( error );
					app.loading = false;
				} );
		},
		getBadgeColor( action ) {
			switch ( action ) {
			case "Login":
				return "blue";
			case "Create":
				return "green";
			case "Update":
				return "yellow";
			case "Delete":
				return "red";
			default:
				return "blue";
			}
		},
		debounceSearch : debounce( function() {
			let app = this;
			app.loadActivityLogs();
		}, 300 )
	}
};
</script>
