<template>
	<div
		class="modal fade"
		:id="'modal' + id"
		tabindex="-1"
		role="dialog"
		aria-labelledby="Modal Label"
		aria-hidden="true"
	>
		<div
			class="modal-dialog"
			role="document"
		>
			<div class="modal-content">
				<form>
					<div class="modal-header">
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<h1
							class="modal-title"
							id="exampleModalLabel"
						>
							{{ title }}
						</h1>
					</div>
					<div class="modal-body">
						{{ body }}
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							type="button"
							:class="getClass"
							@click="executeAction"
							:disabled="isLoading"
						>
							<i
								v-show="isLoading"
								class="fa fa-spinner fa-spin"
							/>
							<span v-if="isLoading">Loading...</span>
							<span v-else>{{ buttonlabel }}</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		id : {
			type     : String,
			required : true
		},
		title : {
			type     : String,
			required : false,
			default  : "Are you sure?"
		},
		body : {
			type     : String,
			required : true,
			default  : ""
		},
		buttonlabel : {
			type     : String,
			required : false,
			default  : "Continue"
		},
		// primary or danger
		type : {
			type     : String,
			required : false,
			default  : "primary"
		},
		url : {
			type     : String,
			required : false,
			default  : ""
		},
		method : {
			type     : String,
			required : false,
			default  : "get"
		},
		redirect : {
			type     : String,
			required : false,
			default  : ""
		}
	},
	data() {
		return { isLoading: false };
	},
	mounted(){
	},
	computed : {
		getClass() {
			switch ( this.type ) {
			case "primary":
				return "btn btn-primary";
				break;
			case "danger":
				return "btn btn-danger";
				break;
			default:
				return "primary";
				break;
			}
		}
	},
	methods : {
		executeAction() {
			var app = this;
			this.isLoading = true;
			if ( app.url != "" ) {
				$.ajax( {
					url    : app.url,
					method : app.method
				} )
					.then( function( result ) {
						if ( app.redirect !== "" ) {
							location.reload();
						} else {
							app.isLoading = false;
							$( "#modal" + app.id ).modal( "toggle" );
						}
					} )
					.catch( function( err ) {
						app.isLoading = false;
						alert( "An error has occurred while executing the action" );
					} );
			} else {
				$( "#modal" + app.id ).modal( "toggle" );
			}
		}
	},
	filters : {
		capitalize : function( value ) {
			if ( !value ) return "";
			value = value.toString();
			return value.charAt( 0 ).toUpperCase() + value.slice( 1 );
		}
	}
};
</script>