// Import Shared Components
import Swal from "sweetalert2/src/sweetalert2.js";
import Alert from "./Alert";
import ActionButton from "./ActionButton";
import Avatar from "./Avatar";
import CollaboratorsForm from "./CollaboratorsForm";
import AuditLog from "./SubscriptionAuditLog";
import FullScreenButton from "./FullScreenButton";
import Pagination from "./Pagination";
import SearchBar from "./SearchBar";
import NavigationLinks from "./NavigationLinks.vue";
import TableLoader from "./TableLoader";
import Badges from "./Badges";
import Divider from "./Divider";
import Breadcrumb from "./Breadcrumb";
import PageHeader from "./PageHeader";
import UserNavBar from "./UserNavBar";
import SubscribeButton from "./SubscribeButton";
import SimpleMDE from "./SimpleMDE";
import Dialog from "./Dialog";
import PasswordMeter from "./PasswordMeter";
import FlashMessages from "./FlashMessages";
import LiquorTree from "liquor-tree";
// Packages, collaborations and activity log
import ActivityLog from "./ActivityLog/Index.vue";

// Export the default as a Vue Plugin
export default {
	install( Vue ) {
		Vue.component( "swal", Swal );
		Vue.component( "Alert", Alert );
		Vue.component( "ActionButton", ActionButton );
		Vue.component( "Avatar", Avatar );
		Vue.component( "CollaboratorsForm", CollaboratorsForm );
		Vue.component( "AuditLog", AuditLog );
		Vue.component( "FullScreenButton", FullScreenButton );
		Vue.component( "Pagination", Pagination );
		Vue.component( "SearchBar", SearchBar );
		Vue.component( "NavigationLinks", NavigationLinks );
		Vue.component( "TableLoader", TableLoader );
		Vue.component( "Badges", Badges );
		Vue.component( "Divider", Divider );
		Vue.component( "Breadcrumb", Breadcrumb );
		Vue.component( "PageHeader", PageHeader );
		Vue.component( "Simplemde", SimpleMDE );
		Vue.component( "DialogModal", Dialog );
		Vue.component( "FlashMessages", FlashMessages );
		Vue.component( "UserNavBar", UserNavBar );
		Vue.component( "SubscribeButton", SubscribeButton );
		Vue.component( "PasswordMeter", PasswordMeter );
		Vue.component( "LiquorTree", LiquorTree );
		// Activity Log
		Vue.component( "ActivityLog", ActivityLog );
	}
};

// Expose Shared Components
export {
	Swal,
	Alert,
	ActionButton,
	Avatar,
	CollaboratorsForm,
	AuditLog,
	FullScreenButton,
	Pagination,
	SearchBar,
	NavigationLinks,
	TableLoader,
	Badges,
	Divider,
	Breadcrumb,
	PageHeader,
	SimpleMDE,
	Dialog,
	FlashMessages,
	UserNavBar,
	SubscribeButton,
	PasswordMeter,
	LiquorTree,
	// Activity log
	ActivityLog,
};