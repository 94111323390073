<template>
	<div>
		<form
			enctype="multipart/form-data"
			@submit.prevent="saveVersion"
		>
			<div v-if="!isEmbedded">
				<page-header
					:menu="menu"
					:primary-button="{ label: 'Save', type: 'submit' }"
					:secondary-button="{ label: 'Cancel', type: 'button', url: !isOrg ? `/myaccount/packages/manage/${thisPackage.slug}` : `/orgs/${organization.slug}/packages/manage/${thisPackage.slug}` }"
				/>
			</div>
			<div
				v-else
				class="tw-flex tw-justify-between tw-border-b tw-border-gray-200 tw-py-3 tw-px-3 tw-h-16 tw-bg-white"
			>
				<div>
					<p class="tw-text-base tw-text-gray-500 tw-mt-2 tw-truncate">
						<strong>{{ thisPackage.title }}</strong>
					</p>
				</div>
				<div>
					<button
						type="button"
						class="tw-mr-3 tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
						@click="$parent.createFirstVersion = false"
					>
						Cancel
					</button>
					<button
						type="submit"
						class="tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
					>
						Save
					</button>
				</div>
			</div>
			<flash-messages
				v-if="notification.show"
				:type="notification.type"
				:title="notification.title"
				:message="notification.message"
			/>
			<div class="lg:tw-border-t lg:tw-border-b lg:tw-border-gray-200">
				<Alert
					type="error"
					:messages="errors"
				/>
				<nav
					class="tw-mx-auto"
					aria-label="Progress"
				>
					<ol class="tw-rounded-md tw-overflow-hidden lg:tw-flex lg:tw-border-l lg:tw-border-r lg:tw-border-gray-200 lg:tw-rounded-none">
						<li class="tw-relative tw-overflow-hidden lg:tw-flex-1">
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden tw-border-b-0 tw-rounded-t-md lg:tw-border-0">
								<!-- Details Step -->
								<div
									class="tw-group tw-cursor-pointer"
									@click="changeTab('1')"
								>
									<span
										:class="activeTab == 'step1' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium">
										<span class="tw-flex-shrink-0">
											<span
												:class="{ 'tw-bg-blue-600' : step1Completed, 'tw-text-blue-600 tw-border-blue-600' : activeTab =='step1', 'tw-border-gray-300' : activeTab !='step1' }"
												class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2"
											>
												<span
													:class="activeTab == 'step1' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												>
													01
												</span>
												<span v-if="step1Completed">
													<!-- Heroicon name: solid/check -->
													<svg
														class="tw-w-6 tw-h-6 tw-text-white"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
														fill="currentColor"
														aria-hidden="true"
													>
														<path
															fill-rule="evenodd"
															d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</span>
										</span>
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'step1' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-text-xs tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Version Details
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">Description, install instructions, changelog</span>
										</span>
									</span>
								</div>
							</div>
						</li>

						<li class="tw-relative tw-overflow-hidden lg:tw-flex-1">
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden lg:tw-border-0">
								<!-- box.json Step -->
								<div
									class="tw-group tw-cursor-pointer"
									@click="changeTab('2')"
								>
									<span
										:class="activeTab == 'step2' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium lg:tw-pl-9">
										<span class="tw-flex-shrink-0">
											<span
												:class="activeTab == 'step2' ? 'tw-border-blue-600' : 'tw-border-gray-300 '"
												class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-border-2 tw-rounded-full"
											>
												<span :class="activeTab == 'step2' ? 'tw-text-blue-600' : 'tw-text-gray-500'">02</span>
											</span>
										</span>
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'step2' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-text-xs tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Box.json
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">A valid json is required</span>
										</span>
									</span>
								</div>

								<div
									class="tw-hidden tw-absolute tw-top-0 tw-left-0 tw-w-3 tw-inset-0 lg:tw-block"
									aria-hidden="true"
								>
									<svg
										class="tw-h-full tw-w-full tw-text-gray-300"
										viewBox="0 0 12 82"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0.5 0V31L10.5 41L0.5 51V82"
											stroke="currentcolor"
											vector-effect="non-scaling-stroke"
										/>
									</svg>
								</div>
							</div>
						</li>

						<li class="tw-relative tw-overflow-hidden lg:tw-flex-1">
							<div class="tw-border tw-border-gray-200 tw-overflow-hidden tw-border-t-0 tw-rounded-b-md lg:tw-border-0">
								<!-- Package zip Step -->
								<div
									class="tw-group tw-cursor-pointer"
									@click="changeTab('3')"
								>
									<span
										:class="activeTab == 'step3' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
										class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
										aria-hidden="true"
									/>
									<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium lg:tw-pl-9">
										<span class="tw-flex-shrink-0">
											<span
												:class="activeTab == 'step3' ? 'tw-border-blue-600' : 'tw-border-gray-300 '"
												class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-border-2 tw-rounded-full"
											>
												<span :class="activeTab == 'step3' ? 'tw-text-blue-600' : 'tw-text-gray-500'">03</span>
											</span>
										</span>
										<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
											<span
												:class="activeTab == 'step3' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
												class="tw-text-xs tw-font-semibold tw-tracking-wide tw-uppercase"
											>
												Package zip
											</span>
											<span class="tw-text-sm tw-font-medium tw-text-gray-500">Upload your package version in zip format</span>
										</span>
									</span>
								</div>

								<div
									class="tw-hidden tw-absolute tw-top-0 tw-left-0 tw-w-3 tw-inset-0 lg:tw-block"
									aria-hidden="true"
								>
									<svg
										class="tw-h-full tw-w-full tw-text-gray-300"
										viewBox="0 0 12 82"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0.5 0V31L10.5 41L0.5 51V82"
											stroke="currentcolor"
											vector-effect="non-scaling-stroke"
										/>
									</svg>
								</div>
							</div>
						</li>
					</ol>
				</nav>
			</div>
			<div class="tw-border tw-border-gray-200 tw-p-3">
				<div
					id="versionDetails"
					v-if="activeTab == 'step1'"
				>
					<div>
						<div class="tw-flex tw-justify-between">
							<label
								for="description"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Description
							</label>
							<select
								v-model="form.descriptionFormat"
								id="descriptionFormat"
								name="descriptionFormat"
								class="tw-block sm:tw-text-sm tw-border-none"
							>
								<option value="md">
									Markdown
								</option>
								<option value="html">
									HTML
								</option>
								<option value="text">
									Plain Text
								</option>
							</select>
						</div>
						<simplemde
							v-model="form.description"
							id="description"
							name="description"
							rows="8"
							placeholder="Enter description here"
							class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						/>
					</div>
					<div class="tw-mt-3">
						<div class="tw-flex tw-justify-between">
							<label
								for="installInstructions"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Install Instructions
							</label>
							<select
								v-model="form.installInstructionsFormat"
								id="installInstructionsFormat"
								name="installInstructionsFormat"
								class="tw-block sm:tw-text-sm tw-border-none"
							>
								<option value="md">
									Markdown
								</option>
								<option value="html">
									HTML
								</option>
								<option value="text">
									Plain Text
								</option>
							</select>
						</div>
						<simplemde
							v-model="form.installInstructions"
							id="installInstructions"
							name="installInstructions"
							rows="8"
							placeholder="Enter install instructions here"
							class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						/>
					</div>
					<div class="tw-mt-3">
						<div class="tw-flex tw-justify-between">
							<label
								for="changeLog"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Changelog
							</label>
							<select
								v-model="form.changelogFormat"
								id="changelogFormat"
								name="changelogFormat"
								class="tw-block sm:tw-text-sm tw-border-none"
							>
								<option value="md">
									Markdown
								</option>
								<option value="html">
									HTML
								</option>
								<option value="text">
									Plain Text
								</option>
							</select>
						</div>
						<simplemde
							v-model="form.changelog"
							id="changeLog"
							name="changeLog"
							rows="8"
							placeholder="Enter changelog here"
							class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						/>
					</div>
				</div>
				<div
					v-else-if="activeTab == 'step2'"
					id="boxjson"
				>
					<v-jsoneditor
						v-model="form.boxjson"
						:options="options"
						:plus="true"
						mode="code"
						height="600px"
						@input="onChange"
						@error="onError"
					/>
				</div>
				<div
					v-else-if="activeTab == 'step3'"
				>
					<div
						v-if="packageVersion.downloadURL && isValidURL(packageVersion.downloadURL)"
						class="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2"
					>
						<div
							class="tw-px-6 tw-pt-5 tw-pb-6 tw-bg-gray-100 tw-border-2 tw-border-gray-300 tw-rounded-md"
						>
							<div class="tw-space-y-1 tw-text-center">
								<a :href="'/download/' + thisPackage.entryID + '/' + packageVersion.version">
									<svg
										class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
										stroke="currentColor"
										fill="none"
										viewBox="0 0 24 24"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
										/>
									</svg>
									<div
										class="tw-flex tw-text-sm tw-text-gray-600"
									>
										<label
											for="assetsFieldHandle"
											class="tw-mx-auto tw-cursor-pointer tw-mt-1 tw-rounded-md tw-font-medium tw-text-blue-600 hover:tw-text-blue-500 focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-offset-2 focus-within:tw-ring-blue-500"
										>
											<span>Download Package</span>
										</label>
									</div>
								</a>
							</div>
							<div>
								<label
									for="assetsFieldHandle"
									class="tw-block tw-text-center tw-cursor-pointer tw-mt-1 tw-rounded-md tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
								>
									<span>Replace file?</span>
									<input
										@change="form.zip = $event.target.files[0]"
										id="assetsFieldHandle"
										type="file"
										name="zip"
										ref="file"
										accept=".zip"
										class="tw-sr-only"
									>
								</label>
							</div>
						</div>
					</div>
					<div v-else>
						<div
							v-if="form.zip == ''"
							id="fileUploadContainter"
							class="tw-flex tw-justify-center tw-px-6 tw-pt-5 tw-pb-6 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-md"
						>
							<div class="tw-space-y-1 tw-text-center">
								<svg
									class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<path
										d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<div
									class="tw-flex tw-text-sm tw-text-gray-600"
									@dragover="dragover"
									@dragleave="dragleave"
									@drop="drop"
								>
									<label
										for="assetsFieldHandle"
										class="tw-relative tw-cursor-pointer tw-mt-1 tw-rounded-md tw-font-medium tw-text-blue-600 hover:tw-text-blue-500 focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-offset-2 focus-within:tw-ring-blue-500"
									>
										<span>Upload a file</span>
										<input
											@change="form.zip = $event.target.files[0]"
											id="assetsFieldHandle"
											type="file"
											name="zip"
											ref="file"
											accept=".zip"
											class="tw-sr-only"
										>
									</label>
									<p class="tw-pl-1">
										or drag and drop
									</p>
								</div>
								<p class="tw-text-xs tw-text-gray-500">
									Only ZIP files are allowed
								</p>
							</div>
						</div>
						<div
							v-else
							class="tw-bg-green-100"
						>
							<div class="tw-text-center tw-text-gray-500 tw-font-italic tw-p-6">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
									class="tw-mr-3 tw-h-10 tw-text-gray-400 tw-w-full"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>

								The Zip file is attached and ready to be uploaded

								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500 tw-inline-block tw-cursor-pointer"
									v-tooltip="'Remove File'"
									@click="form.zip = ''"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

export default {
	props : {
		editMode : {
			type     : Boolean,
			required : false,
			default  : false
		},
		thisPackage : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isEmbedded : {
			type     : Boolean,
			required : false,
			default  : false
		},
		packageVersion : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : true,
			default  : false
		}
	},
	components : {},
	data() {
		return {
			activeTab      : "step1",
			step1Completed : false,
			step2Completed : false,
			step3Completed : false,
			loading        : false,
			errors         : [],
			options        : { mode: "code" },
			notification   : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : true
			},
			form : {
				versionID                 : "",
				description               : "",
				descriptionFormat         : "md",
				installInstructions       : "",
				installInstructionsFormat : "md",
				changelog                 : "",
				changelogFormat           : "md",
				boxjson                   : {},
				zip                       : ""
			}
		};
	},
	computed : {
		menu() {
			return [
				{
					label : "Packages",
					url   : !this.isOrg ? "/myaccount/packages" : `/orgs/${this.organization.slug}/packages`
				},
				{ label: this.thisPackage.title, url: !this.isOrg ? `/myaccount/packages/manage/${this.thisPackage.slug }` : `/orgs/${this.organization.slug}/packages/manage/${this.thisPackage.slug}` },
				{ label: !this.editMode ? "Create Version" : "Edit Version" }
			];
		}
	},
	mounted(){
		if ( this.editMode ){
			this.populateItem();
		}
	},
	methods : {
		populateItem() {
			let app = this;
			app.form.versionID = app.packageVersion.entryVersionID;
			app.form.description = app.packageVersion.description;
			app.form.descriptionFormat = app.packageVersion.descriptionFormat;
			app.form.installInstructions = app.packageVersion.installInstructions;
			app.form.installInstructionsFormat = app.packageVersion.installInstructionsFormat;
			app.form.changelog = app.packageVersion.changelog;
			app.form.changelogFormat = app.packageVersion.changelogFormat;
			app.form.boxjson = JSON.parse( app.packageVersion.boxJSON );
		},
		changeTab( step ) {
			this.activeTab = "step" + step;
		},
		saveVersion() {
			var app = this;
			app.loading = true;
			app.notification.show = false;
			app.errors = [];
			const data = new FormData();
			data.append( "versionID", app.form.versionID );
			data.append( "packageSlug", app.thisPackage.slug );
			data.append( "description", app.form.description );
			data.append( "descriptionFormat", app.form.descriptionFormat );
			data.append( "installInstructions", app.form.installInstructions );
			data.append( "installInstructionsFormat", app.form.installInstructionsFormat );
			data.append( "changelog", app.form.changelog );
			data.append( "changelogFormat", app.form.changelogFormat );
			data.append( "boxjson", JSON.stringify( app.form.boxjson ) );

			if ( app.form.zip != "" ) {
				data.append( "zip", app.form.zip );
			}
			if ( app.editMode ) {
				data.append( "editMode", true );
			}
			var url = !app.isOrg ? "/myaccount/packages/saveVersion" : `/orgs/${app.organization.slug}/packages/saveVersion`;
			app.$http.post( url, data )
				.then( function( response ) {
					// handle success
					if ( response.data.error ) {
						app.errors = response.data.messages;
						app.loading = false;
					} else {
						app.loading = false;
						app.notification = {
							type    : "success",
							title   : "Success",
							message : response.data.data,
							show    : true
						};
						if ( !app.editMode ) {
							var relocationURL = !app.isOrg ? `/myaccount/packages/manage/${app.thisPackage.slug}` : `/orgs/${app.organization.slug}/packages/manage/${app.thisPackage.slug}`;
							window.location = relocationURL;
						}
					}
				} )
				.catch( function( error ) {
					// handle error
					app.loading = false;
					app.errors = [ error.toString() ];
					app.notification = {
						type    : "error",
						title   : "Error",
						message : "Oops something went wrong. Please try again",
						show    : true
					};
				} );
		},
		onInputFileChange( event ) {
			this.form.zip = this.$refs.file.files[0];
			$( "#fileUploadContainter" ).addClass( "tw-bg-gray-300" );
			$( "#fileUploadContainter" ).removeClass( "tw-bg-white" );
		},
		removeFile() {
			this.form.zip = "";
			$( "#fileUploadContainter" ).addClass( "tw-bg-white" );
			$( "#fileUploadContainter" ).removeClass( "tw-bg-gray-300" );
		},
		dragover( event ) {
			event.preventDefault();
			// Add some visual fluff to show the user can drop its files
			if ( !event.currentTarget.classList.contains( "tw-bg-blue-300" ) ) {
				event.currentTarget.classList.remove( "tw-bg-gray-300" );
				event.currentTarget.classList.remove( "tw-bg-white" );
				event.currentTarget.classList.add( "tw-bg-blue-300" );
			}
		},
		dragleave( event ) {
			// Clean up
			event.currentTarget.classList.add( "tw-bg-white" );
			event.currentTarget.classList.remove( "tw-bg-blue-300" );
		},
		drop( event ) {
			event.preventDefault();
			this.$refs.file.files = event.dataTransfer.files;
			this.onInputFileChange(); // Trigger the onInputFileChange event manually
			// Clean up
			event.currentTarget.classList.add( "tw-bg-gray-300" );
			event.currentTarget.classList.remove( "tw-bg-blue-300" );
		},
		onChange() {
			this.errors = [];
		},
		onError() {
			this.errors = [ "The box.json is invalid. Check the format and make sure you enter a valid JSON object." ];
		}
	}
};
</script>