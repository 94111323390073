<template>
	<div>
		<page-header :menu="menu" />
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div
				v-if="logsCount == 0 && !loading && false"
				class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
						No activity log entries yet
					</h3>
					<div>
						<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
							<p class="tw-text-sm">
								There are no activity log entries associated to your account at
								this moment.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<label
							for="OrganizationsSearch"
							class="tw-sr-only"
						>
							Search
						</label>
						<div class="tw-relative tw-rounded-md tw-shadow-sm">
							<div
								class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none"
							>
								<!-- Heroicon name: solid/search -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<input
								v-model="search"
								name="OrganizationsSearch"
								id="OrganizationsSearch"
								type="search"
								class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								@input="debounceSearch"
								placeholder="Search"
							/>
							<!-- Heroicon name: x -->
							<div
								v-if="search != ''"
								class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
							>
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									@click="clearSearch"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<div class="tw-flex tw-gap-3">
							<select
								v-model="filter"
								id="type"
								name="type"
								class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								@change="loadActivityLogs()"
							>
								<option value="all">
									All Actions
								</option>
								<option
									v-if="!isOrg"
									value="login"
								>
									Login
								</option>
								<option value="create">
									Create
								</option>
								<option value="update">
									Update
								</option>
								<option value="delete">
									Delete
								</option>
							</select>
							<button
								type="button"
								class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
								@click="resetFilters"
								v-tooltip="'Reset Filters'"
							>
								<!-- Heroicon name: solid/x -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="tw-sr-only">Reset Filters</span>
							</button>
						</div>
					</div>
				</div>

				<div v-if="logs.length != 0 && !loading">
					<div class="tw-flex tw-mt-3">
						<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
							Showing activity log for the last
							<span class="tw-font-bold">{{ activityLogDays }} days</span> for
							<span class="tw-font-bold">{{ filter }}</span> actions.
						</div>
						<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
							Page: {{ pagination.page }}
						</div>
					</div>

					<p
						v-if="!isOrg && activityLogDays < 8"
						class="tw-italic tw-text-xs tw-text-gray-500 tw-mt-1"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke="currentColor"
							fill="none"
							aria-hidden="true"
							class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						You can get up to 180 days of activity log by subscribing to any of
						our
						<a
							href="/plans"
							class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium"
							rel="noopener"
							target="_blank"
						>plans.
						</a>
					</p>
				</div>

				<div
					v-else-if="loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
							<div class="tw-btn-spinner tw-mt-1" />
							<span class="tw-ml-2">Loading...</span>
						</div>
					</div>
				</div>

				<div class="tw-bg-white">
					<!-- activity log table (small breakpoint and up) -->
					<div class="tw-mt-3">
						<div
							class="tw-overflow-x-scroll lg:tw-overflow-x-hidden tw-w-full tw-border-b tw-border-gray-100 tw-shadow"
						>
							<table
								class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100 tw-overflow-hidden"
							>
								<thead>
									<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
										<th
											class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-0"
										>
											User
										</th>
										<th
											class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
										>
											Activity
										</th>
										<th
											class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
										>
											Action
										</th>
										<th
											class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
										>
											Date
										</th>
									</tr>
								</thead>
								<tbody
									class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
								>
									<tr
										v-if="logs.length == 0 && !loading"
										class="tw-bg-gray-50"
									>
										<td
											colspan="8"
											class="tw-pb-6 tw-text-center"
										>
											<svg
												class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
											<p
												class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate"
											>
												No activity log entries were found
											</p>
										</td>
									</tr>
									<tr
										v-for="log in logs"
										is="ActivityLogRow"
										:key="log.id"
										:log="log"
										:user="user"
										:is-org="isOrg"
										class="hover:tw-bg-gray-50"
									/>
								</tbody>
							</table>
						</div>
					</div>

					<Pagination
						:total-pages="pagination.totalPages"
						:total-records="pagination.records"
						:max-rows="pagination.maxRow"
						:current-page="pagination.page"
						:loader="loadActivityLogs"
						:loading="loading"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ActivityLogRow from "./ActivityLogRow.vue";
import debounce from "lodash/debounce";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		activityLogDays : {
			type     : Number,
			required : false,
			default  : 7
		}
	},
	components : { ActivityLogRow },
	data() {
		return {
			menu       : [ { label: "Activity Log" } ],
			logs       : [],
			logsCount  : 0,
			search     : "",
			loading    : false,
			errors     : [],
			filter     : "all",
			pagination : {
				startRow   : 0,
				maxRow     : 0,
				records    : 0,
				page       : 1,
				totalPages : 1
			}
		};
	},
	mounted() {
		var app = this;
		app.loadActivityLogs();
	},
	methods : {
		loadActivityLogs( page = 1 ) {
			var app = this;
			app.loading = true;
			var url = !app.isOrg
				? "/myaccount/activityLog/list"
				: `/orgs/${app.organization.slug}/activityLog/list`;
			app.$http
				.get( url, { params: { filter: this.filter, search: app.search, page: page } } )
				.then( response => {
					if ( response.data.error ) {
						app.errors.push( response.data.messages );
					} else {
						app.logs = response.data.data.records;
						app.logsCount = response.data.data.count;
						app.pagination = response.data.pagination;
					}
					app.loading = false;
				} )
				.catch( error => {
					app.error.push( error );
					app.loading = false;
				} );
		},
		debounceSearch : debounce( function() {
			let app = this;
			app.loadActivityLogs();
		}, 300 ),
		filterResults( filter ) {
			let app = this;
			app.filter = filter;
			app.loadActivityLogs();
		},
		clearSearch() {
			let app = this;
			app.search = "";
			app.loadActivityLogs();
		},
		resetFilters() {
			let app = this;
			app.search = "";
			app.filter = "all";
			app.loadActivityLogs();
		},
		submit() {
			console.log( "save" );
		}
	}
};
</script>
