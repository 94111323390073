<template>
	<span>
		<img
			:src="avatarLink"
			class="tw-rounded-full"
			:class="dynamicClass"
			v-tooltip.right="tooltip"
		>
	</span>
</template>
<script>
import gravatar from "gravatar";

export default {
	props : {
		shadow : {
			type    : Boolean,
			default : true
		},
		customClass : {
			type    : String,
			default : ""
		},
		email : {
			type     : String,
			required : true,
			default  : ""
		},
		px : {
			type    : Number,
			default : 100
		},
		size : {
			type    : Number,
			default : 8
		},
		rating : {
			type    : String,
			default : "pg"
		},
		rounded : {
			type    : Boolean,
			default : true
		},
		tooltip : {
			type    : String,
			default : ""
		}
	},
	computed : {
		dynamicClass(){
			let target = "";

			if ( this.shadow ){
				target += "tw-shadow ";
			}
			if ( this.rounded ){
				target += "tw-rounded-full ";
			}
			if ( this.customClass ){
				target += this.customClass + " ";
			}
			if ( this.size ){
				target += `tw-w-${this.size} tw-h-${this.size}`;
			}

			return target;
		},
		avatarLink(){
			return gravatar.url(
				this.email,
				{
					s : this.px,
					r : this.rating
				}
			);
		}
	}
};
</script>