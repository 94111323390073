<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<change-username
			:show-slideover="showChangeUsername"
			@interface="showChangeUsername = $event"
		/>
		<change-email
			:user="user"
			:show-slideover="showChangeEmail"
			@interface="showChangeEmail = $event"
		/>
		<change-password
			:show-slideover="showChangePassword"
			@interface="showChangePassword = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div>
			<Alert
				v-if="token != ''"
				type="warning"
				:messages="['An API token has been created for you. This token will only be shown once. Make sure you store it in a safe place.']"
				:dismiss="false"
			>
				<textarea
					class="tw-border tw-border-gray-300 tw-rounded-md tw-text-sm tw-w-full tw-resize-none tw-bg-gray-50 tw-mt-3"
					:value="token"
					disabled
				/>
			</Alert>
			<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
				<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Username
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							Your account username can be changed any time. Keep in mind that this affects your current packages.
						</p>
					</div>
					<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
						<div class="tw-grid tw-grid-cols-6 tw-gap-6">
							<div class="tw-col-span-6">
								<label
									for="username"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mt-4"
								>
									Username
								</label>
								<div>
									<div class="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
										<span class="tw-inline-flex tw-items-center tw-pl-3 tw-pr-1 tw-rounded-l-md tw-border tw-border-r-0 tw-border-gray-300 tw-bg-gray-50 tw-text-gray-500 sm:tw-text-sm">
											https://www.forgebox.io/@
										</span>
										<input
											v-model="user.username"
											type="text"
											name="username"
											id="username"
											class="tw-flex-1 tw-min-w-0 tw-block tw-w-full tw-pl-1 tw-py-2 tw-rounded-none tw-rounded-r-md focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-opacity-75"
											disabled
										>
										<button
											type="button"
											class="tw--ml-px tw-relative tw-inline-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-font-medium tw-rounded-r-md tw-text-gray-700 tw-bg-gray-50 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
											@click="showChangeUsername = !showChangeUsername"
										>
											<span>Change Username</span>
										</button>
									</div>
								</div>
							</div>
							<div
								class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-red-500"
							>
								<svg
									class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-red-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
									/>

								</svg>
								<span class="tw-text-red-500">
									Note that no redirects are provided when changing your username.
									Changing your username will break links to your profile, any private packages, and any FORGEBOX hosted packages.
									You will need to publish new versions of your private packages with updated slugs.
								</span>
							</div>
						</div>
					</div>
				</div>

				<!-- Divider -->
				<divider />

				<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Email Address
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							The email address is used for sending user notifications and also for avatar detection purposes.
						</p>
					</div>
					<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
						<div class="tw-grid tw-grid-cols-6 tw-gap-6">
							<div class="tw-col-span-6">
								<label
									for="email_address"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
								>
									Email Address
								</label>
								<div>
									<div class="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
										<input
											v-model="user.email"
											type="text"
											name="email"
											id="email"
											class="tw-flex-1 tw-min-w-0 tw-block tw-w-full tw-px-3 tw-py-2 tw-rounded-none tw-rounded-r-md focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-opacity-75"
											disabled
										>
										<button
											type="button"
											class="tw--ml-px tw-relative tw-inline-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-font-medium tw-rounded-r-md tw-text-gray-700 tw-bg-gray-50 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
											@click="showChangeEmail = true"
										>
											<span>Change Email</span>
										</button>
									</div>
								</div>
							</div>
							<div
								class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500"
							>
								<svg
									class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								<span>
									You will need to confirm your email after requesting an email change. We also use your email to get your avatar if no avatar is uploaded in FORGEBOX
								</span>
							</div>
						</div>
					</div>
				</div>

				<!-- Divider -->
				<divider />

				<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6 tw-mt-10">
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Security & Preferences
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							You can control your account settings and security in this section.
						</p>
					</div>
					<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2">
						<div class="tw-grid tw-grid-cols-6 tw-gap-6 tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
							<div class="tw-col-span-6 tw-border-b tw-pb-5">
								<label
									for="changePassword"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
								>
									Password
								</label>
								<div class="tw-flex tw-items-center tw-justify-between">
									<div class="tw-inline-flex tw-text-sm tw-text-gray-500">
										<span>Changing your password frequently is the best way to keep your account safe</span>
									</div>
									<div class="tw-inline-flex tw-flex-shrink-0 tw-justify-end">
										<button
											type="button"
											class="tw-px-4 tw-py-1 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-red-600 hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500"
											@click="showChangePassword = !showChangePassword"
										>
											Change Password
										</button>
									</div>
								</div>
							</div>
							<div class="tw-col-span-6 tw-border-b tw-pb-5">
								<label
									for="twoFactorAuthentication"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
								>
									Two-Factor Authentication
									<span class="tw-italic">(Coming Soon)</span>
								</label>
								<div class="tw-flex tw-items-center tw-justify-between">
									<div class="tw-inline-flex tw-text-sm tw-text-gray-500">
										<span>Increase your account's security by enabling Two-Factor Authentication (2FA)</span>
									</div>
									<div class="tw-inline-flex tw-flex-shrink-0 tw-justify-end">
										<!-- TODO: Add this later -->
										<!-- :class="{ 'tw-bg-blue-500 focus:tw-ring-blue-500': twoFactorAuthentication, 'tw-bg-gray-200 focus:tw-ring-gray-300': !(twoFactorAuthentication) }" cursor pointer
											@click="twoFactorAuthentication = !twoFactorAuthentication" -->
										<button
											type="button"
											class="tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 tw-ml-auto tw-bg-gray-200 tw-cursor-not-allowed"
											disabled="disabled"
										>
											<span class="tw-sr-only">Two-Factor Authentication</span>
											<!-- TODO: Add this later -->
											<!-- :class="{ 'tw-translate-x-5': twoFactorAuthentication, 'tw-translate-x-0': !(twoFactorAuthentication) }" -->

											<span
												aria-hidden="true"
												class="tw-inline-block tw-translate-x-0 tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200 tw-opacity-75"
											/>
										</button>
									</div>
								</div>
							</div>
							<div class="tw-col-span-6">
								<label
									for="newsSubscription"
									class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
								>
									News Subscription
								</label>
								<div class="tw-flex tw-items-center tw-justify-between">
									<div class="tw-inline-flex tw-text-sm tw-text-gray-500">
										<span>Subscribe to FORGEBOX news using your email&#32;
											<span class="tw-text-red-500">
												{{ user.email }}
											</span>
										</span>
									</div>
									<div class="tw-inline-flex tw-flex-shrink-0 tw-justify-end">
										<button
											type="submit"
											class="tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 tw-ml-auto"
											:class="{ 'tw-bg-blue-500 focus:tw-ring-blue-500': newsSubscription, 'tw-bg-gray-200 focus:tw-ring-gray-300': !(newsSubscription) }"
											@click="toggleNewsSubscription"
										>
											<span class="tw-sr-only">News Subscription</span>
											<span
												aria-hidden="true"
												class="tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200"
												:class="{ 'tw-translate-x-5': newsSubscription, 'tw-translate-x-0': !(newsSubscription) }"
											/>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ChangeUsername from "./ChangeUsername";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		token : {
			type     : String,
			required : false,
			default  : ""
		}
	},
	components : { ChangeUsername, ChangeEmail, ChangePassword },
	data() {
		return {
			menu                    : [ { label: "Account" } ],
			showChangeUsername      : false,
			showChangeEmail         : false,
			showChangePassword      : false,
			twoFactorAuthentication : false,
			newsSubscription        : this.user.addToMailingList,
			loading                 : false,
			notification            : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	methods : {
		toggleNewsSubscription() {
			let app = this;
			app.notification.show = false;
			app.loading = true;
			app.$http.post(
				"/myaccount/settings/account/addToMailingList/"+!app.newsSubscription
			)
				.then( ( response ) => {
					app.loading = false;
					app.newsSubscription = !app.newsSubscription;
					app.user.addToMailingList = !app.newsSubscription;
					app.notification = {
						type    : "success",
						title   : "Success",
						message : "Your changes have been saved!",
						show    : true
					};
				} )
				.catch( error => {
					app.notification = {
						type    : "error",
						title   : "Error",
						message : error,
						show    : true
					};
					app.loading = false;
				} );
		}
	}
};
</script>