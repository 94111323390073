<template>
	<div>
		<page-header :menu="menu" />
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div>
				<dl class="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-28 tw-cursor-pointer"
						:class="
							mainFilter == 'inbox'
								? 'tw-border-2 tw-border-blue-600 tw-bg-blue-200'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('inbox')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<svg
										class="tw-h-20 tw-w-20"
										:class="
											mainFilter == 'inbox'
												? 'tw-text-blue-600'
												: 'tw-text-gray-400'
										"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
										/>
									</svg>
								</div>
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-md tw-truncate tw-mt-1"
											:class="
												mainFilter == 'inbox'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500'
											"
										>
											Inbox
										</dt>
										<dd class="tw-mt-0">
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Total Collaborations: {{ collaborationsCount.inbox }}
											</div>
										</dd>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Not Confirmed:
												{{ collaborationsCount.inboxNotConfirmed }}
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>

					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-28 tw-cursor-pointer"
						:class="
							mainFilter == 'outbox'
								? 'tw-border-2 tw-border-blue-600 tw-bg-blue-200'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('outbox')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<svg
										class="tw-h-20 tw-w-20"
										:class="
											mainFilter == 'outbox'
												? 'tw-text-blue-600'
												: 'tw-text-gray-400'
										"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
										/>
									</svg>
								</div>
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-md tw-truncate tw-mt-1"
											:class="
												mainFilter == 'outbox'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500'
											"
										>
											Outbox
										</dt>
										<dd class="tw-mt-0">
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Total Collaborations: {{ collaborationsCount.outbox }}
											</div>
										</dd>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Not Confirmed:
												{{ collaborationsCount.outboxNotConfirmed }}
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</dl>
			</div>

			<div
				v-if="
					(mainFilter == 'inbox' && collaborationsCount.inbox == 0) ||
						(mainFilter == 'outbox' && collaborationsCount.outbox == 0)
				"
				class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
						No collaborations yet
					</h3>
					<div>
						<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
							<p class="tw-text-sm">
								Right now you don't have any collaboration in your
								{{ mainFilter }}.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div
				v-else
				class="tw-bg-white"
			>
				<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<label
							for="packageSearch"
							class="tw-sr-only"
						>
							Search
						</label>
						<div class="tw-relative tw-rounded-md tw-shadow-sm">
							<div
								class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none"
							>
								<!-- Heroicon name: solid/search -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<input
								v-model="search"
								name="packageSearch"
								id="packageSearch"
								type="search"
								class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								@input="debounceSearch"
								placeholder="Search"
							/>
							<!-- Heroicon name: x -->
							<div
								v-if="search != ''"
								class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
							>
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									@click="clearSearch"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div class="tw-col-span-6 sm:tw-col-span-3">
						<div class="tw-flex tw-gap-3">
							<select
								v-model="statusFilter"
								id="status"
								name="status"
								class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								@change="loadCollaborations()"
							>
								<option value="all">
									All Collaborations
								</option>
								<option value="pending">
									Pending
								</option>
								<option value="accepted">
									Accepted
								</option>
								<option value="rejected">
									Rejected
								</option>
							</select>
							<button
								type="button"
								class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
								@click="resetFilters"
								v-tooltip="'Reset Filters'"
							>
								<!-- Heroicon name: solid/x -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="tw-sr-only">Reset Filters</span>
							</button>
						</div>
					</div>
				</div>

				<div
					v-if="collaborations.length != 0 && !loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						Showing your <span class="tw-font-bold">{{ mainFilter }}</span> now
					</div>
					<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
						Page: {{ pagination.page }}
					</div>
				</div>

				<div
					v-else-if="loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
							<div class="tw-btn-spinner tw-mt-1" />
							<span class="tw-ml-2">Loading...</span>
						</div>
					</div>
				</div>
				<!-- Packages table (small breakpoint and up) -->
				<div class="tw-mt-3">
					<div
						class="tw-overflow-x-scroll lg:tw-overflow-x-hidden tw-w-full tw-border-b tw-border-gray-100 tw-shadow"
					>
						<table
							class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100"
						>
							<thead>
								<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
									<th
										class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Package
									</th>
									<th
										class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										{{
											mainFilter == "inbox" ? "Package Owner" : "Invited User"
										}}
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Invitation Date
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Accepted Date
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Publish Access
									</th>
									<th
										class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									/>
								</tr>
							</thead>
							<tbody
								class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
							>
								<tr
									v-if="false"
									class="tw-bg-gray-50"
								>
									<td
										colspan="7"
										class="tw-pb-6 tw-text-center"
									>
										<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
											<div class="tw-btn-spinner tw-mt-1" />
											<span class="tw-ml-2">Loading...</span>
										</div>
									</td>
								</tr>
								<tr
									v-else-if="collaborations.length === 0 && !loading"
									class="tw-bg-gray-50"
								>
									<td
										colspan="7"
										class="tw-pb-6 tw-text-center"
									>
										<svg
											class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<p
											class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate"
										>
											No collaborations found
										</p>
									</td>
								</tr>
								<tr
									v-for="collaboration in collaborations"
									is="CollaborationRow"
									:collaboration="collaboration"
									:key="collaboration.collaborationID"
									:filter="mainFilter"
									class="hover:tw-bg-gray-50"
								/>
							</tbody>
						</table>
					</div>
				</div>

				<Pagination
					:total-pages="pagination.totalPages"
					:total-records="pagination.records"
					:max-rows="pagination.maxRow"
					:current-page="pagination.page"
					:loader="loadCollaborations"
					:loading="loading"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CollaborationRow from "./CollaborationRow";
import debounce from "lodash/debounce";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		collaborationsCount : {
			type     : Object,
			required : true,
			default  : function() {
				return {
					inbox              : 0,
					inboxNotConfirmed  : 0,
					outbox             : 0,
					outboxNotConfirmed : 0
				};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { CollaborationRow },
	data() {
		return {
			menu           : [ { label: "Collaborations" } ],
			collaborations : [],
			packageTypes   : [],
			search         : "",
			loading        : false,
			error          : "",
			mainFilter     : "inbox",
			statusFilter   : "all",
			notification   : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			},
			pagination : {
				startRow   : 0,
				maxRow     : 0,
				records    : 0,
				page       : 1,
				totalPages : 1
			}
		};
	},
	mounted() {
		var app = this;
		app.loadCollaborations();
	},
	computed : {
		pendingCollaborations() {
			if ( this.collaborations == "" ) {
				return [];
			} else {
				return this.collaborations.filter( c => !c.accepted );
			}
		},
		acceptedCollaborations() {
			if ( this.collaborations == "" ) {
				return [];
			}
			return this.collaborations.filter( c => c.accepted );
		}
	},
	methods : {
		loadCollaborations( page = 1 ) {
			var app = this;
			var pageNum = page;
			app.loading = true;
			var url = !app.isOrg
				? "/myaccount/collaborations/list"
				: `/orgs/${this.organization.slug}/collaborations/list`;
			app.$http
				.get( url, {
					params : {
						filter : app.mainFilter,
						status : app.statusFilter,
						search : app.search,
						page   : pageNum
					}
				} )
				.then( function( response ) {
					app.loading = false;
					if ( response.data.error ) {
						app.packages = [];
						app.error = response.data.messages;
						return;
					}
					app.collaborations = response.data.data;
					app.pagination = response.data.pagination;
				} )
				.catch( function( response ) {
					app.error = response.messages;
				} );
		},
		debounceSearch : debounce( function() {
			let app = this;
			app.loadCollaborations();
		}, 300 ),
		removeCollaboration( collaborationID ) {
			var app = this;

			for ( let [
				index,
				collaboration
			] of app.collaborations.entries() || [] ) {
				if ( collaboration.collaborationID == collaborationID ) {
					// Update counters
					app.collaborationsCount.outbox = app.collaborationsCount.outbox - 1;
					if ( !collaboration.accepted ) {
						app.collaborationsCount.outboxNotConfirmed =
							app.collaborationsCount.outboxNotConfirmed - 1;
					}
					app.collaborations.splice( index, 1 );
					app.loadCollaborations();
					return;
				}
			}
		},
		filterResults( filter ) {
			let app = this;
			app.mainFilter = filter;
			app.loadCollaborations();
		},
		clearSearch() {
			let app = this;
			app.search = "";
			app.loadCollaborations();
		},
		resetFilters() {
			let app = this;
			app.search = "";
			app.statusFilter = "all";
			app.loadCollaborations();
		},
		submit() {
			console.log( "save" );
		}
	}
};
</script>
