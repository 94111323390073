<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Invite Members
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											You can search for users that already have a FORGEBOX account or send an invite to any email so they can join the organization.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<div class="lg:tw-border-t lg:tw-border-b lg:tw-border-gray-200">
								<nav
									class="tw-mx-auto"
									aria-label="Progress"
								>
									<ol class="tw-rounded-md tw-overflow-hidden lg:tw-flex lg:tw-border-l lg:tw-border-r lg:tw-border-gray-200 lg:tw-rounded-none">
										<li class="tw-relative tw-overflow-hidden lg:tw-flex-1">
											<div class="tw-border tw-border-gray-200 tw-overflow-hidden tw-border-b-0 tw-rounded-t-md lg:tw-border-0">
												<!-- Users to Invite Step -->
												<div
													class="tw-group"
													:class="{ 'tw-cursor-pointer' : !hasSubscription }"
													@click="changeTab('1')"
												>
													<span
														:class="activeTab == 'step1' ? 'tw-bg-blue-600' : 'tw-bg-transparent group-hover:tw-bg-gray-200'"
														class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
														aria-hidden="true"
													/>
													<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium">
														<span class="tw-flex-shrink-0">
															<span
																:class="{ 'tw-bg-blue-600' : step1Completed, 'tw-text-blue-600 tw-border-blue-600' : activeTab =='step1', 'tw-border-gray-300' : activeTab !='step1' }"
																class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2"
															>
																<span
																	v-if="!step1Completed"
																	:class="activeTab == 'step1' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
																>
																	{{ !hasSubscription ? "01" : "+" }}
																</span>
																<span v-if="step1Completed">
																	<!-- Heroicon name: solid/check -->
																	<svg
																		class="tw-w-6 tw-h-6 tw-text-white"
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 20 20"
																		fill="currentColor"
																		aria-hidden="true"
																	>
																		<path
																			fill-rule="evenodd"
																			d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																			clip-rule="evenodd"
																		/>
																	</svg>
																</span>
															</span>
														</span>
														<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
															<span
																:class="activeTab == 'step1' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
																class="tw-text-xs tw-font-semibold tw-tracking-wide tw-uppercase"
															>
																Add Members
															</span>
															<span class="tw-text-sm tw-font-medium tw-text-gray-500">
																Find and add members to send invites
															</span>
														</span>
													</span>
												</div>
											</div>
										</li>

										<li
											v-if="!hasSubscription"
											class="tw-relative tw-overflow-hidden lg:tw-flex-1"
										>
											<div class="tw-border tw-border-gray-200 tw-overflow-hidden lg:tw-border-0">
												<!-- Checkout Step -->
												<div
													class="tw-group"
													:class="{ 'tw-cursor-pointer' : step1Completed, 'tw-bg-gray-100' : !step1Completed }"
													@click="changeTab('2')"
												>
													<span
														:class="{ 'tw-bg-blue-600' : activeTab == 'step2' , 'tw-bg-transparent group-hover:tw-bg-gray-200' : step1Completed && activeTab != 'step2' }"
														class="tw-absolute tw-top-0 tw-left-0 tw-w-1 tw-h-full lg:tw-w-full lg:tw-h-1 lg:tw-bottom-0 lg:tw-top-auto"
														aria-hidden="true"
													/>
													<span class="tw-px-6 tw-py-5 tw-flex tw-items-start tw-text-sm tw-font-medium lg:tw-pl-9">
														<span class="tw-flex-shrink-0">
															<span
																:class="{ 'tw-border-blue-600' : activeTab == 'step2', 'tw-border-gray-300' : activeTab != 'step2' }"
																class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-border-2 tw-rounded-full"
															>
																<span :class="activeTab == 'step2' ? 'tw-text-blue-600' : 'tw-text-gray-500'">02</span>
															</span>
														</span>
														<span class="tw-mt-0.5 tw-ml-4 tw-min-w-0 tw-flex tw-flex-col">
															<span
																:class="activeTab == 'step2' ? 'tw-text-blue-600' : 'tw-text-gray-500'"
																class="tw-text-xs tw-font-semibold tw-tracking-wide tw-uppercase"
															>
																Checkout
															</span>
															<span class="tw-text-sm tw-font-medium tw-text-gray-500">Review the subscription and enter a payment method</span>
														</span>
													</span>
												</div>

												<div
													class="tw-hidden tw-absolute tw-top-0 tw-left-0 tw-w-3 tw-inset-0 lg:tw-block"
													aria-hidden="true"
												>
													<svg
														class="tw-h-full tw-w-full tw-text-gray-300"
														viewBox="0 0 12 82"
														fill="none"
														preserveAspectRatio="none"
													>
														<path
															d="M0.5 0V31L10.5 41L0.5 51V82"
															stroke="currentcolor"
															vector-effect="non-scaling-stroke"
														/>
													</svg>
												</div>
											</div>
										</li>
									</ol>
								</nav>
							</div>

							<!-- Step 1 - Users to Invite -->
							<div
								class="tw-px-6 tw-py-3 tw-space-y-6"
								v-if="activeTab == 'step1'"
							>
								<div>
									<form
										@submit.prevent="add"
										class="form"
									>
										<div class="form-group">
											<span class="tw-text-sm tw-text-gray-900 tw-font-medium">FORGEBOX Username or Email</span>
											<multiselect
												v-model="usersToInvite"
												class="tw-mt-3"
												placeholder="Search for a user"
												open-direction="bottom"
												:options="filteredUsers"
												track-by="username"
												label="username"
												@search-change="debounceFindUsers"
												:multiple="true"
												:allow-empty="true"
												:internal-search="false"
												:loading="loading"
												:taggable="true"
												tag-placeholder="Add Email"
												@tag="addEmail"
												@input="errors = []"
											>
												<template
													slot="search"
													slot-scope="props"
												>
													{{ props.username }}
												</template>

												<template
													slot="singleLabel"
													slot-scope="{ option: { username, fname, lname, avatarLink } }"
												>
													<img
														:src="avatarLink"
														:alt="`${fname} ${lname}`"
														class="tw-rounded-full tw-mr-3 tw-inline-block"
													>
													{{ username }} ({{ fname }} {{ lname }})
												</template>

												<!-- <template
													slot="option"
													slot-scope="{ option: { username, fname, lname, avatarLink } }"
												>
													<img
														:src="avatarLink"
														:alt="`${fname} ${lname}`"
														class="tw-rounded-full tw-mr-3 tw-inline-block"
													>
													{{ username }} ({{ fname }} {{ lname }})
												</template> -->
											</multiselect>
										</div>
									</form>
								</div>

								<div
									v-if="usersToInvite.length > 0"
									class="tw-block"
								>
									<div>
										<div class="tw-flex tw-justify-between tw-mb-2">
											<div
												class="tw-text-sm tw-text-gray-900 tw-font-medium"
											>
												Users to invite
											</div>
											<div
												class="tw-text-sm tw-text-gray-900 tw-font-medium tw-justify-between"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="tw-h-4 tw-w-4 tw-inline-block tw-text-gray-500"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													v-tooltip="'Role within the organization'"
												>
													<path d="M12 14l9-5-9-5-9 5 9 5z" />
													<path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
													/>
												</svg>
												<span class="tw-mr-3">Role</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="tw-h-4 tw-w-4 tw-inline-block tw-text-gray-500"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													v-tooltip="'Only members with this permission will be able to create a new packages on the organization'"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
													/>
												</svg>
												<span>Can Create New Packages?</span>
											</div>
										</div>
										<ul class="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto users-to-invite-placeholder">
											<li
												v-for="(user,index) in usersToInvite"
												:key="user.username"
												class="tw-py-2 tw-flex"
											>
												<div class="tw-flex tw-w-full tw-justify-between">
													<div
														v-if="user.avatarLink != ''"
														class=""
													>
														<span class="tw-text-sm tw-text-gray-500">
															<img
																class="tw-h-5 tw-w-5 tw-rounded-full tw-inline-block"
																:src="user.avatarLink"
																alt=""
																v-tooltip="'FORGEBOX User'"
															>
															{{ user.fullName }} <small>({{ user.username }})</small>
														</span>
													</div>
													<div v-else>
														<span class="tw-text-sm tw-text-gray-500">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																class="tw-h-6 tw-w-6 tw-mr-1 tw-text-gray-500 tw-inline-block"
																fill="none"
																viewBox="0 0 24 24"
																stroke="currentColor"
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	stroke-width="2"
																	d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
																/>
															</svg>
															{{ user.username }}
														</span>
													</div>

													<div class="tw-flex tw-space-x-10">
														<select
															v-model="user.role"
															:name="'user-role'+index"
															class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-36 tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
														>
															<option value="Member">
																Member
															</option>
															<option value="Admin">
																Admin
															</option>
															<option value="Super Admin">
																Super Admin
															</option>
														</select>

														<!-- Can user create packages? -->
														<select
															v-model="user.canCreateNewPackages"
															:name="'can-create-new-packages'+index"
															class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
														>
															<option :value="false">
																No
															</option>
															<option :value="true">
																Yes
															</option>
														</select>
													</div>
												</div>
											</li>
										</ul>

										<div
											v-if="hasSubscription && !organization.isComplimentary"
											class="tw-mt-3"
										>
											<div
												class="tw-text-sm tw-text-gray-900 tw-font-medium"
											>
												Subscription Update
											</div>

											<div class="tw-mt-3">
												<table class="tw-min-w-full">
													<thead>
														<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
															<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
															<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
																Current
															</th>
															<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
																New
															</th>
														</tr>
													</thead>
													<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
														<tr
															class="hover:tw-bg-gray-50"
														>
															<th
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
															>
																<span>
																	Members
																</span>
															</th>
															<td
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
															>
																<span>
																	{{ currentMembers }}
																</span>
															</td>
															<td
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
															>
																<span>
																	{{ currentMembers + usersToInvite.length }}
																</span>
															</td>
														</tr>
														<tr
															class="hover:tw-bg-gray-50"
														>
															<th
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
															>
																<span>
																	Total
																</span>
															</th>
															<td
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
															>
																<span>
																	${{ currentAmount | formatNumber }}
																</span>
															</td>
															<td
																class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
															>
																<span>
																	${{ newAmount | formatNumber }}
																</span>
															</td>
														</tr>
													</tbody>
												</table>

												<div
													v-if="!organization.isComplimentary"
													class="tw-text-sm tw-text-gray-500 tw-italic tw-mt-3"
												>
													<span class="tw-text-sm tw-text-gray-500">
														Your subscription changes will be applied immediately. The next billing date will remain the same {{ nextBillDate }}.
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Step 2 - Checkout -->
							<checkout
								v-if="activeTab == 'step2' && !hasSubscription"
								:users-to-invite="usersToInvite"
								:price-per-member="pricePerMember"
								:card-element="cardElement"
							/>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<!-- Alert Box -->
							<Alert
								type="error"
								:messages="errors"
								class="tw-mb-3"
							/>
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									:label="getButtonLabel()"
									:loading="loading"
									:method="checkout"
									:disabled="loading || !isValidForm"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Multiselect from "vue-multiselect";
import Checkout from "./Checkout";
import debounce from "lodash/debounce";

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		members : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		plan : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		stripePublishableKey : {
			type     : String,
			required : true,
			default  : ""
		},
		nextBillDate : {
			type     : String,
			required : true,
			default  : ""
		}
	},
	components : { Multiselect, Checkout },
	data() {
		return {
			showPanel       : false,
			activeTab       : "step1",
			hasSubscription : this.organization.isComplimentary || this.members.length > 1, // Check for provider id and num of members on stripe (quantity)
			pricePerMember  : this.plan.price,
			users           : [],
			emails          : [],
			usersToInvite   : [],
			stripe          : null,
			cardElement     : null,
			validCard       : false,
			errors          : [],
			loading         : false
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	async mounted() {
		var app = this;
		app.findUsers();
		app.stripe = await loadStripe( app.stripePublishableKey );
		app.cardElement = app.stripe.elements().create( "card", {
			base : {
				fontFamily : "inherit",
				fontSize   : "100%",
				color      : "rgba(55, 65, 81, var(--text-opacity))",
			},
		} );
	},
	computed : {
		filteredUsers() {
			return this.users.map(
				function( user ){
					user[ "role" ] = "Member";
					user[ "canCreateNewPackages" ] = false;
					return user;
				} ).concat( this.emails );
		},
		currentMembers() {
			return ( this.members.length - 1 );
		},
		currentAmount() {
			return ( this.members.length - 1 ) * this.pricePerMember;
		},
		newAmount() {
			return ( this.members.length + this.usersToInvite.length - 1 ) * this.pricePerMember;
		},
		step1Completed() {
			return this.usersToInvite.length > 0;
		},
		isValidForm() {
			return this.step1Completed || ( this.activeTab == "step2" && this.validCard );
		}
	},
	methods : {
		debounceFindUsers : debounce( function( query ) {
			this.findUsers( query );
		}, 500 ),
		findUsers( query="" ){
			let app = this;
			app.errors = [];
			app.loading = true;

			app.$http.get(
				"/api/v1/users",
				{ params: { searchTerm: query } }
			)
				.then( response => {
					app.users = response.data.data.results;
					app.loading = false;
				} )
				.catch( err => {
					app.loading = false;
					app.errors.push( "The users list couldn't be retrieved. Please try again" );
				} );
		},
		async checkout() {
			let app = this;
			app.$parent.notification.show = false;
			app.errors = [];
			if ( !app.hasSubscription && app.activeTab == "step1" ) {
				app.activeTab = "step2";
			}
			else if ( app.hasSubscription && app.activeTab == "step1" ) {
				app.updateSubscription();
				return;
			} else {
				// Create Stripe payment method
				const stripeResponse = await app.stripe.createPaymentMethod( {
					type : "card",
					card : app.cardElement,
				} );
				if ( "error" in stripeResponse && stripeResponse.error.message != "" ) {
					app.errors.push( stripeResponse.error.message );
					app.loading = false;
					return;
				}
				app.loading = true;
				app.$http.post( `/orgs/${app.organization.slug}/subscribe`, { users: app.usersToInvite, paymentMethodId: stripeResponse.paymentMethod.id } )
					.then( ( response, status ) => {
						app.loading = false;
						if ( !response.data.error ) {
							app.updateUI();
							app.updateFlag();
							app.$parent.notification = {
								type    : "success",
								title   : "Success",
								message : "Subscription created successfully!",
								show    : true
							};
						} else {
							app.errors.push( response.data.messages[0] );
						}
					} )
					.catch( error => {
						app.errors.push( error.toString() );
						app.loading = false;
					} );
			}
		},
		updateSubscription() {
			var app = this;
			app.$http.put( `/orgs/${app.organization.slug}/updateSubscription`, { users: app.usersToInvite, isComplimentary: app.organization.isComplimentary } )
				.then( ( response, status ) => {
					app.loading = false;
					if ( !response.data.error ) {
						app.updateUI();
						app.updateFlag();
						app.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : app.organization.isComplimentary ? "Members added successfully!" : "Subscription updated successfully!",
							show    : true
						};
					} else {
						app.errors = [ response.data.messages[0] ];
					}
				} )
				.catch( error => {
					app.errors = [ error.response.data.messages[ 0 ] ];
					app.loading = false;
				} );
		},
		addEmail( email ){
			this.errors = [];
			if ( this.validEmail( email ) ){
				var newUser = {
					email                : email,
					username             : email,
					role                 : "Member",
					avatarLink           : "",
					fname                : "",
					lname                : "",
					canCreateNewPackages : false
				};
				this.emails.push( newUser );
				this.usersToInvite.push( newUser );
			} else {
				this.errors.push( "'"+email + "' is not a valid email. Please enter a valid email address" );
			}
		},
		changeTab( step ) {
			// Validate state
			if ( ( step == "2" && !this.step1Completed ) || this.hasSubscription ) {
				return;
			}
			this.activeTab = "step" + step;
		},
		getButtonLabel() {
			var app = this;
			var label = "Checkout";
			if ( !app.hasSubscription && app.activeTab == "step1" ){
				label = "Proceed to Checkout";
			} else if ( !app.hasSubscription && app.activeTab == "step2" ){
				label = "Subscribe";
			} else if ( this.organization.isComplimentary ){
				label = "Add Members";
			}
			else if ( app.hasSubscription ){
				label = "Update Subscription";
			}
			return label;
		},
		validEmail( email ) {
			if ( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test( email ) ) {
				return ( true );
			}
			return ( false );
		},
		updateUI() {
			var app = this;
			app.usersToInvite.forEach( function( value, index, arr ){
				var member = {
					isOwner       : false,
					respondedDate : "",
					createdDate   : new Date(),
					user          : {
						username : "email" in value ? value.email.split( "@" )[0] : value.username,
						email    : "email" in value ? value.email : "",
						fullName : "email" in value ? app.organization.slug + " OrgMember" : value.fullName
					},
					role : { role: value.role }
				};
				app.$parent.orgMembers.push( member );
				app.$parent.orgMembers.sort();
			} );
			// TODO: Update data on the billing page
		},
		updateFlag : function() {
			this.showPanel = false;
			this.emails        = [];
			this.usersToInvite = [];
			this.activeTab = "step1";
			this.errors = [];
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	},
	beforeDestroy() {
		if ( this.cardElement ) {
			this.cardElement.destroy();
			this.cardElement = null;
		}
	}
};
</script>