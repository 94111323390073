<template>
	<div
		class="modal fade"
		id="auditLogModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="Subscription Audit Log"
		aria-hidden="true"
	>
		<div
			class="modal-dialog modal-lg"
			role="document"
		>
			<div class="modal-content">
				<form>
					<div class="modal-header">
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<h1
							class="modal-title"
							id="exampleModalLabel"
						>
							Subscription Audit Log
						</h1>
					</div>
					<div class="modal-body">
						<table class="table">
							<thead>
								<tr>
									<th class="text-center">
										#
									</th>
									<th>
										Plan
									</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th class="text-center">
										Complimentary?
									</th>
									<th class="text-center">
										Active
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(subscription, index) in subscriptions"
									:key="subscription.subscriptionId"
								>
									<td class="text-center">
										{{ index + 1 }}
									</td>
									<td>
										{{ subscription.plan }}
									</td>
									<td>{{ subscription.createdDate | formatDate }}</td>
									<td>{{ subscription.updatedDate | formatDate }}</td>
									<td class="text-center">
										{{ subscription.isComplimentary | yesOrNo }}
									</td>
									<td class="text-center">
										<span
											class="badge badge-dot"
											v-tooltip="subscription.isActive ? 'Active' : 'Inactive'"
										>
											<i :class="{ 'bg-success' : subscription.isActive, 'bg-danger' : !subscription.isActive }" />
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-dismiss="modal"
						>
							Close
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		userid : {
			type     : String,
			required : true
		},
		admin : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	data() {
		return {
			isLoading     : false,
			errors        : [],
			subscriptions : []
		};
	},
	mounted(){
		this.$nextTick( () => {
			this.loadUserSubscriptions();
		} );
	},
	computed : {},
	methods  : {
		loadUserSubscriptions() {
			var app = this;
			app.isLoading = true;
			var endpointURL = "profile/listSubscriptions";
			if ( app.admin ) {
				endpointURL = `admin/userSubscriptions/show/${this.userid}`;
			}
			app.$http.get( endpointURL )
				.then( function( response ) {
					// handle success
					app.subscriptions = response.data.data;
				} )
				.catch( function( error ) {
					// handle error
					console.log( error );
				} );
		}
	},
	filters : {
		yesOrNo : function( value ) {
			return value ? "Yes" : "No";
		}
	}
};
</script>