<template>
	<div>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-show="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Update Payment Method
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Enter a new credit card information to use for your recurring payments.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<!-- Divider container -->
							<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
								<fieldset>
									<legend class="tw-sr-only">
										Enter Credit Card Information
									</legend>
									<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-px-6 tw-p-5 tw-text-gray-500">
										<div class="tw-flex tw-items-center tw-border-b-2 tw-border-blue-500 tw-py-2">
											<div
												class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight focus:tw-outline-none"
												ref="card"
											/>
										</div>
									</div>
								</fieldset>
							</div>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									label="Update"
									:loading="loading"
									:method="updatePaymentMethod"
									:disabled="!validForm || loading"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>


<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		stripePublishableKey : {
			type     : String,
			required : true,
			default  : ""
		},
		orgSlug : {
			type     : String,
			required : true,
			default  : ""
		}
	},
	data() {
		return {
			showPanel   : false,
			stripe      : null,
			cardElement : null,
			validForm   : false,
			loading     : false,
			errors      : []
		};
	},
	async mounted() {
		var app = this;
		app.stripe = await loadStripe( app.stripePublishableKey );
		app.cardElement = app.stripe.elements().create( "card", {
			base : {
				fontFamily : "inherit",
				fontSize   : "100%",
				color      : "rgba(55, 65, 81, var(--text-opacity))",
			},
		} );
		app.cardElement.mount( app.$refs.card );

		// Check if card is valid
		app.cardElement.on( "change", function( event ) {
			if ( event.complete ) {
				app.validForm = true;
			} else if ( event.error ) {
				app.validForm = false;
			}
		} );
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {},
	methods  : {
		async updatePaymentMethod() {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;

			// Create Stripe payment method
			const data = await this.stripe.createPaymentMethod( {
				type : "card",
				card : app.cardElement,
			} );

			app.$http.put( `/api/v1/subscriptions/${data.paymentMethod.id}`, { isOrg: true, orgSlug: app.orgSlug } )
				.then( ( response ) => {
					if ( !response.data.error ) {
						app.loading = false;
						app.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "Your payment method has been updated successfully.",
							show    : true
						};
						app.updateOrganizationCard( response.data.data.card );
					} else {
						app.$parent.notification = {
							type    : "error",
							title   : "Error",
							message : response.data.messages[0],
							show    : true
						};
						app.loading = false;
					}
				} )
				.catch( error => {
					console.log( error );
					app.$parent.notification = {
						type    : "error",
						title   : "Error",
						message : "There was an error updating your payment method. Please try again",
						show    : true
					};
					app.loading = false;
				} );

		},
		updateOrganizationCard( card ) {
			var app = this;
			app.$parent.cardDetails.card.brand = card.brand;
			app.$parent.cardDetails.card.last4 = card.last4;
			app.$parent.cardDetails.card["exp_month"] = card.exp_month;
			app.$parent.cardDetails.card["exp_year"] = card.exp_year;
			app.validForm = false;
			app.errors = [];
			app.cardElement.destroy();
			app.cardElement = null;
			app.updateFlag();
		},
		updateFlag : function() {
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	},
	beforeDestroy() {
		if ( this.cardElement ) {
			this.cardElement.destroy();
			this.cardElement = null;
		}
	}
};
</script>