<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<div class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Change Email
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											An email will be sent to the new address to confirm the account.
											Your account will be temporary unavailable until you confirm it with the new email address.
											Another email will also be sent to the old
											<span class="tw-text-red-500">
												{{ user.email }}
											</span> address to verify that this is intentional.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<!-- Divider container -->
							<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
								<!-- Current Password -->
								<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
									<div>
										<label
											for="currentPassword"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											Current Password
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="form.currentPassword"
											type="password"
											name="currentPassword"
											id="currentPassword"
											required="true"
											class="tw-block tw-w-full tw-shadow-sm sm:tw-text-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
										>
									</div>
								</div>

								<!-- New Email -->
								<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
									<div>
										<label
											for="newEmail"
											class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
										>
											New Email
										</label>
									</div>
									<div class="sm:tw-col-span-2">
										<input
											v-model="form.email"
											type="text"
											name="email"
											id="newEmail"
											required="true"
											class="tw-block tw-w-full tw-shadow-sm sm:tw-text-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
										>
									</div>
								</div>
							</div>

							<!-- Alert Box -->
							<Alert
								class="tw-m-3"
								type="error"
								:messages="errors"
							/>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-ml-3 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									label="Change Email"
									:loading="loading"
									:method="changeEmail"
									:disabled="!isValidForm || loading"
								/>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	data() {
		return {
			showPanel : false,
			form      : {
				currentPassword : "",
				email           : ""
			},
			loading : false,
			errors  : []
		};
	},
	computed : {
		isValidForm() {
			return this.form.currentPassword != "" && this.form.email != "";
		}
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	methods : {
		changeEmail() {
			var app = this;
			app.loading = true;
			app.errors = [];
			app.$parent.notification.show = false;
			const data = new FormData();
			data.append( "currentPassword", app.form.currentPassword );
			data.append( "email", app.form.email );
			app.$http.post(
				"/myaccount/settings/account/changeEmail", data
			)
				.then( ( response ) => {
					app.loading = false;
					if ( !response.data.error ) {
						app.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : response.data.data,
							show    : true
						};
						window.location = "/login";
					} else {
						app.errors.push( response.data.messages );
					}
				} ).catch( function( error ) {
					// handle error
					app.loading = false;
					app.errors = [ error.response.data.messages[0] ];
					app.$parent.notification = {
						type    : "error",
						title   : "Error",
						message : "Oops something went wrong. Please try again",
						show    : true
					};
				} );
		},
		updateFlag : function() {
			this.form = {
				currentPassword : "",
				email           : ""
			};
			this.errors = [];
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>