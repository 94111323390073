<template>
	<button
		:type="type"
		class="tw-px-4 tw-py-2 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
		:class="disabled ? 'tw-bg-gray-200 tw-cursor-not-allowed tw-border tw-border-gray-300 tw-text-gray-500' : 'tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white'"
		@click="method"
		:disabled="disabled || loading"
	>
		<span
			v-if="loading"
		>
			<div
				class="tw-btn-spinner tw-mt-1 tw-inline-block"
			/>
		</span>
		{{ label }}
	</button>
</template>
<script>

export default {
	props : {
		label : {
			type     : String,
			required : true,
			default  : ""
		},
		type : {
			type     : String,
			required : false,
			default  : "button"
		},
		loading : {
			type     : Boolean,
			required : true
		},
		disabled : {
			type     : Boolean,
			required : false
		},
		method : {
			type    : Function,
			default : () => {}
		},
		hideText : {
			type    : Boolean,
			default : false
		}
	},
	computed : {
		dynamicClass(){
			let target = "";

			return target;
		}
	}
};
</script>