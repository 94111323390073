<template>
	<div class="flex-1">
		<page-header
			:menu="menu"
		/>
		<change-plan
			:show-slideover="showChangePlan"
			@interface="showChangePlan = $event"
			:subscription="subscription"
			:subscription-details="subscriptionDetails"
		/>
		<update-payment-method
			:show-slideover="showUpdatePaymentMethod"
			@interface="showUpdatePaymentMethod = $event"
			:stripe-publishable-key="stripePublishableKey"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Current Plan
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						The plan you are currently subscribed to. You can change your plan anytime if you wish.
					</p>
					<p class="tw-mt-3 tw-text-sm tw-text-gray-500">
						Looking for an organization account?<br>
						<a
							href="/myaccount/organizations"
							class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
						>
							Create an organization now!
						</a>
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<dl class="tw-divide-y tw-divide-gray-200">
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Name
							</dt>
							<dd class="tw-mt-1 tw-flex tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
								<span
									class="tw-flex-grow"
								>
									{{ subscription.plan.name }}
									<span
										v-if="subscription.isComplimentary"
										class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-blue-100 tw-text-blue-800 tw-border-blue-200 tw-border"
									>
										Complimentary Subscription
									</span>
								</span>
								<span
									v-if="!isCancelled"
									class="tw-ml-4 tw-flex-shrink-0"
								>
									<span
										class="tw-cursor-pointer tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm"
										@click="showChangePlan = true"
									>
										Change Plan
									</span>
								</span>
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Amount
							</dt>
							<dd class="tw-mt-1 tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
								<span
									v-if="subscription.plan.isFree || subscription.isComplimentary"
								>
									$0
								</span>
								<span v-else>
									${{ subscription.plan.price }}
								</span>
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								<span
									v-if="!isCancelled"
								>
									Next Bill Date
								</span>
								<span v-else>
									Subscription End Date
								</span>
							</dt>
							<dd class="tw-mt-1 tw-flex tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-col-span-2">
								<span
									v-if="subscription.isComplimentary"
									class="tw-flex-grow"
								>
									N/A
								</span>
								<span
									v-else-if="subscription.plan.isFree"
									class="tw-flex-grow"
								>
									No active subscription
								</span>
								<span
									v-else
									class="tw-flex-grow"
								>
									{{ subscriptionDetails.content.current_period_end | formatDate }}
								</span>

								<span
									v-if="isCancelled"
									class="tw-ml-4 tw-flex-shrink-0"
								>
									<span
										class="tw-cursor-pointer tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm"
										@click="resubscribeDialog()"
									>
										Resubscribe?
									</span>
								</span>
							</dd>
						</div>
						<div
							v-if="isCancelled"
							class="tw-py-4 sm:tw-py-5 sm:tw-gap-4 sm:tw-px-4"
						>
							<dd>
								<Alert
									type="warning"
									:messages="['Your subscription is currently cancelled. You will be able to use your plan features until ' + nextBillDate + ' unless you resubscribe before that date.']"
									:dismiss="false"
								/>
							</dd>
						</div>
					</dl>
				</div>
			</div>

			<!-- Divider -->
			<divider />

			<!-- Features Section -->
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Plan Features
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						These are the features you have available in your current plan.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg tw-text-gray-500">
					<div
						class="tw-mx-3 list-features"
						v-html="subscription.plan.featuresHtml"
					/>
				</div>
			</div>

			<!-- Divider -->
			<divider v-if="!subscription.plan.isFree && !subscription.isComplimentary" />

			<!-- Credit Card -->
			<div
				v-if="!subscription.plan.isFree && !subscription.isComplimentary"
				class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6"
			>
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Credit Card On File
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						On this section, you can update the credit card on file so you can manage your billing and payments.
					</p>
				</div>
				<section
					class="md:tw-col-span-2"
					aria-labelledby="payment_method_heading"
				>
					<div class="tw-mt-5 md:tw-mt-0 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
						<div class="tw-mt-3 tw-flex tw-flex-col">
							<div class="tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
								<div class="tw-py-2 tw-align-middle tw-inline-block tw-min-w-full sm:tw-px-6 lg:tw-px-8">
									<div class="tw-overflow-hidden tw-border tw-shadow tw-bg-gray-100 tw-rounded-lg">
										<table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
											<thead class="tw-bg-white">
												<tr class="tw-bg-gray-100">
													<th
														scope="col"
														class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
													>
														Card Type
													</th>
													<th
														scope="col"
														class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
													>
														Card Number
													</th>
													<th
														scope="col"
														class="tw-px-6 tw-py-3 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
													>
														Exp. Date
													</th>
													<!--
															`relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
														-->
													<th
														scope="col"
														class="tw-relative tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
													>
														<span class="tw-sr-only">Update payment method</span>
													</th>
												</tr>
											</thead>
											<tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
												<tr>
													<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
														{{ cardDetails.card.brand | capitalize }}
													</td>
													<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
														****-****-****-{{ cardDetails.card.last4 }}
													</td>
													<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
														{{ cardDetails.card.exp_month }}/{{ cardDetails.card.exp_year }}
													</td>
													<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
														<span
															class="tw-cursor-pointer tw-text-blue-600 hover:tw-text-blue-500"
															@click="showUpdatePaymentMethod = true"
														>
															Update payment method
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<!-- TODO: Enable this section later -->
			<div v-if="!subscription.plan.isFree && !subscription.isComplimentary && false">
				<!-- Divider -->
				<divider />

				<!-- Billing History -->
				<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Billing History
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							You can see your billing history here.
						</p>
					</div>
					<div class="tw-col-span-2">
						<!-- Billing history -->
						<section aria-labelledby="billing_history_heading">
							<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
								<div class="tw-mt-3 tw-flex tw-flex-col">
									<div class="tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
										<div class="tw-py-2 tw-align-middle tw-inline-block tw-min-w-full sm:tw-px-6 lg:tw-px-8">
											<div class="tw-overflow-hidden tw-border tw-shadow tw-bg-gray-100 tw-rounded-lg">
												<table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
													<thead class="tw-bg-white">
														<tr class="tw-bg-gray-100">
															<th
																scope="col"
																class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
															>
																Date
															</th>
															<th
																scope="col"
																class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
															>
																Description
															</th>
															<th
																scope="col"
																class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
															>
																Amount
															</th>
															<!--
															`relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
														-->
															<th
																scope="col"
																class="tw-relative tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
															>
																<span class="tw-sr-only">View receipt</span>
															</th>
														</tr>
													</thead>
													<tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
														<tr>
															<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
																1/1/2020
															</td>
															<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
																FORGEBOX Business Plan
															</td>
															<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
																$7.99
															</td>
															<td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
																<a
																	href="#"
																	class="tw-text-blue-600 hover:tw-text-blue-500"
																>View receipt</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ChangePlan from "./ChangePlan";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import swal from "sweetalert2";
import moment from "moment";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		subscriptionDetails : {
			type     : Object,
			required : true,
			default  : function() {
				return { content: { "current_period_end": new Date() } };
			}
		},
		cardDetails : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		stripePublishableKey : {
			type     : String,
			required : true,
			default  : ""
		}
	},
	components : {
		ChangePlan,
		UpdatePaymentMethod
	},
	data() {
		return {
			menu                    : [ { label: "Plan & Billing" } ],
			subscription            : this.user.subscription,
			showChangePlan          : false,
			showUpdatePaymentMethod : false,
			loading                 : false,
			notification            : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	computed : {
		isCancelled() {
			var app = this;
			return !app.subscription.isActive && app.subscription.endDate != "";
		},
		nextBillDate() {
			var app = this;
			return moment( new Date( app.subscriptionDetails.content.current_period_end ) ).format( "ll" );
		}
	},
	methods : {
		resubscribeDialog() {
			var app = this;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to resubscribe to the " + app.subscription.plan.name + " plan. Your billing will resume on " + app.nextBillDate + ".",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Resubscribe Now",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.put( "/api/v1/resubscribe" )
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.updateUI();
								app.notification = {
									type    : "success",
									title   : "Success",
									message : "Your subscription has been resumed successfully.",
									show    : true
								};
							} else {
								app.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error changing your plan. Please try again",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		updateUI() {
			var app = this;
			app.subscription.isActive = true;
			app.subscription.endDate = "";
		}
	}
};
</script>