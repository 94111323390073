<template>
	<!-- Step 2 - Checkout -->
	<div
		class="tw-px-6 tw-py-3 tw-space-y-6"
	>
		<div>
			<span
				class="tw-text-sm tw-text-gray-900 tw-font-medium"
			>
				Enter Credit Card Information
			</span>
			<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
				<fieldset>
					<div class="tw-mt-3 tw-text-gray-500">
						<div class="tw-flex tw-items-center tw-border-b-2 tw-border-blue-500 tw-py-2">
							<div
								class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight focus:tw-outline-none"
								ref="card"
							/>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div
			v-if="usersToInvite.length > 0"
		>
			<span
				class="tw-text-sm tw-text-gray-900 tw-font-medium"
			>
				Billing Summary
			</span>
			<div>
				<div class="tw-flex tw-items-center tw-justify-between tw-mb-3">
					<div class="tw-text-gray-500">
						<span class="tw-block tw-text-sm">
							FORGEBOX Subscription
						</span>
						<span class="tw-text-sm">
							Date:  {{ Date.now() | formatDate }}
						</span>
					</div>
					<div class="tw-text-right">
						<img
							class="tw-h-9 tw-w-auto"
							src="/includes/images/ForgeBoxIcon100.png"
							alt="FORGEBOX"
						>
					</div>
				</div>

				<div class="tw-flex tw-justify-between tw-mb-4 tw-bg-gray-200 tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
					<div class="tw-font-medium">
						Price Per Member (Month)
					</div>
					<div class="tw-text-right">
						${{ pricePerMember }}
					</div>
				</div>
				<div class="tw-flex tw-justify-between tw-mb-4 tw-bg-gray-200 tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
					<div class="tw-font-medium">
						Members to Invite
					</div>
					<div class="tw-text-right">
						{{ usersToInvite.length }}
					</div>
				</div>

				<div class="tw-flex tw-justify-between tw-items-center">
					<div class="tw-text-base tw-font-medium tw-leading-none">
						<span class="tw-text-gray-500">
							Total:
						</span>
					</div>
					<div class="tw-text-base tw-text-gray-500 tw-text-right tw-font-medium">
						${{ totalAmount }} Per Month
					</div>
				</div>

				<!-- Alert Box -->
				<Alert
					type="error"
					:messages="errors"
				/>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		usersToInvite : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		pricePerMember : {
			type     : Number,
			required : true,
			default  : function() {
				return 6.99;
			}
		},
		cardElement : {
			type     : Object,
			required : true,
			default  : function() {
			}
		}
	},
	components : {},
	data() {
		return { errors: [] };
	},
	watch : {},
	mounted() {
		var app = this;
		app.cardElement.mount( this.$refs.card );

		// Check if card is valid
		app.cardElement.on( "change", function( event ) {
			if ( event.complete ) {
				app.$parent.validCard = true;
			} else if ( event.error ) {
				app.$parent.validCard = false;
			}
		} );
	},
	computed : {
		totalAmount() {
			return this.usersToInvite.length * this.pricePerMember;
		}
	},
	methods : {}
};
</script>