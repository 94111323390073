<template>
	<textarea ref="area" />
</template>
<script>
/**
 * @vue-prop {String} type - Alert Type according to bootstrap
 * @vue-prop {any} messages - The single message or arry of messages
 * @vue-computed {Boolean} close Checks if the messagebox is closed or not
 */
export default {
	props : {
		// Set prop
		value : {
			type    : String,
			default : ""
		}
	},
	mounted(){
		this.mde = new SimpleMDE( {
			element : this.$refs.area, // Tie SimpleMDE to your textarea
			// Set your SimpleMDE configuration here
			// e.g. remove the status bar (status: false), customise the
			// toolbar (toolbar: ["bold", "italic", "heading"]) or similar
		} );
		this.mde.value( this.value );
		var self = this;
		this.mde.codemirror.on( "change", function() {
			// Catch on change events
			self.$emit( "input", self.mde.value() );
		} );
	},
	watch : {
		// Setup a watch to track changes,
		// and only update when changes are made
		value( newVal ) {
			if ( newVal != this.mde.value() ) {
				this.mde.value( newVal );
			}
		}
	},
	beforeDestroy() {
		// Clean up when the component gets destroyed
		this.mde.toTextArea();
	}
};
</script>