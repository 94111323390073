<template>
	<div>
		<div class="tw-flex tw-border-b">
			<div class="">
				<h3 class="tw-text-lg tw-font-medium">
					<svg
						v-if="sameUser"
						class="tw-inline-block tw-h-8 tw-w-8"
						:class="thisPackage.isPrivate ? 'tw-text-red-600' : 'tw-text-green-500'"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
						v-tooltip="thisPackage.isPrivate ? 'Private Package' : 'Public Package'"
					>
						<path
							v-if="thisPackage.isPrivate"
							fill-rule="evenodd"
							d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
							clip-rule="evenodd"
						/>
						<path
							v-else
							d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
						/>
					</svg>
					<a
						:href="'view/'+ thisPackage.slug"
						:title="'View:' + thisPackage.slug"
						class="focus:tw-outline-none hover:tw-text-gray-600"
					>
						{{ thisPackage.title }}
					</a>
					<span
						v-if="thisPackage.latestVersion.version"
						class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800 tw-align-middle"
					>
						{{ thisPackage.latestVersion.version }}
					</span>
				</h3>
			</div>
			<div class="tw-flex-grow">
				<span class="tw-float-right">
					<a
						:href="'type/' + thisPackage.typeSlug"
					>
						<span class="tw-inline-flex tw-items-center tw-truncate tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-yellow-100 tw-text-yellow-800 hover:tw-opacity-75">
							{{ thisPackage.typeName }}
						</span>
					</a>
					<div
						v-if="!isOrg && sameUser"
						v-click-outside="() => (showDropdown = false)"
						class="tw-relative tw-inline-block tw-justify-end tw-items-center tw-align-middle"
					>

						<!-- Heroicon name: solid/dots-vertical -->
						<button
							aria-haspopup="true"
							type="button"
							class="tw-w-5 tw-h-5 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
							@click="showDropdown = !showDropdown"
						>
							<span class="tw-sr-only">Open dropdown</span>
							<svg
								class="tw-w-5 tw-h-5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
							</svg>
						</button>
						<transition
							enter-class="tw-opacity-0 tw-transform tw-scale-95"
							enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
							enter-active-class="tw-transition ease-out duration-200"
							leave-class="tw-opacity-100 tw-transform tw-scale-100"
							leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
							leave-active-class="tw-transition tw-ease-in tw-duration-75"
						>
							<div
								v-if="showDropdown"
								class="tw-mx-0 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="package-options-menu-0"
							>
								<div
									class="tw-py-1"
									role="none"
								>
									<a
										:href="'/view/'+thisPackage.slug"
										class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
										role="menuitem"
									>
										<!-- Heroicon name: eye -->
										<svg
											class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
											/>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
											/>
										</svg>
										View
									</a>
									<a
										:href="'/myaccount/packages/manage/'+thisPackage.slug"
										class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
										role="menuitem"
									>
										<!-- Heroicon name: solid/pencil-alt -->
										<svg
											class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
											<path
												fill-rule="evenodd"
												d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
												clip-rule="evenodd"
											/>
										</svg>
										Manage
									</a>
								</div>
							</div>
						</transition>
					</div>
				</span>
			</div>
		</div>
		<div class="tw-mt-8">
			<div
				class="tw-mt-2 tw-text-sm tw-text-gray-500 tw-h-16"
			>
				<span v-if="thisPackage.summary.length">
					{{ thisPackage.summary }}
				</span>
				<span
					v-if="sameUser && thisPackage.summary.length == 0"
					class="tw-text-red-500"
				>
					This package does not have a summary, please add one!
				</span>
				<span
					v-else-if="thisPackage.summary.length == 0"
					class="tw-italic"
				>
					This package does not have a summary yet :(
				</span>
			</div>
			<div class="tw-border-t tw-mt-6 tw-pt-6 tw-border-gray-200 tw-flex">
				<div class="tw-flex tw-flex-grow">
					<div class="tw-flex tw-items-center tw-text-sm tw-text-gray-500 tw-mr-3">
						<!-- Heroicon name: solid/download -->
						<svg
							class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-pointer"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24"
							stroke="currentColor"
							v-tooltip="'Installs'"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M13 10V3L4 14h7v7l9-11h-7z"
							/>
						</svg>
						{{ thisPackage.installs == 0 ? '0' : thisPackage.installs | formatNumber }}
					</div>
					<div class="tw-flex tw-items-center tw-text-sm tw-text-gray-500 tw-mr-3">
						<!-- Heroicon name: star -->
						<svg
							class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-pointer"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							v-tooltip="'Rating'"
						>
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						</svg>
						{{ thisPackage.avgRating == 0.0000 ? 'N/A' : thisPackage.avgRating | formatNumber }}
					</div>
				</div>
				<div class="tw-flex-shrink tw-text-sm tw-text-gray-500">
					<span class="tw-text-gray-600 tw-font-semibold">
						Last Update:
					</span>
					{{ thisPackage.latestVersionUpdatedDate | formatDate }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
		},
		sameUser : {
			type     : Boolean,
			required : true,
			default  : false
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
	},
	data() {
		return { showDropdown: false, };
	},
	directives : { ClickOutside },
	computed   : {},
	methods    : {}
};
</script>
