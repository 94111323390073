<template>
	<div class="mt-4">
		<p>
			Here are all the collaborators for this package. Collaborators all have read access by <strong>default</strong>.
			To give a collaborator publish access, mark the checkbox next to their name.
		</p>

		<hr class="my-4">

		<h3>Add a new collaborator</h3>

		<div class="alert alert-danger">
			<i class="fa fa-exclamation-triangle" />
			All collaborators must have a ForgeBox account.
		</div>

		<form
			@submit.prevent="add"
			class="form"
		>
			<div class="form-group">
				<label class="control-label">Username</label>
				<multiselect
					v-model="newCollaborator"
					id="newCollaborator"
					placeholder="Search for a user"
					open-direction="bottom"
					:options="users"
					track-by="username"
					label="username"
					@search-change="findUsers"
					:allow-empty="false"
					:internal-search="false"
					:loading="loading"
				>
					<template
						slot="singleLabel"
						slot-scope="{ option: { username, fname, lname, avatarLink } }"
					>
						<img
							:src="avatarLink"
							:alt="`${fname} ${lname}`"
							class="rounded-circle"
							style="margin-right: 1rem;"
						>
						{{ username }} ({{ fname }} {{ lname }})
					</template>
					<template
						slot="option"
						slot-scope="{ option: { username, fname, lname, avatarLink } }"
					>
						<img
							:src="avatarLink"
							:alt="`${fname} ${lname}`"
							class="rounded-circle"
							style="margin-right: 1rem;"
						>
						{{ username }} ({{ fname }} {{ lname }})
					</template>
				</multiselect>
			</div>

			<!--<div class="checkbox" v-if="entry.isPrivate">
                <label>
                    <input
                        type="checkbox"
                        v-model="publishAccess"
                        :disabled="!entry.isPrivate"
                        aria-describedby="publish-access-help-block"
                    > Publish Access
                </label>
            </div>-->

			<div
				class="custom-control custom-checkbox mb-3"
				v-if="entry.isPrivate"
			>
				<input
					type="checkbox"
					name="publishAccess"
					id="publishAccess"
					class="custom-control-input"
					value="true"
					v-model="publishAccess"
					:disabled="!entry.isPrivate"
					aria-describedby="publish-access-help-block"
				>
				<label
					class="custom-control-label"
					for="publishAccess"
				>
					Publish Access
				</label>
			</div>

			<div
				v-if="entry.isPrivate"
				id="publish-access-help-block"
				class="form-text text-muted"
			>
				All collaborators have <strong>read</strong> access by default.
			</div>
			<div
				v-else
				id="publish-access-help-block"
				class="form-text text-muted"
			>
				Collaborators on <strong>public</strong> packages always have publish access by default.
			</div>

			<div class="form-group mt-4">
				<button
					type="submit"
					class="btn btn-primary"
					:disabled="loading || newCollaborator == null"
				>
					<i
						class="fa fa-spinner fa-spin"
						v-if="loading"
					/>
					Add Collaborator
				</button>
			</div>

			<!-- Alert Box -->
			<Alert
				type="danger"
				:messages="errors"
			/>
		</form>

		<hr>

		<div v-show="pendingCollaborations.length">
			<h3>Pending Collaborators</h3>
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th class="col-xs-3">
							Username
						</th>
						<th class="col-xs-1">
							Publish Access
						</th>
						<th class="col-xs-1" />
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="collaboration in pendingCollaborations"
						:key="collaboration.collaborationID"
					>
						<td>
							<img
								:src="collaboration.user.avatarLink"
								:alt="`${collaboration.user.fname} ${collaboration.user.lname}`"
								class="rounded-circle"
								style="margin-right: 1rem;"
							>
							{{ collaboration.user.username }}
						</td>
						<td style="vertical-align: middle;">
							{{ collaboration.publishAccess | yesOrNo }}
						</td>
						<td class="text-right">
							<form @submit.prevent="remove( collaboration )">
								<button
									type="submit"
									class="btn btn-sm btn-danger"
								>
									<i class="fa fa-trash" /> Revoke Invitation
								</button>
							</form>
						</td>
					</tr>
				</tbody>
			</table>

			<hr>
		</div>

		<h3>Collaborators</h3>
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th class="col-xs-3">
						Username
					</th>
					<th class="col-xs-1">
						Publish Access
					</th>
					<th class="col-xs-1" />
				</tr>
			</thead>
			<tbody>
				<tr v-show="!acceptedCollaborations.length">
					<th
						colspan="3"
						class="text-center"
					>
						No Collaborators
					</th>
				</tr>
				<tr
					v-for="collaboration in acceptedCollaborations"
					:key="collaboration.collaborationID"
				>
					<td>
						<img
							:src="collaboration.user.avatarLink"
							:alt="`${collaboration.user.fname} ${collaboration.user.lname}`"
							class="rounded-circle"
							style="margin-right: 1rem;"
						>
						{{ collaboration.user.username }}
					</td>
					<td style="vertical-align: middle;">
						{{ collaboration.publishAccess | yesOrNo }}
					</td>
					<td class="text-right">
						<form @submit.prevent="remove( collaboration )">
							<button
								type="submit"
								class="btn btn-sm btn-danger"
							>
								<i class="fa fa-trash" /> Remove Collaborator
							</button>
						</form>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	props    		: [ "entry" ],
	components	: { Multiselect },
	data() {
		return {
			errors          : [],
			collaborations  : this.entry.collaborations,
			users           : [],
			newCollaborator : null,
			publishAccess   : !this.entry.isPrivate,
			loading         : false
		};
	},
	filters : {
		yesOrNo( value ){
			return value && value !== "false" ? "Yes" : "No";
		}
	},
	computed : {
		pendingCollaborations(){
			if ( this.collaborations == "" ) {
				return [];
			}
			else {
				return this.collaborations.filter( c => !c.accepted );
			}
		},
		acceptedCollaborations(){
			if ( this.collaborations == "" ) {
				return [];
			}
			return this.collaborations.filter( c => c.accepted );
		}
	},
	methods : {
		add() {
			this.loading 	= true;
			this.errors	= [];
			$.ajax( {
				url    : "/api/v1/collaborations",
				method : "post",
				data   : {
					slug          : this.entry.slug,
					username      : this.newCollaborator.username,
					publishAccess : this.publishAccess
				}
			} )
				.then( ( response, status ) => {
					this.loading = false;
					this.collaborations.push( response.data );
					this.newCollaborator = null;
					// Notify
					this.$notify( {
						title	: "Invitation Sent!",
						type 	: "success",
						text 	: "The invitation has been sent for collaboration"
					} );
				} )
				.catch( err => {
					this.errors 		= err.responseJSON.messages;
					this.loading 	= false;
				} );
		},
		findUsers( query ){
			this.loading = true;
			$.ajax( {
				url 	: "/api/v1/users",
				data	: { searchTerm: query }
			} )
				.then( ( response, status ) => {
					this.users = response.data.results;
					this.loading = false;
				} )
				.catch( err => {
					this.loading = false;
					this.errors = err.responseJSON.messages;
				} );
		},
		remove( { collaborationID } ){
			this.errors = [];
			$.ajax( {
				url   	: `/api/v1/collaborations/${collaborationID}`,
				method	: "delete"
			} )
				.then( ( response, status ) => {
					// Remove from list
					this.collaborations = this.collaborations.filter(
						c => c.collaborationID !== collaborationID
					);
				} )
				.catch( err => {
					this.error = err.responseJSON.messages;
					console.error( err );
				} );
		},
		userLabel( { username, fname, lname } ){
			return `${username} (${fname} ${lname})`;
		}
	}
};
</script>
