<template>
	<form
		class="tw-flex-1 tw-flex tw-justify-center lg:tw-justify-end"
		id="searchForm"
		name="searchForm"
		role="search"
		action="/"
		method="get"
	>
		<div class="tw-w-full tw-px-2 lg:tw-px-6">
			<label
				for="search"
				class="tw-sr-only"
			>
				Search Packages
			</label>
			<div class="tw-relative tw-text-blue-200 focus-within:tw-text-gray-400">
				<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
					<!-- Heroicon name: solid/search -->
					<svg
						class="tw-h-5 tw-w-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
				<input
					v-model="search"
					id="search"
					name="search"
					class="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-transparent tw-rounded-full tw-leading-5 tw-bg-gray-100 tw-bg-opacity-25 tw-text-blue-100 tw-placeholder-blue-200 focus:tw-outline-none focus:tw-bg-white focus:tw-ring-0 focus:tw-placeholder-gray-400 focus:tw-text-gray-900 sm:tw-text-sm tw-text-center"
					placeholder="Search Packages (i.e. coldbox, arrays, contentbox...)"
					type="search"
				>
				<!-- Heroicon name: x -->
				<div
					v-if="search != ''"
					class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
				>
					<a
						href="/"
						title="Reset Search"
						class="hover:tw-text-white"
					>
						<svg
							class="tw-h-5 tw-w-5"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</a>
				</div>
			</div>
		</div>
	</form>
</template>
<script>
export default {
	props : { searchText: { required: false, default: "",type: String } },
	data() {
		return { search: this.searchText };
	}
};
</script>