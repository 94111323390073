<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Add Members
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Add members that belong to the organization to this team.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<div class="tw-px-4 tw-py-6">
								<span class="tw-text-sm tw-text-gray-900 tw-font-medium">Find Members</span>

								<multiselect
									v-model="membersToAdd"
									class="tw-mt-3"
									placeholder="Search for a member"
									open-direction="bottom"
									:options="filteredMembers"
									track-by="username"
									label="username"
									:multiple="true"
									:allow-empty="true"
									:internal-search="false"
									:loading="loading"
									@input="errors = []"
								>
									<!-- <template
										slot="search"
										slot-scope="props"
									>
										{{ props.user.username }}
									</template> -->
									<template
										slot="option"
										slot-scope="{ option: { user } }"
									>
										<img
											:src="user.avatarLink"
											:alt="`${user.fullName}`"
											class="tw-rounded-full tw-mr-3 tw-inline-block"
										>
										{{ user.username }} ({{ user.fullName }})
									</template>
								</multiselect>
							</div>

							<div class="tw-col-span-6 tw-mx-3 tw-mb-3 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
									class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
								><path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								/></svg>
								<span>
									You can only add members that are confirmed. Pending members cannot be added to any team until they accept the invite. Note that members that are already in the team won't show up in the dropdown.
								</span>
							</div>

							<div class="tw-px-4 tw-3">
								<ul class="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto users-to-invite-placeholder">
									<li
										v-for="(thisMember) in membersToAdd"
										:key="thisMember.user.username"
										class="tw-py-2 tw-flex-1"
									>
										<div>
											<div
												v-if="thisMember.user.avatarLink != ''"
												class="tw-flex tw-w-full"
											>
												<!-- <span class="tw-text-sm tw-text-gray-500">
													<img
														class="tw-h-5 tw-w-5 tw-rounded-full tw-inline-block"
														:src="member.user.avatarLink"
														alt=""
													>
													{{ member.user.fullName }} <small>({{ member.user.username }})</small>
												</span> -->

												<div
													class="tw-group tw-p-2 tw-w-full tw-flex tw-items-center tw-justify-between tw-space-x-3 tw-text-left"
												>
													<span class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center tw-space-x-3">
														<span class="tw-block tw-flex-shrink-0">
															<img
																class="tw-h-10 tw-w-10 tw-rounded-full"
																:src="thisMember.user.avatarLink"
																alt=""
															>
														</span>
														<span class="tw-block tw-min-w-0 tw-flex-1">
															<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">{{ thisMember.user.fullName }}</span>
															<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">@{{ thisMember.user.username }}</span>
														</span>
													</span>

													<span class="tw-h-10 tw-w-10">
														<svg
															class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-opacity-75 tw-cursor-pointer"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															fill="currentColor"
															aria-hidden="true"
															@click="removeMember(thisMember)"
															v-tooltip="'Remove Member'"
														>
															<path
																fill-rule="evenodd"
																d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
																clip-rule="evenodd"
															/>
														</svg>
													</span>
												</div>
											</div>
										</div>
									</li>
								</ul>

								<!-- <div
									class="tw-group tw-p-2 tw-w-full tw-flex tw-items-center tw-justify-between tw-space-x-3 tw-text-left"
								>
									<span class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center tw-space-x-3">
										<span class="tw-block tw-flex-shrink-0">
											<img
												class="tw-h-10 tw-w-10 tw-rounded-full"
												:src="newCollaborator.avatarLink"
												alt=""
											>
										</span>
										<span class="tw-block tw-min-w-0 tw-flex-1">
											<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">{{ newCollaborator.fullName }}</span>
											<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">{{ newCollaborator.username }}</span>
										</span>
									</span>
									<span class="tw-flex-shrink-0 tw-h-10 tw-w-10 tw-inline-flex tw-items-center tw-justify-center">
										<svg
											class="tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block hover:tw-opacity-75 tw-cursor-pointer"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
											@click="removeMember"
											v-tooltip="'Remove User'"
										>
											<path
												fill-rule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clip-rule="evenodd"
											/>
										</svg>
									</span>
								</div> -->
							</div>

							<!-- Alert Box -->
							<Alert
								type="error"
								:messages="errors"
								class="m-3"
							/>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									label="Add to Team"
									:loading="loading"
									:method="addMembers"
									:disabled="loading || membersToAdd.length == 0"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		team : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : { Multiselect },
	data() {
		return {
			showPanel    : false,
			membersToAdd : [],
			errors       : [],
			loading      : false
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {
		filteredMembers() {
			var app = this;
			return this.organization.members.reduce( ( a, member ) => {
				member["username"] = member.user.username;
				member["fullName"] = member.user.fullName;
				member["avatarLink"] = member.user.avatarLink;
				if ( member.isAccepted && !app.memberBelongsToTeam( member ) ) {
					a.push( member );
				}
				return a;
			}, [] );
		}
	},
	methods : {
		addMembers() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.post(
				`/orgs/${app.organization.slug}/teams/members/add`,
				{
					members : app.membersToAdd,
					teamId  : app.team.teamId
				}
			)
				.then( ( response ) => {
					if ( !response.data.error ){
						app.loading = false;

						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "The team has been updated successfully!",
							show    : true
						};
						app.updateUI();
						app.updateFlag();
					} else {
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.response.data.messages[0] );
					app.loading = false;
				} );
		},
		memberBelongsToTeam( member ) {
			var app = this;
			var belongToTeam = false;
			app.team.members.forEach( function( teamMember, index, arr ){
				if ( teamMember.orgMember.user.username == member.user.username ) {
					belongToTeam = true;
					return true;
				}
			} );
			return belongToTeam;
		},
		removeMember( thisMember ) {
			var app = this;
			app.membersToAdd = app.membersToAdd.filter( member => member.user.username != thisMember.user.username );
		},
		updateUI() {
			var app = this;
			for ( var member of this.membersToAdd ) {
				var teamMember = {
					orgMember   : member,
					createdDate : new Date(),
					isActive    : true
				};
				app.$parent.teamMembers.push( teamMember );
			}
			this.updateFlag();
		},
		updateFlag : function() {
			this.showPanel = false;
			this.membersToAdd = [];
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>