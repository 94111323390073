<template>
	<!-- <div class="tw-sticky tw-z-20 tw-bg-white"> -->
	<div>
		<!-- Page title / Breadcrumb & actions -->
		<breadcrumb
			:menu="menu"
		>
			<template v-slot:buttons>
				<div
					class="tw-mt-4 tw-flex"
				>
					<span v-if="secondaryButton.url != ''">
						<a :href="secondaryButton.url">
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0 tw-mr-3"
							>
								Cancel
							</button>
						</a>
					</span>
					<span v-if="!executeMethod && primaryButton.url != '' && primaryButton.url != ''">
						<a :href="primaryButton.url">
							<button
								:type="primaryButton.type"
								class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700 sm:tw-order-1 sm:tw-ml-3"
							>
								{{ primaryButton.label }}
							</button>
						</a>
					</span>
					<span v-else-if="executeMethod">
						<action-button
							:label="primaryButton.label"
							:loading="loading"
							:method="method"
							:disabled="disabled"
						/>
					</span>
					<span v-else-if="primaryButton.label != ''">
						<button
							:type="primaryButton.type"
							class="tw-order-0 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700 sm:tw-order-1 sm:tw-ml-3"
						>
							{{ primaryButton.label }}
						</button>
					</span>
				</div>
			</template>
		</breadcrumb>
	</div>
</template>

<script>

export default {
	props : {
		primaryButton : {
			type    : Object,
			default : function() {
				return {
					label : "",
					type  : "button",
					url   : ""
				};
			}
		},
		secondaryButton : {
			type    : Object,
			default : function() {
				return {
					label : "",
					type  : "button",
					url   : ""
				};
			}
		},
		executeMethod : {
			type    : Boolean,
			default : false
		},
		loading : {
			type    : Boolean,
			default : false
		},
		disabled : {
			type    : Boolean,
			default : false
		},
		method : {
			type    : Function,
			default : () => {}
		},
		menu : {
			type    : Array,
			default : function() {
				return [
					{
						label : "Dashboard",
						url   : ""
					}
				];
			}
		}
	},
	mounted() {
	}
};
</script>