<template>
	<div id="forgebox-modernize">
		<section id="editions">
			<div class="main-content">
				<p
					class="tw-block tw-text-4xl tw-font-extrabold tw-mb-1"
					:class="isAboutPage ? 'tw-text-white' : 'tw-text-blue-600'"
				>
					Plans
				</p>
				<div class="row mx-0">
					<div class="col-md-12">
						<div class="card-community">
							<div class="text-center card-title">
								COMMUNITY
							</div>

							<div class="card-content">
								<div class="card-description">
									<h3>FREE</h3>
									<p class="forever">
										Forever
									</p>
									<p>The free edition of FORGEBOX that helps foment the ColdFusion (CFML) community.</p>
								</div>

								<div class="card-detail text-center">
									<div class="card-list">
										<p><i class="fas fa-check-circle" /> Unlimited public packages</p>
										<p><i class="fas fa-check-circle" /> Unlimited collaborators on public packages</p>
										<p><i class="fas fa-check-circle" /> 1 private package</p>
										<p><i class="fas fa-check-circle" /> Up to 3 package subscriptions</p>
										<p><i class="fas fa-check-circle" /> 10GB of storage for your packages</p>
										<p><i class="fas fa-check-circle" /> 7 days of activity log</p>
										<p><i class="fas fa-check-circle" /> Community support</p>
										<p><i class="fas fa-check-circle" /> Collaborators public community support </p>
									</div>

									<a
										v-if="plan == ''"
										href="/signup"
										class="tw-inline-block tw-my-3 tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									>
										JOIN FOR FREE
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tw-mx-0 tw-mb-4 tw-mt-3">
					<div class="col-md-4">
						<div
							class="card pro"
							:class="plan == 'pro' ? 'current-plan-border' : ''"
						>
							<div
								class="card-title"
								:class="plan == 'pro' ? 'current-plan' : ''"
							>
								<i
									v-if="plan == 'pro'"
									class="fa fa-star mr-2"
									data-toggle="tooltip"
									data-original-title="Your Current Plan"
								/>PRO
								<span
									v-if="plan == 'pro'"
									class="current-plan"
								>
									(current)
								</span>
							</div>

							<div class="card-body">
								<p class="description">
									Directed to the software professional or small  business in need of many packages and collaborations.
								</p>

								<img
									src="/includes/images/plans/Pro.png"
									alt="forgebox"
									class="tw-mx-auto"
								>
								<p class="price">
									$4.99
								</p>
								<label class="interval">month</label>
								<a
									v-if="plan == 'pro'"
									class="tw-inline-block tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									href="/myaccount/settings/billing"
								>
									VIEW YOUR PLAN
								</a>

								<a
									v-else
									class="tw-inline-block tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									href="/subscribe/pro"
								>
									SUBSCRIBE NOW
								</a>

								<div class="features">
									<p><i class="fas fa-check-circle" /> Unlimited private packages </p>
									<p><i class="fas fa-check-circle" /> 10 collaborators on private packages</p>
									<p><i class="fas fa-check-circle" /> Unlimited package subscriptions</p>
									<p><i class="fas fa-check-circle" /> Private package based permissions</p>
									<p><i class="fas fa-check-circle" /> 90 days of activity log</p>
									<p><i class="fas fa-check-circle" /> 50GB of storage for all packages</p>
									<p><i class="fas fa-check-circle" /> Basic support (Email + Slack)</p>
								</div>
							</div>

							<div class="card-footer">
								<a
									href="/about#plan-comparison"
									class="hover:tw-text-blue-500"
									:target="!isAboutPage ? '_blank' : ''"
									rel="noopener"
								>
									Compare Plans
								</a>
							</div>
						</div>
					</div>

					<div class="col-md-4">
						<div class="card business">
							<div class="card-title">
								BUSINESS
							</div>

							<div class="card-body">
								<p class="description">
									Directed towards organizations and teams so they can collaborate and support their software building needs.
								</p>

								<img
									src="/includes/images/plans/Business.png"
									alt="forgebox"
									class="tw-mx-auto"
								>
								<p class="price">
									$6.99
								</p>
								<label class="interval">month/user</label>
								<a
									class="tw-inline-block tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									href="/myaccount/organizations"
								>
									START NOW
								</a>

								<div class="features">
									<p><i class="fas fa-check-circle" /> Unlimited collaborators</p>
									<p><i class="fas fa-check-circle" /> Unlimited organizations and teams</p>
									<p><i class="fas fa-check-circle" /> Role based security</p>
									<p><i class="fas fa-check-circle" /> Unlimited public/private packages</p>
									<p><i class="fas fa-check-circle" /> Team-based permissions</p>
									<p><i class="fas fa-check-circle" /> 180 days of activity log</p>
									<p><i class="fas fa-check-circle" /> 100GB of storage for all packages</p>
									<p><i class="fas fa-check-circle" /> Premium support (Slack + HelpDesk)</p>
								</div>
							</div>

							<div class="card-footer">
								<a
									href="/about#plan-comparison"
									class="hover:tw-text-blue-500"
									:target="!isAboutPage ? '_blank' : ''"
									rel="noopener"
								>
									Compare Plans
								</a>
							</div>
						</div>
					</div>

					<div class="col-md-4">
						<div class="card enterprise">
							<div class="card-title">
								ENTERPRISE
							</div>

							<div class="card-body">
								<p class="description">
									Directed towards organizations that want a private registry with flexibility in mind.
								</p>

								<img
									src="/includes/images/plans/Enterprise.png"
									alt="forgebox"
									class="tw-mx-auto"
								>
								<p class="price">
									$199
								</p>
								<label class="interval">month</label>
								<a
									class="tw-inline-block tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
									href="https://www.ortussolutions.com/contact?subject=FORGEBOX%20ENTERPRISE"
								>CONTACT SALES</a>

								<div class="features">
									<p><i class="fas fa-check-circle" /> Unlimited users</p>
									<p><i class="fas fa-check-circle" /> Custom CommandBox Endpoint</p>
									<p><i class="fas fa-check-circle" /> Custom Security Roles & Permissions</p>
									<p><i class="fas fa-check-circle" /> Package type management</p>
									<p><i class="fas fa-check-circle" /> Up to 250GB of storage included</p>
									<p><i class="fas fa-check-circle" /> Artifact CDN</p>
									<p><i class="fas fa-check-circle" /> Enterprise Support & Mentorship</p>
									<p><i class="fas fa-check-circle" /> Containerized Environment</p>
									<p><i class="fas fa-check-circle" /> One time setup fee of $199</p>
								</div>
							</div>

							<div class="card-footer">
								<a
									href="/about#plan-comparison"
									class="hover:tw-text-blue-500"
									:target="!isAboutPage ? '_blank' : ''"
									rel="noopener"
								>
									Compare Plans
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

export default {
	props : {
		plan : {
			type     : String,
			required : true
		},
		isAboutPage : {
			type     : Boolean,
			required : false,
			default  : false
		}
	}
};

</script>

<style scoped lang="scss">
	#forgebox-modernize {

		section {
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.container {
			margin-right: 0px !important;
			margin-left: 0px !important;
		}

		.main-content {
			margin-right: 0px !important;
			margin-left: 0px !important;
			margin: auto !important;
			padding-top: 10px;
			padding-bottom: 10px;
			max-width: 1340px;
		}

    .current-plan {
		color: rgb(227, 160, 8) !important;
		background-color: rgb(23, 43, 77) !important;
    }

    .current-plan-border {
		border: 4px solid rgb(227, 160, 8) !important;
    }

	#editions {
		width: 100%;
		height: auto;

	p {
		line-height: 1.75 !important;
		margin-left: 10px !important;
	}

	.col-md-3, .col-md-12 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.card-community {
		border: 4px solid #172b4d;
		border-radius: 8px;
		margin-right: 5px;
		margin-left: 5px;
		margin-top: 15px;
		margin-bottom: 15px;
		background-color: #fff;
	}

	.card {
		margin-right: 5px;
		margin-left: 5px;
		border: 4px solid #172b4d;
		border-radius: 8px;
		background-color: #fff;
		height: 725px;
		position: relative;
	}

		.card-content {
			width: 100%;
			display: flex;
		}

		.card-description {
			width: 35%;
			text-align: center;
		}

		.card-detail {
			width: 65%;
			position: relative;
			padding-top: 2%;
			text-align: left;
		}

		.card-list {
			text-align: left !important;
			column-count: 3;
		}

		.btn-join {
			position: absolute;
			bottom: 0;
			left: 37%;
		}

		.forever {
			font-size: 25px;
		}

		.description {
			padding: 10px;
			line-height: 1.5;
			font-size: 15px;
			min-height: 115px;
			margin-bottom: 0px;
		}

		.fas {
			color: #8fc73e;
		}

		.card-title {
			background-color: #172b4d;
			text-align: center;
			color: #fff;
			font-weight: bold;
			padding-top: 10px;
			padding-bottom: 10px;
			margin-bottom: 0px !important;
		}

		.card-body {
			padding: 5px;
			text-align: center;
		}

		.btn-secondary {
			background-color: #838383;
			color: #fff;
			border: none;
		}

		.price {
			color: #003f53;
			font-weight: bold;
			font-size: 45px;
			margin-bottom: 0px;
			line-height: 1.3 !important;
		}

		.interval {
			display: block;
		}

		.btn-margin {
			margin-top: 10%;
			margin-bottom: 15%;
		}

		.card-footer {
			color: rgb(54, 155, 194);
			border-top: 1px solid silver;
			text-align: center;
			font-weight: 500;
			padding: 2%;
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		.features {
			text-align: left;
			margin-top: 10px;
		}

		h3 {
			font-size: 45px;
			color: #003f53;
			margin-bottom: 0;
		}

		span {
			color: white;
			font-weight: bold;
		}

		img {
			height: 120px;
		}

		label {
			color: #757575;
			margin-top: 0px;
			font-weight: 0;
		}

			// *** MEDIA QUERY ***
			@media only screen and (max-width: 768px) {
        .col-md-3, .col-md-12 {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }

				.btn {
          padding: 0.5rem 1rem !important;
          font-size: 12px !important;
        }

				.card-description, .card-detail {
					width: 100%;
				}

        .description {
          min-height: 139px !important;
          padding: 3px !important;
        }

        img {
          height: 100px !important;
        }

				.card-content {
					display: flow-root;
					padding: 10px;
				}

				.card-list {
					column-count: 1;
					text-align: left;
				}

				.btn-join {
					position: inherit;
					left: 0;
				}

				.card {
					height: 100% !important;
					margin-bottom: 10px;
				}

				.card-body {
					margin-bottom: 15px;
				}
			}

      // *** MEDIA QUERY ***
			@media only screen and (max-width: 600px) {
        .description {
          min-height: 80px !important;
          padding: 3px !important;
        }
      }
		}
	}
</style>
