<template>
	<tr>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<div class="tw-text-sm tw-font-medium tw-text-gray-900">
						<a
							:href="`/view/${subscription.slug}`"
							class="tw-text-gray-700 hover:tw-text-gray-900"
						>
							{{ subscription.title }}
						</a>
					</div>
					<div class="tw-text-sm tw-text-gray-500">
						{{ subscription.slug }}
					</div>
				</div>
			</div>
		</td>

		<td class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span class="tw-inline-flex tw-items-center tw-text-xs tw-leading-5 tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded-full tw-bg-blue-300 tw-text-gray-800">
				{{ subscription.numberOfVersions }}
			</span>
		</td>

		<td class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span class="tw-inline-flex tw-items-center tw-text-xs tw-leading-5 tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded-full tw-bg-blue-300 tw-text-gray-800">
				{{ subscription.latestVersionNumber }}
			</span>
		</td>

		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(subscription.latestVersionUpdatedDate)"
		>
			{{ subscription.latestVersionUpdatedDate | formatDate }}
		</td>

		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-52 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<span
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRemovalDialog"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M4 8c0-.26.017-.517.049-.77l7.722 7.723a33.56 33.56 0 01-3.722-.01 2 2 0 003.862.15l1.134 1.134a3.5 3.5 0 01-6.53-1.409 32.91 32.91 0 01-3.257-.508.75.75 0 01-.515-1.076A11.448 11.448 0 004 8zM17.266 13.9a.756.756 0 01-.068.116L6.389 3.207A6 6 0 0116 8c.001 1.887.455 3.665 1.258 5.234a.75.75 0 01.01.666zM3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06L3.28 2.22z" />

								</svg>
								Unsubscribe
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		subscription : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			showDropdown : false,
			loading      : false,
			errors       : []
		};
	},
	directives : { ClickOutside },
	methods    : {
		openRemovalDialog() {
			var app = this;
			app.loading = true;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to unsubscribe from this package. This action cannot be undone.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.loading = true;
					app.$http.post(
						`/myaccount/packages/${app.subscription.slug}/subscribe/email/${false}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.updateUI( app.subscription.slug );
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.loading = false;
								// show error message
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} )
						.catch( error => {
							console.log( error.response );
							app.loading = false;
						} );
				}
			} );
		},
		updateUI( packageSlug ) {
			var app = this;
			console.log( packageSlug );
			app.$parent.packageSubscriptions = app.$parent.packageSubscriptions.filter(
				p => p.slug !== packageSlug
			);
		}
	}
};
</script>
