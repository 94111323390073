<template>
	<div>
		<page-header
			:menu="menu"
			:primary-button="{ label: 'Save', type: 'button' }"
			:secondary-button="{ label: 'Cancel', type: 'button', url: !isOrg ? '/myaccount/packages' : `/orgs/${organization.slug}/packages` }"
			:execute-method="true"
			:loading="loading"
			:disabled="loading"
			:method="createPackage"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<!-- Alert Box -->
			<div class="tw-mb-3">
				<Alert
					class="tw-mt-3"
					type="error"
					:messages="errors"
				/>
			</div>
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						General Information
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						Enter a title, type and a brief summary for your new package.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<div class="tw-grid tw-grid-cols-6 tw-gap-6">
						<div class="tw-col-span-6">
							<label
								for="title"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Title
								<span class="tw-text-red-500">*</span>
							</label>
							<input
								v-model="packageForm.title"
								type="text"
								name="title"
								id="title"
								required="true"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div class="tw-col-span-6">
							<label
								for="entryType"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Type
								<span class="tw-text-red-500">*</span>
							</label>
							<select
								v-model="packageForm.entryType"
								id="entryType"
								name="entryType"
								class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
								<option
									v-for="type in packageTypes"
									:value="type.typeID"
									:key="type.typeID"
								>
									{{ type.typeName }}
								</option>
							</select>
						</div>

						<div class="tw-col-span-6">
							<label
								for="summary"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Summary
								<span class="tw-text-red-500">*</span>
							</label>
							<textarea
								v-model="packageForm.summary"
								id="summary"
								name="summary"
								rows="3"
								required="required"
								placeholder="My package rocks!!!"
								class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							/>
						</div>
						<div
							class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500"
						>
							<a
								href="https://commandbox.ortusbooks.com/package-management/creating-packages"
								rel="noopener"
								target="_blank"
							>
								<svg
									class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-opacity-75"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</a>
							<span>
								Fill out the following form to create a new package in FORGEBOX.
								Please note that you will need to create a version once your package has been created in order to publish it.
								You can avoid all these manual hassles if you like and just use CommandBox's <span class="tw-text-red-500">publish</span> command.
							</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Divider -->
			<divider />

			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Package {{ hasPrivatePackages ? 'Visibility & ' : '' }} Slug
					</h3>
					<p
						v-if="!isOrg"
						class="tw-mt-1 tw-text-sm tw-text-gray-500"
					>
						<span v-if="hasPrivatePackages">
							You can create unlimited public and private packages with your current plan.
						</span>
						<span v-else>
							You can create unlimited public packages with your current plan.
							We are offering one free private package for you to try out, If you need to create unlimited private packages you will need to subscribe to a paid plan.
							<br><br>
							<a
								href="/plans"
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-mt-3"
							>
								Check out our plans now!
							</a>
						</span>
					</p>
					<!-- Org view -->
					<p
						v-else
						class="tw-mt-1 tw-text-sm tw-text-gray-500"
					>
						<span v-if="!hasPrivatePackages">
							You can create unlimited public packages on this organization.
						</span>
						<span v-else>
							You can create unlimited public and private packages on this organization.
						</span>

						<br><br>

						<span v-if="member.isOwner && !hasPrivatePackages">
							If you need to create private packages, you need to
							<a
								:href="`/orgs/${organization.slug}/settings/billing`"
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-mt-3"
							>
								invite members
							</a>
							to join the organization or
							<a
								href="/subscribe/pro"
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-mt-3"
							>
								subscribe to the Pro plan
							</a>
							in order to activate the private packages feature.
						</span>
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<div class="tw-flex tw-gap-6">
						<div class="tw-flex-1">
							<label
								for="slug"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Slug
								<span class="tw-text-red-500">*</span>
							</label>
							<input
								v-model="packageForm.packageSlug"
								type="text"
								name="slug"
								id="slug"
								required="true"
								@input="debounceSlugCheck"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								:class="{ 'tw-pr-10 tw-border-red-300 tw-text-red-900 tw-placeholder-red-300 focus:tw-outline-none focus:tw-ring-red-500 focus:tw-border-red-500' : !validSlug, 'tw-pr-10 tw-border-green-500 focus:tw-outline-none focus:tw-ring-green-700 focus:tw-border-green-900' : validSlug && packageForm.packageSlug != '' }"
							>
						</div>
						<div
							v-if="hasPrivatePackages || canPublishFreePrivatePackage"
							class="tw-flex-1"
						>
							<label
								for="visibility"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Private Package?
							</label>
							<div
								class="tw-mt-2 tw-flex tw-items-center tw-justify-between"
							>
								<div class="tw-flex tw-flex-col">
									<p
										v-if="!isOrg"
										class="tw-text-sm tw-text-gray-500"
										id="privatePackage"
									>
										If set to private, your slug will have your username appended upon saving:
										<span class="tw-text-red-500">
											{slug}@{{ user.username }}
										</span>.
									</p>
									<p
										v-else
										class="tw-text-sm tw-text-gray-500"
										id="privatePackage"
									>
										If set to private, the package slug will have the organization slug appended upon saving:
										<span class="tw-text-red-500">
											{slug}@{{ organization.slug }}
										</span>.
									</p>
								</div>
								<button
									type="button"
									class="tw-ml-4 tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2"
									:class="{ 'tw-bg-blue-500 focus:tw-ring-blue-500': packageForm.isPrivate, 'tw-bg-gray-200 focus:tw-ring-gray-300': !(packageForm.isPrivate) }"
									@click="packageForm.isPrivate = !packageForm.isPrivate"
								>
									<span
										name="isPrivate"
										type="hidden"
										class="tw-sr-only"
										:value="packageForm.isPrivate"
									>
										Private Package
									</span>
									<span
										aria-hidden="true"
										class="tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200"
										:class="{ 'tw-translate-x-5': packageForm.isPrivate, 'tw-translate-x-0': !(packageForm.isPrivate) }"
									/>
								</button>
							</div>
						</div>
					</div>

					<div
						v-if="!isOrg && !hasPrivatePackages"
						class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500 tw-mt-3"
					>
						<svg
							class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke="currentColor"
							fill="none"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						<span
							v-if="canPublishFreePrivatePackage"
						>
							You can create your first private package for free. If you need to create more private packages or need more storage subscribe to a paid plan.
							<a
								href="/plans"
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-mt-3"
							>
								Compare plans
							</a>
						</span>
						<span v-else>
							Subscribe to a paid plan if you need to create unlimited private packages or need more storage.
							<a
								href="/plans"
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm tw-mt-3"
							>
								Compare plans
							</a>
						</span>
					</div>
				</div>
			</div>

			<!-- Divider -->
			<divider />

			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Package Links
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						You can link your packages to the official website, repo, docs etc.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<div class="tw-grid tw-grid-cols-6 tw-gap-6">
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="homeURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Home URL
							</label>
							<input
								v-model="packageForm.homeURL"
								type="text"
								name="homeURL"
								id="homeURL"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="https://"
							>
						</div>
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="sourceCodeURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Source Code URL
							</label>
							<input
								v-model="packageForm.sourceURL"
								type="text"
								name="sourceCodeURL"
								id="sourceCodeURL"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="https://"
							>
						</div>
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="documentationURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Documentation URL
							</label>
							<input
								v-model="packageForm.documentationURL"
								type="text"
								name="documentationURL"
								id="documentationURL"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="https://"
							>
						</div>
						<div class="tw-col-span-6 sm:tw-col-span-3">
							<label
								for="bugsURL"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Bugs URL
							</label>
							<input
								v-model="packageForm.bugsURL"
								type="text"
								name="bugsURL"
								id="bugsURL"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="https://"
							>
						</div>
					</div>
				</div>
			</div>

			<!-- Divider -->
			<divider v-if="false">
				<div
					v-if="false"
					class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6"
				>
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Package First Version
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							This section is optional but is highly recommended that you create the first version of your package that way you can publish it.
						</p>
					</div>
					<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
						<div class="tw-grid tw-grid-cols-6 tw-gap-6">
							<div class="tw-col-span-6">
								<div
									v-if="!createFirstVersion"
									class="tw-text-center"
								>
									<action-button
										label="Create First Version"
										:loading="loading"
										:method="openVersionForm"
										:disabled="!isValidForm || loading"
									/>
								</div>
								<div>
									<transition
										enter-class="tw-opacity-0 tw-transform tw-scale-95"
										enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
										enter-active-class="tw-transition ease-out duration-200"
										leave-class="tw-opacity-100 tw-transform tw-scale-100"
										leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
										leave-active-class="tw-transition tw-ease-in tw-duration-75"
									>
										<div v-if="createFirstVersion">
											<new-version
												:this-package="packageForm"
												:is-embedded="true"
											/>
										</div>
									</transition>
								</div>
							</div>
						</div>
					</div>
				</div>
			</divider>
		</div>
	</div>
</template>

<script>
import NewVersion from "./Versions/VersionForm";
import debounce from "lodash/debounce";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		member : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		packageTypes : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		hasPrivatePackages : {
			type     : Boolean,
			required : true,
			default  : false
		},
		canPublishFreePrivatePackage : {
			type     : Boolean,
			required : false,
			default  : false
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { NewVersion },
	data() {
		return {
			menu : [
				{
					label : "Packages",
					url   : !this.isOrg ? "/myaccount/packages" : `/orgs/${this.organization.slug}/packages`
				},
				{ label: "New" }
			],
			packageForm : {
				title            : "",
				entryType        : "",
				summary          : "",
				packageSlug      : "",
				isPrivate        : false,
				homeURL          : "",
				sourceURL        : "",
				documentationURL : "",
				bugsURL          : ""
			},
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			},
			createFirstVersion : false,
			validSlug          : true,
			loading            : false,
			errors             : []
		};
	},
	computed : {
		isValidForm() {
			return this.packageForm.title != "" &&
				this.packageForm.entryType != "" &&
				this.packageForm.summary != "" &&
				this.packageForm.packageSlug != "" &&
				this.validSlug;
		}
	},
	mounted(){
	},
	methods : {
		createPackage() {
			var app = this;
			app.errors = [];
			app.loading = true;
			app.notification.show = false;
			var url = !app.isOrg ? "/myaccount/packages/create" :`/orgs/${this.organization.slug}/packages/create`;
			app.$http.post(
				url, app.packageForm
			)
				.then( ( response ) => {
					if ( !response.data.error ) {
						// app.loading = false;
						app.notification = {
							type    : "success",
							title   : "Success",
							message : "Package Created Succesfully!",
							show    : true
						};
						var privateSlug = "";
						if ( app.packageForm.isPrivate ) {
							privateSlug = !app.isOrg ? "@" + app.user.username : "@" + app.organization.slug;
						}
						// relocate to the package manager page
						var relocationURL = !app.isOrg ? `/myaccount/packages/manage/${app.packageForm.packageSlug}${privateSlug}` :`/orgs/${app.organization.slug}/packages/manage/${app.packageForm.packageSlug}${privateSlug}`;
						window.location = relocationURL;
					} else {
						app.loading = false;
						app.errors = response.data.messages;
					}
				} )
				.catch( error => {
					app.loading = false;
					app.errors.push( error.response.data.messages[0] );
				} );
		},
		debounceSlugCheck : debounce( function() {
			this.slugCheck();
		}, 500 ),
		slugCheck() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.validSlug = true;
			if ( app.packageForm.packageSlug != "" ) {
				app.$http.get( "/api/v1/slug-check/"+ app.packageForm.packageSlug )
					.then( ( response ) => {
						app.validSlug = response.data.data;
						if ( !response.data.data ) {
							app.errors = response.data.messages;
						}
						app.loading = false;
					} )
					.catch( error => {
						app.loading = false;
						app.validSlug = false;
						app.errors.push( error.toString() );
					} );
			} else {
				app.validSlug = false;
			}
		},
		openVersionForm() {
			var app = this;
			app.createFirstVersion = true;
		}
	}
};
</script>