// Import Shared Filters
import { dateFilter } from "vue-date-fns";
import moment from "moment";
import numeral from "numeral";

// Declare Mixins
const mixins = {

	filters : {
		capitalize : function( value ) {
			if ( !value ) return "";
			value = value.toString();
			return value.charAt( 0 ).toUpperCase() + value.slice( 1 );
		},
		formatDate( date, format="short" ) {
			if ( date == "" ) {
				return "N/A";
			}
			date = new Date( date );
			if ( date ) {
				if ( format == "short" ) {
					return moment( date ).format( "ll" );
				} else {
					return moment( date ).format( "LLL" );
				}
			}
		},
		formatNumber( code ) {
			if ( code && code != 0 ) {
				return numeral( code ).format( "0,0" );
			} else {
				return code;
			}
		},
		yesOrNo( value ){
			return value && value !== "false" ? "Yes" : "No";
		}
	},
	methods : {
		getFullDate( date, format = "long" ){
			if ( date == "" ) {
				return "N/A";
			}
			date = new Date( date );
			if ( date ) {
				if ( format == "short" ) {
					return moment( date ).format( "ll" );
				} else {
					return moment( date ).format( "LLL" );
				}
			} else {
				return date;
			}
		},
		isValidURL( url ) {
			if ( url.toLowerCase() == "forgeboxstorage" ) {
				return true;
			}

			var urlPattern = "(https?|ftp)://(www\\.)?(((([a-zA-Z0-9.-]+\\.){1,}[a-zA-Z]{2,4}|localhost))|((\\d{1,3}\\.){3}(\\d{1,3})))(:(\\d+))?(/([a-zA-Z0-9-._~!$&'()*+,;=:@/]|%[0-9A-F]{2})*)?(\\?([a-zA-Z0-9-._~!$&'()*+,;=:/?@]|%[0-9A-F]{2})*)?(#([a-zA-Z0-9._-]|%[0-9A-F]{2})*)?";
			urlPattern = "^" + urlPattern + "$";
			var regex = new RegExp( urlPattern );

			return regex.test( url );
		},
		isEmptyObject( obj ) {
			return Object.keys( obj ).length === 0;
		}
	}

};

// Export the default as a Vue Plugin and register all filters
export default {
	install( Vue ) {
		Vue.filter( "date", dateFilter );
		Vue.mixin( mixins );
	}
};