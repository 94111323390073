<template>
	<div
		class="text-center m-4 text-secondary"
		v-show="visible"
	>
		<h2><i class="fa fa-spinner fa-spin" /> Loading...</h2>
	</div>
</template>
<script>
export default {
	props : {
		visible : {
			default : false,
			type    : Boolean
		}
	}
};
</script>