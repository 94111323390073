<template>
	<div
		v-click-outside="() => (showDropdown = false)"
	>
		<div>
			<button
				type="button"
				id="options-menu"
				class="tw-align-middle"
				aria-expanded="false"
				aria-haspopup="true"
				@click="showDropdown = !showDropdown"
			>
				<span>
					<Avatar
						:email="user.email"
						:px="40"
						:size="9"
					/>
				</span>
			</button>
		</div>

		<transition
			enter-active-class="tw-transition ease-out duration-200"
			enter-class="tw-opacity-0 tw-transform tw-scale-95"
			enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
			leave-active-class="tw-transition tw-ease-in tw-duration-75"
			leave-class="tw-opacity-100 tw-transform tw-scale-100"
			leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
		>
			<div
				v-if="showDropdown"
				class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-2 tw-top-12 tw-w-64 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<div
					class="tw-py-1"
					role="none"
				>
					<span
						class="tw-block tw-px-4 tw-pt-2 tw-font-semibold tw-text-sm tw-text-gray-700"
					>
						{{ user.fullName }}
					</span>
					<span
						class="tw-block tw-px-4 tw-pb-2 tw-text-sm tw-text-gray-700"
					>
						@{{ user.username }}
					</span>
				</div>

				<div
					class="tw-py-1"
					role="none"
				>
					<a
						href="/myaccount"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						My Account
					</a>

					<a
						href="/myaccount/packages"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						My Packages
					</a>

					<!-- TODO: only if the user has at least 1 org -->
					<a
						href="/myaccount/organizations"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						My Organizations
					</a>

					<a
						:href="'/@'+user.username"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						Public Profile
					</a>

					<a
						href="/myaccount/activityLog"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						Activity Log
					</a>
				</div>

				<div
					class="tw-py-1"
					role="none"
				>
					<a
						href="/myaccount/settings/account"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						Settings
					</a>

					<a
						href="/security/doLogout"
						class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
						role="menuitem"
					>
						Logout
					</a>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
	props : {
		user : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			showDropdown : false,
			showSettings : false
		};
	},
	created(){

	},
	directives : { ClickOutside },
	computed   : {},
	methods    : {}
};
</script>