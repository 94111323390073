<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<edit-team
			:edit-mode="true"
			:team="team"
			:organization="organization"
			:show-slideover="showEditTeam"
			@interface="showEditTeam = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div>
			<header class="tw-bg-gray-100 tw-py-4">
				<div class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 xl:tw-flex xl:tw-items-center xl:tw-justify-between">
					<div class="tw-flex-1 tw-min-w-0">
						<h1 class="tw-mt-2 tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate">
							{{ team.name }}
						</h1>
						<div class="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-8">
							<div class="tw-mt-1 tw-flex tw-items-center tw-text-sm tw-font-bold tw-text-gray-500">
								{{ team.description }}
							</div>
						</div>
					</div>
					<div
						v-if="canManageTeam"
						class="tw-mt-5 tw-flex xl:tw-mt-0 xl:tw-ml-4"
					>
						<span>
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-50 focus:tw-ring-blue-500"
								@click="showEditTeam = true"
							>
								<!-- Heroicon name: solid/pencil -->
								<svg
									class="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
								</svg>
								Edit
							</button>
						</span>
					</div>
				</div>
			</header>

			<div class="tw-pb-8">
				<div class="tw-mx-auto sm:tw-px-6 lg:tw-px-8">
					<div class="tw-px-4">
						<!-- Tabs -->
						<div class="sm:tw-hidden">
							<label
								for="tabs"
								class="tw-sr-only"
							>
								Select a tab
							</label>
							<select
								v-model="currentTab"
								id="tabs"
								name="tabs"
								class="tw-mt-4 tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-rounded-md"
							>
								<option v-if="false">
									Activity Log
								</option>

								<option value="members">
									Members
								</option>

								<option value="packages">
									Packages
								</option>
							</select>
						</div>
						<div class="tw-hidden sm:tw-block">
							<div class="tw-border-b tw-border-gray-200">
								<nav
									class="tw-mt-2 tw--mb-px tw-flex tw-space-x-8"
									aria-label="Tabs"
								>
									<!-- Activity Log goes here -->
									<span
										:class="currentTab == 'members' ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-200'"
										class="tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-cursor-pointer"
										@click="currentTab = 'members'"
									>
										Members
										<span
											:class="currentTab == 'members' ? 'tw-bg-blue-100 tw-text-blue-600' : 'tw-bg-gray-100 tw-text-gray-900'"
											class="tw-hidden tw-ml-2 tw-py-0.5 tw-px-2.5 tw-rounded-full tw-text-xs tw-font-medium md:tw-inline-block"
										>
											{{ team.members.length }}
										</span>
									</span>

									<span
										:class="currentTab == 'packages' ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-200'"
										class="tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-cursor-pointer"
										@click="currentTab = 'packages'"
									>
										Packages
										<span
											:class="currentTab == 'packages' ? 'tw-bg-blue-100 tw-text-blue-600' : 'tw-bg-gray-100 tw-text-gray-900'"
											class="tw-hidden tw-ml-2 tw-py-0.5 tw-px-2.5 tw-rounded-full tw-text-xs tw-font-medium md:tw-inline-block"
										>
											{{ team.entries.length }}
										</span>
									</span>
								</nav>
							</div>
						</div>
					</div>

					<!-- Activity Log Section -->
					<div v-if="false">
						<p>Activity Log Goes Here</p>
					</div>

					<!-- Members -->
					<div v-if="currentTab == 'members'">
						<members
							:member="member"
							:organization="organization"
							:team="team"
						/>
					</div>

					<!-- Packages -->
					<div v-if="currentTab == 'packages'">
						<packages
							:member="member"
							:organization="organization"
							:team="team"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EditTeam from "./Edit";
import Members from "./Members/Index";
import Packages from "./Packages/Index";
import ClickOutside from "vue-click-outside";

export default {
	props : {
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		team : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : { EditTeam, Members, Packages },
	directives : { ClickOutside },
	data() {
		return {
			currentTab        : "members",
			publishedDropdown : false,
			showEditTeam      : false,
			notification      : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	mounted(){
	},
	computed : {
		menu() {
			return [
				{
					label : "Teams",
					url   : "/orgs/"+this.organization.slug+"/teams"
				},
				{ label: this.team.name }
			];
		},
		canManageTeam(){
			return this.member.role.role == "Super Admin" || this.member.role.role == "Admin";
		}
	},
	methods : {
		openPublishingDialog( entryID,status ) {
			var action = status ? "publish" : "unpublish";

			swal( {
				title   : "Are you sure?",
				text    : "You are about to " + action + " this package.",
				icon    : "warning",
				buttons : [
					"Cancel",
					"Yes"
				],
				dangerMode : true,
			} );
		},
		submit() {
			console.log( "save" );
		}
	}
};
</script>