<template>
	<tr>
		<td class="tw-px-6 tw-py-3 tw-max-w-0 tw-w-full tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
			<div class="tw-flex tw-items-center tw-space-x-3 lg:tw-pl-2">
				<a
					:href="'/orgs/'+organization.slug"
					class="tw-truncate tw-text-gray-700 hover:tw-text-gray-600"
				>
					<div>
						<div class="tw-text-sm tw-font-medium tw-text-gray-900">
							{{ organization.name }}
							<svg
								v-if="organization.isComplimentary"
								class="tw-w-4 tw-h-4 tw-inline-block tw-text-yellow-400"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								v-tooltip="'Complimentary Organization'"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
								/>

							</svg>
						</div>
						<div class="tw-text-sm tw-text-gray-500">
							{{ organization.description }}
						</div>
					</div>

				</a>
			</div>
		</td>

		<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
			<div class="tw-flex tw-items-center tw-space-x-3 lg:tw-pl-2">
				<div class="tw-text-sm tw-text-gray-500">
					{{ organization.slug }}
				</div>
			</div>
		</td>

		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
						<span class="tw-inline-block tw-relative">
							<Avatar
								:email="organization.owner.user.email"
								:px="40"
								:size="10"
							/>
						</span>
					</div>
				</div>
				<div class="tw-ml-4">
					<a
						:href="'/@'+organization.owner.user.username"
						class="tw-inline-block"
					>
						<div class="tw-text-sm tw-font-medium tw-text-gray-900">
							{{ organization.owner.user.fullName }}
						</div>
						<div class="tw-text-sm tw-text-gray-500">
							@{{ organization.owner.user.username }}
						</div>
					</a>
				</div>
			</div>
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-sm tw-text-gray-500 tw-font-medium">
			<div class="tw-flex tw-items-center tw-justify-center">
				<div class="tw-flex tw-flex-shrink-0 tw--space-x-1 tw-">
					<Avatar
						v-for="member in filteredMembers"
						:key="member.orgMemberID"
						:email="member.user.email"
						:px="40"
						:size="5"
						custom-class="tw-ring-2 tw-ring-white"
						:tooltip="member.user.username"
					/>
				</div>

				<div
					v-if="filteredMembers.length > 5"
					class="tw-flex-shrink-0 tw-text-xs tw-leading-5 tw-font-medium"
				>
					+{{ filteredMembers.length - 5 }}
				</div>
				<div
					v-else-if="filteredMembers.length == 0"
					class="tw-inline-flex tw-items-center tw-text-xs tw-leading-5 tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded-full tw-bg-blue-300 tw-text-gray-800"
				>
					0
				</div>
			</div>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			<span class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800">
				{{ organization.entries.length }}
			</span>
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800">
				{{ organization.teams.length }}
			</span>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(organization.createdDate)"
		>
			{{ organization.createdDate | formatDate }}
		</td>
		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<a
								:href="'/orgs/'+organization.slug"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: eye -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
									<path
										fill-rule="evenodd"
										d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
										clip-rule="evenodd"
									/>
								</svg>
								View
							</a>
							<span
								v-if="isOwner"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="showEditOrganization()"
							>
								<!-- Heroicon name: solid/pencil-alt -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
									<path
										fill-rule="evenodd"
										d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
										clip-rule="evenodd"
									/>
								</svg>
								Edit
							</span>
							<a
								v-if="isOwner"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								:href="'/orgs/'+organization.slug+'/settings/billing'"
								role="menuitem"
							>
								<!-- Heroicon name: solid/swatch -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
									/>
								</svg>
								Manage
							</a>
							<a
								v-if="organization.entries.length > 0"
								:href="'/organization/@'+organization.slug"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: home -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										clip-rule="evenodd"
										fill-rule="evenodd"
										d="M4 16.5v-13h-.25a.75.75 0 010-1.5h12.5a.75.75 0 010 1.5H16v13h.25a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75v-2.5a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v2.5a.75.75 0 01-.75.75h-3.5a.75.75 0 010-1.5H4zm3-11a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM7.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM11 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zm.5 3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
									/>

								</svg>
								Public Profile
							</a>
						</div>
						<div
							v-if="isOwner"
							class="tw-py-1"
							role="none"
						>
							<span
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRemovalDialog()"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								Delete
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		organization : {
			type     : Object,
			required : true
		},
		user : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			showDropdown : false,
			loading      : false,
			errors       : []
		};
	},
	directives : { ClickOutside },
	computed   : {
		filteredMembers() {
			return this.confirmedMembers.slice( 0, 6 );
		},
		confirmedMembers() {
			return this.organization.members.filter(
				member => member.isAccepted
			);
		},
		isOwner() {
			return this.user.username == this.organization.owner.user.username;
		}
	},
	methods : {
		showEditOrganization() {
			this.$parent.organizationToEdit = this.organization;
			this.$parent.showEditOrganization = true;
		},
		openRemovalDialog() {
			var app = this;
			app.loading = true;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to remove this organization. Keep in mind that all packages and members that belong to the organization will be removed. This action cannot be undone.",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/myaccount/organizations/delete/${app.organization.slug}`
					)
						.then( ( response ) => {
							if ( !response.data.error ) {
								app.loading = false;
								app.reloadOrganizations( response.data.data );
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "The organization with slug " + app.organization.slug + " has been removed successfully.",
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
								app.loading = false;
							}
						} )
						.catch( error => {
							console.log( error );
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "There was an error removing the organization. Please try again.",
								show    : true
							};
							app.loading = false;
						} );
				}
			} );
		},
		reloadOrganizations() {
			var app = this;
			app.$parent.loadOrganizations();
		}
	}
};
</script>
