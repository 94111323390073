<template>
	<div class="tw-relative tw-bg-white">
		<div class="tw-absolute tw-inset-0">
			<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-1/2 tw-bg-gray-50" />
		</div>
		<div class="tw-relative tw-max-w-7xl tw-mx-auto lg:tw-grid lg:tw-grid-cols-5">
			<div class="tw-bg-gray-50 tw-py-16 tw-px-4 sm:tw-px-6 lg:tw-col-span-2 lg:tw-px-8 lg:tw-py-24 xl:tw-pr-12">
				<div class="tw-max-w-lg tw-mx-auto">
					<h2 class="tw-text-2xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-3xl">
						Get in touch
					</h2>
					<p class="tw-mt-3 tw-text-lg tw-leading-6 tw-text-gray-500">
						Want to get in touch? We'd love to hear from you. Here's how you can reach us...
					</p>
					<dl class="tw-mt-8 tw-text-base tw-text-gray-500">
						<div class="tw-mt-6">
							<dt class="tw-sr-only">
								Phone number
							</dt>
							<dd class="tw-flex">
								<!-- Heroicon name: outline/phone -->
								<svg
									class="tw-flex-shrink-0 tw-h-6 tw-w-6 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
									/>
								</svg>
								<span class="tw-ml-3">
									+1 (888) 557 8057
								</span>
							</dd>
						</div>
						<div class="tw-mt-3">
							<dt class="tw-sr-only">
								Email
							</dt>
							<dd class="tw-flex">
								<!-- Heroicon name: outline/mail -->
								<svg
									class="tw-flex-shrink-0 tw-h-6 tw-w-6 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
									/>
								</svg>
								<span class="tw-ml-3">
									<a
										href="mailto:info@ortussolutions.com"
										class="hover:tw-text-gray-600"
									>
										info@ortussolutions.com
									</a>
								</span>
							</dd>
						</div>
						<!-- Twitter -->
						<div class="tw-mt-3">
							<dt class="tw-sr-only">
								Twitter
							</dt>
							<dd class="tw-flex">
								<!-- Icon: twitter -->
								<svg
									class="tw-flex-shrink-0 tw-h-6 tw-w-6 tw-text-gray-400"
									viewBox="328 355 335 276"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										d="M 630, 425
											A 195, 195 0 0 1 331, 600
											A 142, 142 0 0 0 428, 570
											A  70,  70 0 0 1 370, 523
											A  70,  70 0 0 0 401, 521
											A  70,  70 0 0 1 344, 455
											A  70,  70 0 0 0 372, 460
											A  70,  70 0 0 1 354, 370
											A 195, 195 0 0 0 495, 442
											A  67,  67 0 0 1 611, 380
											A 117, 117 0 0 0 654, 363
											A  65,  65 0 0 1 623, 401
											A 117, 117 0 0 0 662, 390
											A  65,  65 0 0 1 630, 425
											Z"
										style="fill:rgb(159, 166, 178);"
									/>
								</svg>
								<span class="tw-ml-3">
									@ortussolutions
								</span>
							</dd>
						</div>
					</dl>
					<p class="tw-mt-6 tw-text-base tw-text-gray-500">
						Looking for Ortus Solutions?
						<a
							href="https://www.ortussolutions.com"
							rel="noopener"
							target="_blank"
							class="tw-font-medium tw-text-gray-700 tw-underline hover:tw-text-gray-600"
						>
							Visit our website
						</a>
					</p>
				</div>
			</div>
			<div class="tw-bg-white tw-py-16 tw-px-4 sm:tw-px-6 lg:tw-col-span-3 lg:tw-py-24 lg:tw-px-8 xl:tw-pl-12">
				<div class="tw-max-w-lg tw-mx-auto lg:tw-max-w-none">
					<form
						id="contactForm"
						name="contactForm"
						action="/support"
						method="post"
						class="tw-grid tw-grid-cols-1 tw-gap-y-6"
						@submit="checkForm"
					>
						<div>
							<label
								for="email"
								class="tw-sr-only"
							>
								Email
							</label>
							<input
								v-model="email"
								id="email"
								name="email"
								type="text"
								autocomplete="email"
								class="tw-block tw-w-full tw-shadow-sm tw-py-3 tw-px-4 tw-placeholder-gray-500 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
								placeholder="Email"
								required="true"
							>
						</div>
						<div>
							<label
								for="subject"
								class="tw-sr-only"
							>
								Subject
							</label>
							<input
								v-model="subject"
								id="subject"
								name="subject"
								type="text"
								class="tw-block tw-w-full tw-shadow-sm tw-py-3 tw-px-4 tw-placeholder-gray-500 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
								placeholder="Subject"
								required="true"
							>
						</div>
						<div>
							<label
								for="category"
								class="tw-sr-only"
							>
								Category
							</label>
							<select
								id="category"
								name="category"
								class="tw-block tw-w-full tw-shadow-sm tw-py-3 tw-px-4 tw-placeholder-gray-500 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
							>
								<option
									v-for="category in categories"
									:value="category"
									:key="category"
								>
									{{ category }}
								</option>
							</select>
						</div>
						<div>
							<label
								for="message"
								class="tw-sr-only"
							>
								Message
							</label>
							<textarea
								v-model="message"
								class="tw-block tw-w-full tw-shadow-sm tw-py-3 tw-px-4 tw-placeholder-gray-500 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
								id="message"
								name="message"
								rows="4"
								placeholder="I need help with..."
								required="true"
								maxlength="500"
							/>
						</div>

						<!-- Alert Box -->
						<Alert
							type="error"
							:messages="errors"
						/>

						<div class="tw-flex tw-justify-end">
							<button
								type="submit"
								class="tw-inline-flex tw-justify-center tw-py-3 tw-px-6 tw-border tw-border-transparent tw-shadow-sm tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
								:class="{ 'hover:tw-bg-blue-700' : !loading }"
								:disabled="loading"
							>
								<div
									v-show="loading"
									class="tw-btn-spinner tw-mt-1 tw-mr-2"
								/>
								{{ loading ? 'Submitting...' : 'Submit' }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		userEmail : {
			type     : String,
			required : false,
			default  : ""
		}
	},
	components : {},
	data() {
		return {
			loading    : false,
			email      : "",
			subject    : "",
			message    : "",
			errors     : [],
			categories : [
				"Feedback",
				"I need support",
				"Report Abuse",
				"Security Issue"
			]
		};
	},
	mounted(){
		this.$nextTick( () => {
			this.email = this.userEmail;
		} );
	},
	computed : {},
	methods  : {
		checkForm : function( e ) {
			if ( this.email && this.subject && this.message ) {
				this.loading = true;
				return true;
			}

			this.errors = [];

			if ( !this.email ) {
				this.errors.push( "The Email is required" );
			}
			if ( !this.subject ) {
				this.errors.push( "The Subject is required" );
			}
			if ( !this.message ) {
				this.errors.push( "The Message is required." );
			}

			e.preventDefault();
		}
	}
};
</script>