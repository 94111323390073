<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Add Packages
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Choose the packages you want add to this team.
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<div class="tw-px-4 tw-py-6">
								<span class="tw-text-sm tw-text-gray-900 tw-font-medium">Find Packages</span>
								<multiselect
									v-model="packagesToAdd"
									class="tw-mt-3"
									placeholder="Search for a package"
									open-direction="bottom"
									:options="filteredPackages"
									track-by="slug"
									label="title"
									:multiple="true"
									:allow-empty="true"
									:internal-search="false"
									:loading="loading"
									@input="errors = []"
								>
									<template
										slot="search"
										slot-scope="props"
									>
										{{ props.title }}
									</template>
								</multiselect>

								<p class="tw-text-sm tw-text-gray-500 tw-my-3">
									The following packages will be added to the team. Make sure you choose the publish access for each package.
								</p>
								<ul class="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto users-to-invite-placeholder">
									<div
										class="tw-mt-6 sm:tw-flex sm:tw-items-center"
									>
										<li
											v-for="(thisPackage) in packagesToAdd"
											:key="thisPackage.slug"
											class="tw-relative tw-rounded-md tw-shadow-sm sm:tw-min-w-0 sm:tw-flex-1"
										>
											<input
												type="text"
												class="tw-bg-gray-100 tw-block tw-w-full tw-pr-32 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
												placeholder="Package"
												:value="`${thisPackage.title} (${thisPackage.slug})`"
												disabled
											>
											<div class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center">
												<span
													class="tw-h-4 tw-w-px tw-bg-gray-200"
													aria-hidden="true"
												/>
												<select
													v-model="thisPackage.publishAccess"
													class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-h-full tw-py-0 tw-pl-4 tw-pr-7 tw-border-transparent tw-bg-transparent tw-text-gray-500 sm:tw-text-sm tw-rounded-md"
												>
													<option :value="false">
														Read Only
													</option>
													<option :value="true">
														Read & Write
													</option>
												</select>
											</div>
										</li>
									</div>
								</ul>
							</div>

							<!-- Alert Box -->
							<Alert
								type="error"
								:messages="errors"
								class="tw-m-3"
							/>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									:label="'Add Packages'"
									:loading="loading"
									:method="addPackages"
									:disabled="loading || !isValidForm"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	props : {
		showSlideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		team : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : { Multiselect },
	data() {
		return {
			showPanel     : false,
			packagesToAdd : [],
			errors        : [],
			loading       : false
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {
		filteredPackages() {
			var app = this;
			return app.organization.entries.reduce( ( a, entry ) => {
				entry[ "publishAccess" ] = false;
				if ( !app.packageBelongsToTeam( entry ) ) {
					a.push( entry );
				}
				return a;
			}, [] );
		},
		isValidForm() {
			return this.packagesToAdd.length > 0;
		}
	},
	methods : {
		addPackages() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.post(
				`/orgs/${app.organization.slug}/teams/packages/add`,
				{
					packages : app.packagesToAdd,
					teamId   : app.team.teamId
				}
			)
				.then( ( response ) => {
					if ( !response.data.error ){
						app.loading = false;

						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "The team has been updated successfully!",
							show    : true
						};
						app.updateUI( response.data.data );
						app.updateFlag();
					} else {
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.response.data.messages[0] );
					app.loading = false;
				} );
		},
		updateUI( packageEntries ) {
			var app = this;
			packageEntries.forEach( function( teamPackage, index, arr ){
				app.$parent.teamPackages.push( teamPackage );
			} );
			app.updateFlag();
		},
		packageBelongsToTeam( entry ) {
			var app = this;
			var belongToTeam = false;
			app.team.entries.forEach( function( teamEntry, index, arr ){
				if ( teamEntry.entry.entryID == entry.entryID ) {
					belongToTeam = true;
					return true;
				}
			} );
			return belongToTeam;
		},
		updateFlag : function() {
			this.showPanel = false;
			this.packagesToAdd = [];
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>