<template>
	<nav
		v-show="totalPages >= 1 && totalRecords > 0"
	>
		<div class="tw-border-t tw-border-gray-200 tw-px-4 tw-flex tw-items-center tw-justify-between sm:tw-px-0">
			<div class="tw--mt-px tw-w-0 tw-flex-1 tw-flex">
				<span
					class="tw-border-t-2 tw-border-transparent tw-pt-4 tw-pr-1 tw-inline-flex tw-items-center tw-text-sm tw-font-medium"
					:class="currentPage <= 1 ? 'tw-text-gray-400 tw-cursor-default tw-pointer-events-none' : 'hover:tw-text-gray-700 hover:tw-border-gray-300 tw-text-gray-500 tw-cursor-pointer'"
					@click.prevent="loadRecords( currentPage - 1 )"
				>
					<!-- Heroicon name: solid/arrow-narrow-left -->
					<svg
						class="tw-mr-3 tw-h-5 tw-w-5"
						:class="currentPage <= 1 ? 'tw-text-gray-400 tw-cursor-default tw-pointer-events-none' : 'tw-text-gray-500'"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
							clip-rule="evenodd"
						/>
					</svg>
					Previous
				</span>
			</div>
			<div
				v-if="!loading"
				class="tw-hidden md:tw--mt-px md:tw-flex"
			>
				<span v-if="(currentPage + bandgap + 3 > totalPages) && totalPages > bandgap">
					<span
						class="tw-pt-4 tw-px-4 tw-border-t-2 tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-cursor-pointer tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300"
						@click.prevent="loadRecords( 1 )"
					>
						<!--- First Page --->
						1
					</span>
					<span
						class="tw-border-transparent tw-text-gray-500 tw-border-t-2 tw-pt-4 tw-px-4 tw-inline-flex tw-items-center tw-text-sm tw-font-medium"
					>
						<!--- Dots --->
						...
					</span>
				</span>
				<span
					v-for="index in paginationPages"
					:key="index"
					:class="index == currentPage ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300'"
					class="tw-border-t-2 tw-pt-4 tw-px-4 tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-cursor-pointer"
					@click.prevent="loadRecords( index )"
				>
					{{ index }}
				</span>
				<span v-if="currentPage + bandgap - 3 < totalPages">
					<span
						class="tw-border-transparent tw-text-gray-500 tw-border-t-2 tw-pt-4 tw-px-4 tw-inline-flex tw-items-center tw-text-sm tw-font-medium"
					>
						<!--- Dots --->
						...
					</span>
					<span
						class="tw-pt-4 tw-px-4 tw-border-t-2 tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-cursor-pointer tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300"
						@click.prevent="loadRecords( totalPages )"
					>
						<!--- Last Page --->
						{{ totalPages }}
					</span>
				</span>
			</div>
			<div
				v-else
				class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
			>
				<div
					class="tw-btn-spinner tw-mt-1"
				/>
				<span class="tw-ml-2">
					Loading...
				</span>
			</div>
			<div class="tw--mt-px tw-w-0 tw-flex-1 tw-flex tw-justify-end">
				<span
					class="tw-border-t-2 tw-border-transparent tw-pt-4 tw-pr-1 tw-inline-flex tw-items-center tw-text-sm tw-font-medium"
					:class="currentPage >= totalPages ? 'tw-text-gray-400 tw-cursor-default tw-pointer-events-none' : 'hover:tw-text-gray-700 hover:tw-border-gray-300 tw-text-gray-500 tw-cursor-pointer'"
					@click.prevent="loadRecords( currentPage + 1 )"
				>
					Next
					<!-- Heroicon name: solid/arrow-narrow-right -->
					<svg
						class="tw-ml-3 tw-h-5 tw-w-5"
						:class="currentPage >= totalPages ? 'tw-text-gray-400' : 'tw-text-gray-500 tw-cursor-default tw-pointer-events-none'"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
							clip-rule="evenodd"
						/>
					</svg>
				</span>
			</div>
		</div>
		<div class="tw-mt-3 tw-text-center tw-w-full">
			<!-- Pagination Info -->
			<p class="tw-text-md tw-text-gray-500">
				Showing {{ firstRecord }} to {{ lastRecord }} of {{ totalRecords }} record{{ totalRecords > 1 ? "s" : "" }} ({{ totalPages }} page{{ totalPages > 1 ? "s" : "" }})
			</p>
		</div>
	</nav>
</template>
<script>
export default {
	props : {
		totalPages : {
			type    : Number,
			default : 0
		},
		totalRecords : { type: Number, default: 0 },
		currentPage  : { type: Number, default: 1 },
		maxRows      : { type: Number, default: 25 },
		loader       : { type: Function, default: null },
		bandgap      : { type: Number, default: 6 },
		loading      : { type: Boolean, default: false }
	},
	computed : {
		pageFrom() {
			var from = 1;
			if ( ( this.currentPage - ( this.bandgap / 2 ) ) > 1 ) {
				from = this.currentPage - ( this.bandgap / 2 );
			}
			return from;
		},
		pageTo() {
			var to = this.totalPages;
			if ( ( this.currentPage + this.bandgap  ) < this.totalPages ) {
				if ( this.currentPage + ( this.bandgap / 2 ) < this.totalPages && this.currentPage + ( this.bandgap / 2 ) > this.bandgap ) {
					to = this.currentPage + ( this.bandgap / 2 );
				} else {
					to = this.bandgap;
				}
			}
			return to;
		},
		paginationPages() {
			if ( this.totalPages <= 7 ) {
				return this.range( 1, this.totalPages + 1 );
			}


			let bottomLimit = this.currentPage > 3 ? Math.max( 1, this.currentPage -3 ) : Math.min( 1, this.currentPage );
			let topLimit = Math.min(
				this.currentPage + 3,
				this.totalPages
			);
			if ( bottomLimit === 1 ) {
				topLimit = bottomLimit + this.bandgap;
			}
			else if ( topLimit === this.totalPages ) {
				bottomLimit = topLimit - this.bandgap;
			}
			return this.range( bottomLimit, topLimit + 1 );
		},
		firstRecord() {
			return Math.max(
				( this.currentPage - 1 ) * this.maxRows + 1,
				1
			);
		},
		lastRecord() {
			return Math.min(
				this.currentPage * this.maxRows,
				this.totalRecords
			);
		}
	},
	methods : {
		loadRecords( page ){
			this.loader.apply( this, [ page ] );
		},
		range( start, end ) {
			let length = end - start;
			return Array.from( { length }, ( _, i ) => start + i );
		}
	}
};
</script>