<template>
	<div
		class="tw-hidden sm:tw-block"
		aria-hidden="true"
	>
		<div :class="dynamicClass">
			<div class="tw-border-t tw-border-gray-200" />
		</div>
	</div>
</template>
<script>

export default {
	props : {
		my : {
			type    : String,
			default : "6"
		}
	},
	computed : {
		dynamicClass(){
			return "tw-my-"+this.my;
		}
	}
};
</script>