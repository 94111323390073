<template>
	<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
		<div class="tw-flex-1">
			<!-- Header -->
			<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
				<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
					<div class="tw-space-y-1">
						<h2
							id="slide-over-heading"
							class="tw-text-lg tw-font-medium tw-text-gray-900"
						>
							{{ editMode ? "Edit" : "Create" }} Team
						</h2>
						<p
							v-if="!editMode"
							class="tw-text-sm tw-text-gray-500"
						>
							Create a new team where you can manage members, packages and publish access
						</p>
						<p
							v-else
							class="tw-text-sm tw-text-gray-500"
						>
							Update the team name or description
						</p>
					</div>
					<div class="tw-h-7 tw-flex tw-items-center">
						<button
							type="button"
							class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
							@click="updateFlag"
						>
							<span class="tw-sr-only">Close panel</span>
							<!-- Heroicon name: outline/x -->
							<svg
								class="tw-h-6 tw-w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>

			<!-- Divider container -->
			<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
				<!-- Team Name -->
				<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
					<div>
						<label
							for="name"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Name
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<input
							v-model="name"
							class="tw-block tw-w-full tw-shadow-sm sm:tw-text-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
							type="text"
							name="name"
						>
					</div>
				</div>

				<!-- Team Description -->
				<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
					<div>
						<label
							for="newPassword"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Description
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<textarea
							v-model="description"
							id="description"
							name="description"
							rows="5"
							placeholder="Description"
							class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						/>
					</div>
				</div>
			</div>
			<Alert
				class="tw-mx-3 tw-mb-3"
				type="error"
				:messages="errors"
			/>
		</div>

		<!-- Action buttons -->
		<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
			<div class="tw-space-x-3 tw-flex tw-justify-end">
				<button
					type="button"
					@click="updateFlag"
					class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
				>
					Cancel
				</button>

				<action-button
					:label="editMode ? 'Save' : 'Create'"
					:loading="loading"
					:method="!editMode ? createTeam : updateTeam"
					:disabled="loading || !isValidForm"
				/>
			</div>
		</div>
	</form>
</template>

<script>

export default {
	props : {
		editMode : {
			type     : Boolean,
			required : false,
			default  : false
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		team : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		}
	},
	data() {
		return {
			name        : "",
			description : "",
			loading     : false,
			errors      : []
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {
		isValidForm() {
			return this.name != "";
		}
	},
	mounted(){
		if ( this.editMode ){
			this.populateItem();
		}
	},
	methods : {
		populateItem() {
			let app = this;
			app.name = app.team.name;
			app.description = app.team.description;
		},
		createTeam() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.post(
				`/orgs/${app.organization.slug}/teams/create`,
				{
					name        : app.name,
					description : app.description
				}
			)
				.then( ( response ) => {
					if ( !response.data.error ){
						app.addTeamToTable( response.data.data );
						app.loading = false;
						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "The team was created successfully!",
							show    : true
						};
						app.updateFlag();
					} else {
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.response.data.messages[0] );
					app.loading = false;
				} );
		},
		updateTeam() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.put(
				`/orgs/${app.organization.slug}/teams/${app.team.teamId}`,
				{
					name        : app.name,
					description : app.description
				}
			)
				.then( ( response ) => {
					if ( !response.data.error ){
						app.loading = false;
						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "The changes have been saved successfully!",
							show    : true
						};
						app.updateTable( response.data.data );
						app.updateFlag();
					} else {
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.response.data.messages[0] );
					app.loading = false;
				} );
		},
		addTeamToTable( team ) {
			var app = this;
			team.entries = [];
			team.members = [];
			app.$parent.$parent.teams.push( team );
		},
		updateTable( team ) {
			var app = this;
			app.team.name = team.name;
			app.team.description = team.description;
			app.organization.teams.forEach( function( value, index, arr ){
				if ( value.teamId == team.teamId ) {
					// Remove row
					app.organization.teams.splice( index, 1 );
					// Add it back with the updated values
					app.organization.teams.splice( index, 0, team );
				}
			} );
		},
		updateFlag : function() {
			var app = this;
			app.showPanel = false;
			app.errors = [];
			app.$parent.updateFlag();
		}
	}
};
</script>