<template>
	<div
		tabindex="0"
		autofocus
		@keydown.esc="updateFlag"
	>
		<div
			v-if="showPanel"
			class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75 tw-z-40"
		/>
		<section
			class="tw-fixed tw-top-0 tw-right-0 tw-max-w-full tw-flex tw-h-screen tw-z-50 tw-pl-0 xs:tw-pl-10 md:tw-pl-0"
			aria-labelledby="slide-over-heading"
		>
			<transition
				enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				enter-class="tw-translate-x-full"
				enter-to-class="tw-translate-x-0"
				leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
				leave-class="tw-translate-x-0"
				leave-to-class="tw-translate-x-full"
			>
				<div
					v-if="showPanel"
					class="tw-w-screen tw-max-w-2xl"
				>
					<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
						<div class="tw-flex-1">
							<!-- Header -->
							<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
								<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
									<div class="tw-space-y-1">
										<h2
											id="slide-over-heading"
											class="tw-text-lg tw-font-medium tw-text-gray-900"
										>
											Add Collaborator
										</h2>
										<p class="tw-text-sm tw-text-gray-500">
											Invite any {{ !isOrg ? "FORGEBOX user" : "member of the organization" }} to collaborate on this package
										</p>
									</div>
									<div class="tw-h-7 tw-flex tw-items-center">
										<button
											type="button"
											class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
											@click="updateFlag"
										>
											<span class="tw-sr-only">Close panel</span>
											<!-- Heroicon name: outline/x -->
											<svg
												class="tw-h-6 tw-w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>

							<!-- Divider container -->
							<div class="tw-p-6 tw-space-y-6">
								<form
									@submit.prevent="add"
									class="form"
								>
									<div class="form-group">
										<span class="tw-text-sm tw-text-gray-900 tw-font-medium">Username</span>
										<multiselect
											v-model="newCollaborator"
											id="newCollaborator"
											class="tw-mt-3"
											placeholder="Search for a user"
											open-direction="bottom"
											:options="users"
											track-by="username"
											label="username"
											@search-change="debounceFindUsers"
											:allow-empty="false"
											:internal-search="false"
											:loading="loading"
										>
											<template
												slot="singleLabel"
												slot-scope="{ option: { username, fname, lname, avatarLink } }"
											>
												<img
													:src="avatarLink"
													:alt="`${fname} ${lname}`"
													class="tw-rounded-full tw-mr-3 tw-inline-block tw-h-8 tw-w-8"
												>
												{{ username }} ({{ fname }} {{ lname }})
											</template>
											<template
												slot="option"
												slot-scope="{ option: { username, fname, lname, avatarLink } }"
											>
												<img
													:src="avatarLink"
													:alt="`${fname} ${lname}`"
													class="tw-rounded-full tw-mr-3 tw-inline-block tw-h-8 tw-w-8"
												>
												{{ username }} ({{ fname }} {{ lname }})
											</template>
										</multiselect>
									</div>

									<div
										class="tw-mb-3"
										v-if="thisPackage.isPrivate"
									>
										<input
											v-model="publishAccess"
											name="publishAccess"
											type="checkbox"
											class="tw-h-4 tw-w-4 tw-text-blue-600 tw-border-gray-300 tw-rounded"
										>
										<span
											class="tw-text-sm tw-text-gray-500"
											for="publishAccess"
										>
											Publish Access
										</span>
									</div>

									<div
										v-if="thisPackage.isPrivate"
										id="publish-access-help-block"
										class="tw-text-sm tw-text-gray-500"
									>
										<svg
											class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											stroke="currentColor"
											fill="none"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<span>
											All collaborators have <strong>read</strong> access by default.
										</span>
									</div>
									<div v-else>
										<svg
											class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											stroke="currentColor"
											fill="none"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<span class="tw-text-sm tw-text-gray-500">
											Collaborators on <strong>public</strong> packages always have publish access by default.
										</span>
									</div>

									<div v-if="newCollaborator != ''">
										<divider my="3" />
										<p class="tw-text-sm tw-text-gray-500 tw-mb-3">
											An invite to collaborate on this package will be sent to the following user:
										</p>
										<div
											class="tw-group tw-p-2 tw-w-full tw-flex tw-items-center tw-justify-between tw-space-x-3 tw-text-left"
										>
											<span class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center tw-space-x-3">
												<span class="tw-block tw-flex-shrink-0">
													<img
														class="tw-h-10 tw-w-10 tw-rounded-full"
														:src="newCollaborator.avatarLink"
														alt=""
													>
												</span>
												<span class="tw-block tw-min-w-0 tw-flex-1">
													<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">{{ newCollaborator.fullName }}</span>
													<span class="tw-block tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">{{ newCollaborator.username }}</span>
												</span>
											</span>
											<span class="tw-flex-shrink-0 tw-h-10 tw-w-10 tw-inline-flex tw-items-center tw-justify-center">
												<!-- Heroicon name: solid/x -->
												<svg
													class="tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block hover:tw-opacity-75 tw-cursor-pointer"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 20 20"
													fill="currentColor"
													aria-hidden="true"
													@click="removeUser"
													v-tooltip="'Remove User'"
												>
													<path
														fill-rule="evenodd"
														d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														clip-rule="evenodd"
													/>
												</svg>
											</span>
										</div>
									</div>

									<!-- Alert Box -->
									<Alert
										class="tw-mt-3"
										type="error"
										:messages="errors"
									/>
								</form>
							</div>
						</div>

						<!-- Action buttons -->
						<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
							<div class="tw-space-x-3 tw-flex tw-justify-end">
								<button
									type="button"
									@click="updateFlag"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
								>
									Cancel
								</button>
								<action-button
									label="Add Collaborator"
									:loading="loading"
									:method="addCollaborator"
									:disabled="loading || newCollaborator == ''"
								/>
							</div>
						</div>
					</form>
				</div>
			</transition>
		</section>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import Divider from "../../../../shared/Divider.vue";

export default {
	props : {
		showslideover : {
			type     : Boolean,
			required : true,
			default  : false
		},
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { Multiselect },
	data() {
		Divider;
		return {
			showPanel       : false,
			users           : [],
			newCollaborator : "",
			publishAccess   : !this.thisPackage.isPrivate,
			errors          : [],
			loading         : false
		};
	},
	watch : {
		showslideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	mounted() {
		this.findUsers();
	},
	methods : {
		debounceFindUsers : debounce( function( query ) {
			this.findUsers( query );
		}, 500 ),
		findUsers( query ){
			let app = this;

			if ( app.isOrg ) {
				app.users = app.organization.members.map(
					function( member ){
						return member.user;
					} );
				return;
			}
			app.loading = true;
			app.$http.get(
				"/api/v1/users",
				{ params: { searchTerm: query } }
			)
				.then( response => {
					app.users = response.data.data.results;
					app.loading = false;
				} )
				.catch( err => {
					app.loading = false;
					app.errors.push( "The users list couldn't be retrieved. Please try again" );
				} );
		},
		addCollaborator() {
			let app = this;
			app.loading = true;
			app.$parent.notification.show = false;

			app.$http.post(
				"/api/v1/collaborations",
				{
					slug          : app.thisPackage.slug,
					username      : app.newCollaborator.username,
					publishAccess : app.publishAccess
				}
			)
				.then( response => {
					app.loading = false;
					if ( !response.data.error ) {
						app.$parent.addCollaborator( response.data.data );
						app.$parent.notification = {
							type    : "success",
							title   : "Invite Sent",
							message : "The invite has been sent successfully",
							show    : true
						};
					} else {
						app.$parent.notification = {
							type    : "error",
							title   : "Error",
							message : "There was an error sending the invite. Please try again",
							show    : true
						};
						app.errors.push( response.data.messages );
					}
					app.updateFlag();
				} )
				.catch( error => {
					app.loading = false;
					app.$parent.notification = {
						type    : "error",
						title   : "Error",
						message : "There was an error sending the invite. Please try again",
						show    : true
					};
					console.log( error );
					// app.errors.push( error.response.data.messages[0] );
				} );

		},
		removeUser() {
			var app = this;
			app.newCollaborator = "";
		},
		updateFlag : function() {
			this.errors = [];
			this.newCollaborator = "";
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>