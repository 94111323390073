<template>
	<div
		class="tw-fixed tw-z-40 tw-inset-0 tw-overflow-y-auto"
		aria-labelledby="New-Package"
		role="dialog"
		aria-modal="true"
	>
		<div class="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
			<!--
                Background overlay, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100"
                    To: "opacity-0"
            -->
			<div
				class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-opacity-75"
			/>

			<!-- This element is to trick the browser into centering the modal contents. -->
			<span
				class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
				aria-hidden="true"
			>​</span>

			<!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
			<div class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-3xl sm:tw-w-full sm:tw-p-6">
				<div class="tw-hidden sm:tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
					<button
						type="button"
						class="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
						@click="updateFlag"
					>
						<span class="tw-sr-only">Close</span>
						<!-- Heroicon name: outline/x -->
						<svg
							class="tw-h-6 tw-w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div class="sm:tw-flex sm:tw-items-start">
					<div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
						<!-- <h3
							class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							id="modal-title"
						>
							Create Package
						</h3> -->
						<div class="tw-mt-2">
							<p class="tw-text-medium tw-text-gray-500">
								There are 2 ways to create a package in FORGEBOX. Via CommandBox and the UI Wizard Package
							</p>
						</div>
					</div>
				</div>
				<div class="tw-flex tw-m-3">
					<div class="col-8 tw-border-r tw-p-6">
						<p class="tw-text-lg tw-text-gray-700 tw-font-semibold tw-text-center">
							CommandBox
						</p>
						<p class="tw-text-gray-500">
							- $ box forgebox login username password
						</p>
						<p>
							- $ box bump --major|minor
						</p>
						<p>
							- $ box publish
						</p>
					</div>
					<div class="col-4 tw-p-6">
						<p class="tw-text-lg tw-text-gray-700 tw-font-semibold tw-text-center">
							Wizard
						</p>
						<p>
							Follow our wizard UI
						</p>
						<div class="tw-text-center tw-mt-8">
							<a
								:href="!isOrg ? '/myaccount/packages/new' : `/orgs/${organization.slug}/packages/new`"
								class="tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-700 hover:tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
							>
								Create
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		showDialog : {
			type     : Boolean,
			required : true,
			default  : false
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return { slug: "" };
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	data() {
		return { showPanel: false };
	},
	watch : {
		showDialog( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	methods : {
		updateFlag : function() {
			this.showPanel = false;
			this.$emit( "interface", this.showPanel ); // handle data and give it back to parent by interface
		}
	}
};
</script>