<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div
				v-if="packageSubscriptions.length != 0"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
					Total Package Subscriptions: {{ packageSubscriptions.length }}
				</div>
			</div>

			<div
				v-if="packageSubscriptions.length == 0"
				class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
						No package subscriptions yet
					</h3>
					<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							You are not subscribed to any package notifications at the moment. The packages you get subscribed to will show up here.
						</p>
					</div>
				</div>
			</div>

			<div
				v-else
				class="tw-bg-white"
			>
				<div class="tw-mt-3">
					<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
						<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
							<thead>
								<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Package
									</th>
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										# Of Versions
									</th>
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Latest Version
									</th>
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Last Update
									</th>
									<th class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
								</tr>
							</thead>
							<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
								<tr
									v-for="(subscription) in packageSubscriptions"
									is="SubscriptionRow"
									:subscription="subscription"
									:key="subscription.username"
									class="hover:tw-bg-gray-50"
								/>
							</tbody>
						</table>
					</div>
					<div
						v-if="hasCommunityPlan"
						class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500 tw-mt-3"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke="currentColor"
							fill="none"
							aria-hidden="true"
							class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
						><path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/></svg> <span>
							With the community plan, you can only get subscribed to up to 3 packages to receive email notifications.
							If you need want to get unlimited package subscriptions, check out our plans
							<a
								href="/plans"
								class="tw-text-sm tw-text-blue-600 hover:tw-text-blue-500 tw-cursor-pointer"
							>
								here!
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SubscriptionRow from "./SubscriptionRow";

export default {
	props : {
		user : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		subscriptions : {
			type     : Array,
			required : false,
			default  : function() {
				return [];
			}
		},
		hasCommunityPlan : {
			type     : Boolean,
			required : false,
			default  : true
		}
	},
	components : { SubscriptionRow },
	data() {
		return {
			menu                 : [ { label: "Subscriptions" } ],
			packageSubscriptions : this.subscriptions,
			loading              : false,
			errors               : [],
			notification         : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	mounted(){
	},
	computed : {},
	methods  : {}
};
</script>