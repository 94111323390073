<template>
	<tr>
		<td class="tw-px-6 tw-py-3 tw-max-w-0 tw-w-full tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
			<div class="tw-flex tw-items-center tw-space-x-3 lg:tw-pl-2">
				<a
					:href="!isOrg ? `/myaccount/packages/manage/${thisPackage.slug}` : `/orgs/${organization.slug}/packages/manage/${thisPackage.slug}`"
					class="tw-truncate tw-text-gray-700 hover:tw-text-gray-600"
				>
					<span>
						{{ thisPackage.title }}
						<span
							v-if="thisPackage.latestVersionNumber != null"
							class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-blue-200 tw-text-gray-900"
						>
							{{ thisPackage.latestVersionNumber }}
						</span>
						<div class="tw-text-sm tw-text-gray-500">
							{{ thisPackage.slug }}
						</div>
					</span>
				</a>
			</div>
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span class="tw-inline-flex tw-items-center tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-yellow-100 tw-text-yellow-800">
				{{ thisPackage.typeName }}
			</span>
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-sm tw-text-gray-500 tw-font-medium">
			<div class="tw-flex tw-items-center tw-justify-center">
				<div class="tw-flex tw-flex-shrink-0 tw--space-x-1 tw-">
					<Avatar
						v-for="collaboration in filteredCollaborations"
						:key="collaboration.collaborationID"
						:email="collaboration.user.email"
						:px="40"
						:size="5"
						custom-class="tw-ring-2 tw-ring-white"
						:tooltip="collaboration.user.username"
					/>
				</div>

				<div
					v-if="thisPackage.collaborations.length > 5"
					class="tw-flex-shrink-0 tw-text-xs tw-leading-5 tw-font-medium"
				>
					+{{ thisPackage.collaborations.length - 5 }}
				</div>
				<div
					v-else-if="thisPackage.collaborations.length == 0"
					class="tw-inline-flex tw-items-center tw-text-xs tw-leading-5 tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded-full tw-bg-blue-300 tw-text-gray-800"
				>
					0
				</div>
			</div>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-right"
			v-tooltip="getFullDate(thisPackage.latestVersionUpdatedDate)"
		>
			{{ thisPackage.latestVersionUpdatedDate | formatDate }}
		</td>
		<td class="tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-right">
			<div class="tw-flex tw-items-center tw-space-x-2 lg:tw-pl-2">
				<svg
					:class="thisPackage.isActive ? 'tw-text-green-500' : 'tw-text-red-600'"
					class="tw-h-6 tw-w-6 tw-cursor-pointer tw-mx-auto"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 24 24"
					stroke="currentColor"
					aria-hidden="true"
					v-tooltip="thisPackage.isActive ? 'Published' : 'Unpublished'"
				>
					<path
						v-if="thisPackage.isActive"
						fill-rule="evenodd"
						d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
						clip-rule="evenodd"
					/>
					<path
						v-else
						d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
					/>
				</svg>
			</div>
		</td>
		<td class="tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-right">
			<div class="tw-flex tw-items-center tw-space-x-2 lg:tw-pl-2">
				<svg
					:class="thisPackage.isPrivate ? 'tw-text-red-600' : 'tw-text-green-500'"
					class="tw-h-6 tw-w-6 tw-cursor-pointer tw-mx-auto"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 24 24"
					stroke="currentColor"
					aria-hidden="true"
					v-tooltip="thisPackage.isPrivate ? 'Private' : 'Public'"
				>
					<path
						v-if="thisPackage.isPrivate"
						fill-rule="evenodd"
						d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
						clip-rule="evenodd"
					/>
					<path
						v-else
						d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
					/>
				</svg>
			</div>
		</td>
		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<a
								:href="'/view/'+thisPackage.slug"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: eye -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
									/>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
									/>
								</svg>
								View
							</a>
							<!-- TODO: Maybe check here if the member has publish access through any team and show the link here -->
							<a
								:href="!isOrg ? `/myaccount/packages/manage/${thisPackage.slug}` : `/orgs/${organization.slug}/packages/manage/${thisPackage.slug}`"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: solid/pencil-alt -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
									<path
										fill-rule="evenodd"
										d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
										clip-rule="evenodd"
									/>
								</svg>
								Manage
							</a>
							<span
								v-if="canManagePackages"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700"
								:class="thisPackage.numberOfVersions > 0 ? 'hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer' : 'tw-bg-gray-100 tw-cursor-not-allowed'"
								@click="openPublishingDialog(thisPackage.numberOfVersions,!thisPackage.isActive)"
								v-tooltip="thisPackage.numberOfVersions == 0 && !thisPackage.isActive ? 'This package cannot be published because it does not have any version yet' : ''"
							>
								<!-- Heroicon name: arrow-down/arrow-up -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400"
									:class="thisPackage.numberOfVersions > 0 ? 'group-hover:tw-text-gray-500' : ''"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										v-if="!thisPackage.isActive"
										fill-rule="evenodd"
										d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
										clip-rule="evenodd"
									/>
									<path
										v-else
										d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
									/>
								</svg>
								{{ !thisPackage.isActive ? 'Publish' : 'Unpublish' }}
							</span>
						</div>
						<div
							v-if="canManagePackages"
							class="tw-py-1"
							role="none"
						>
							<span
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRemovalDialog"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								Delete
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true
		},
		organization : {
			type     : Object,
			required : true
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		canPublishNewPackages : {
			type     : Boolean,
			required : true,
			default  : false
		},
		canManagePackages : {
			type     : Boolean,
			required : true,
			default  : false
		}
	},
	data() {
		return {
			showDropdown : false,
			loading      : false,
			errors       : []
		};
	},
	directives : { ClickOutside },
	computed   : {
		filteredCollaborations() {
			return this.thisPackage.collaborations.slice( 0, 6 );
		},
		isSuperAdmin(){
			return this.member.role.role == "Super Admin";
		}
	},
	methods : {
		openPublishingDialog( numberOfVersions, status ) {
			var app = this;
			numberOfVersions = parseInt( numberOfVersions, 10 );
			if ( numberOfVersions == 0 ){
				return;
			}
			app.$parent.notification.show = false;
			var action = status ? "publish" : "unpublish";
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to " + action + " this package. This change will take effect immediately.",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					var url = !app.isOrg ? `/myaccount/packages/${this.thisPackage.entryID}/changeStatus/${status}` : `/orgs/${app.organization.slug}/packages/${this.thisPackage.entryID}/changeStatus/${status}`;
					app.$http.post( url )
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.$parent.loadPackages();
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.data,
									show    : true
								};
							}
						} ).catch( error => {
							app.loading = false;
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : error.response.data.messages[0],
								show    : true
							};
							app.errors.push( error.response.data.messages[0] );
						} );
				}
			} );
		},
		openRemovalDialog() {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to remove this package. This action cannot be undone.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					var url = !app.isOrg ? `/myaccount/packages/delete/${app.thisPackage.entryID}` : `/orgs/${app.organization.slug}/packages/delete/${this.thisPackage.entryID}`;
					app.$http.delete( url )
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.$parent.loadPackages();
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.data,
									show    : true
								};
							}
						} ).catch( error => {
							app.loading = false;
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : error.response.data.messages[0],
								show    : true
							};
							app.errors.push( error.response.data.messages[0] );
						} );
				}
			} );
		}
	}
};
</script>
