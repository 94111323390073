// Import Components
import ChangePlan from "./ChangePlan";
import DowngradePlan from "./DowngradePlan";
import UpgradePlan from "./UpgradePlan";
import Pricing from "./Pricing";
import About from "./About";
import Versions from "./Versions";
import Support from "./Support";
import DependenciesTree from "./DependenciesTree";
import Stats from "./Stats/Index.vue";
import InstallsChart from "./Stats/InstallsChart.vue";
import SideBarClickEmitter from "./SideBarClickEmitter";
import Homepage from "./User/Homepage/Homepage";
import UserSideBar from "./User/SideBar";
import Dashboard from "./Shared/Dashboard/Index.vue";
import UserProfile from "./User/Settings/Profile/Index";
import Account from "./User/Settings/Account/Index";
import Billing from "./User/Settings/Billing/Index";
import AccessTokens from "./User/Settings/AccessTokens/Index";
import PackageRow from "./Shared/Packages/PackageRow";
import Packages from "./Shared/Packages/Index.vue";
import NewPackage from "./Shared/Packages/New";
import ManagePackage from "./Shared/Packages/Manage";
import EditPackage from "./Shared/Packages/Edit";
import NewPackageVersion from "./Shared/Packages/Versions/New";
import EditPackageVersion from "./Shared/Packages/Versions/Edit";
import Collaborations from "./Shared/Collaborations/Index";
import Subscriptions from "./User/Subscriptions/Index";
import Organizations from "./User/Organizations/Index";
import ContextSwitcher from "./Shared/ContextSwitcher.vue";
// Org context
import OrgSideBar from "./Orgs/SideBar";
import VersionForm from "./Package/VersionForm";
import OrgProfile from "./Orgs/Settings/Profile/Index";
import OrgBilling from "./Orgs/Settings/Billing/Index";
import OrgMembers from "./Orgs/Members/Index.vue";
import OrgTeams from "./Orgs/Teams/Index.vue";
import ManageTeam from "./Orgs/Teams/Manage.vue";

// Export the default as a Vue Plugin
export default {
	install( Vue ) {
		Vue.component( "DowngradePlan", DowngradePlan );
		Vue.component( "ChangePlan", ChangePlan );
		Vue.component( "Pricing", Pricing );
		Vue.component( "About", About );
		Vue.component( "Versions", Versions );
		Vue.component( "SideBarClickEmitter", SideBarClickEmitter );
		Vue.component( "Support", Support );
		Vue.component( "DependenciesTree", DependenciesTree );
		Vue.component( "Stats", Stats );
		Vue.component( "InstallsChart", InstallsChart );
		Vue.component( "Homepage", Homepage );
		Vue.component( "UserSideBar", UserSideBar );
		Vue.component( "Dashboard", Dashboard );
		Vue.component( "UserProfile", UserProfile );
		Vue.component( "Billing", Billing );
		Vue.component( "AccessTokens", AccessTokens );
		Vue.component( "Account", Account );
		Vue.component( "PackageRow", PackageRow );
		Vue.component( "Packages", Packages );
		Vue.component( "NewPackage", NewPackage );
		Vue.component( "EditPackage", EditPackage );
		Vue.component( "ManagePackage", ManagePackage );
		Vue.component( "NewPackageVersion", NewPackageVersion );
		Vue.component( "EditPackageVersion", EditPackageVersion );
		Vue.component( "Subscriptions", Subscriptions );
		Vue.component( "Collaborations", Collaborations );
		Vue.component( "Organizations", Organizations );
		// Organization Context
		Vue.component( "OrgSideBar", OrgSideBar );
		Vue.component( "OrgMembers", OrgMembers );
		Vue.component( "OrgTeams", OrgTeams );
		Vue.component( "OrgProfile", OrgProfile );
		Vue.component( "OrgBilling", OrgBilling );
		Vue.component( "ManageTeam", ManageTeam );
		Vue.component( "ContextSwitcher", ContextSwitcher );

		// Old
		Vue.component( "VersionForm", VersionForm );
	}
};

// Expose Components
export {
	DowngradePlan,
	UpgradePlan,
	ChangePlan,
	Pricing,
	About,
	Versions,
	Support,
	DependenciesTree,
	Stats,
	InstallsChart,
	Homepage,
	UserSideBar,
	Dashboard,
	UserProfile,
	Account,
	PackageRow,
	Packages,
	NewPackage,
	EditPackage,
	ManagePackage,
	NewPackageVersion,
	EditPackageVersion,
	Subscriptions,
	Collaborations,
	Organizations,
	AccessTokens,
	Billing,
	OrgSideBar,
	OrgMembers,
	OrgTeams,
	VersionForm,
	OrgProfile,
	OrgBilling,
	ManageTeam,
	SideBarClickEmitter,
	ContextSwitcher
};

window.addEventListener( "load", function( event ) {
	$( ".nav-link.entry-tab, .hash-tab" ).click( function( e ) {
		var hash = $( this ).attr( "href" );
		window.location.hash = hash;
	} );
} );