<template>
	<tr>
		<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
			<div v-if="!log.isSystem">
				<Avatar
					:email="log.user.email"
					:px="40"
					:size="6"
					:tooltip="isOrg ? '@' + log.user.username : 'You'"
				/>
			</div>
			<div v-else>
				<img
					src="/includes/images/ForgeBoxIcon100.png"
					class="tw-rounded-full tw-w-6 tw-h-6"
					v-tooltip.right="'System'"
				>
			</div>
		</td>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
		>
			{{ log.activity }}
		</td>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			<span
				class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-capitalize"
				:class="`tw-bg-${getBadgeColor(log.action)}-100 tw-text-${getBadgeColor(log.action)}-800`"
			>
				{{ log.action }}
			</span>
		</td>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			{{ getFullDate( log.createdDate ) }}
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
	props : {
		user : {
			type     : Object,
			required : true
		},
		log : {
			type     : Object,
			required : true
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	data() {
		return {
			showDropdown : false,
			loading      : false,
			errors       : []
		};
	},
	directives : { ClickOutside },
	computed   : {
		filteredMembers() {
			return [];
		}
	},
	methods : {
		removeLog( orgSlug ) {
			var app = this;
			app.loading = true;
			app.$http.delete( "/ui/orgs/delete", { params: { orgSlug: orgSlug } } )
				.then( ( response ) => {
					app.loading = false;
				} )
				.catch( error => {
					app.errors.push( error );
					app.loading = false;
				} );
		},
		async openRemovalDialog( orgSlug ) {
			var app = this;
			const confirm = await swal( {
				title      : "Are you sure?",
				text       : "You are about to remove this log. This action cannot be undone.",
				icon       : "warning",
				buttons    : true,
				dangerMode : true
			} );

			if ( confirm ) {
				app.$http.delete( "/ui/orgs/delete", { params: { orgSlug: orgSlug } } )
					.then( ( response ) => {
						app.loading = false;
					} )
					.catch( error => {
						app.errors.push( error );
						app.loading = false;
					} );
			}
		},
		getBadgeColor( action ) {
			switch ( action ) {
			case "Login":
				return "blue";
			case "Create":
				return "green";
			case "Update":
				return "yellow";
			case "Delete":
				return "red";
			default:
				return "blue";
			}
		}
	}
};
</script>
