<template>
	<div>
		<span>
			{{ installsInRange | formatNumber }} installs in the last {{ months > 1 ? months + ' months' : 'month' }}
		</span>
		<LineChartGenerator
			:chart-options="chartOptions"
			:chart-data="chartData"
			:chart-id="chartId"
			:dataset-id-key="datasetIdKey"
			:plugins="plugins"
			:css-classes="cssClasses"
			:styles="styles"
			:width="width"
			:height="height"
		/>
	</div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import moment from "moment";

import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement
} from "chart.js";

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement
);

export default {
	name       : "InstallsChart",
	components : { LineChartGenerator },
	props      : {
		chartId : {
			type    : String,
			default : "line-chart"
		},
		datasetIdKey : {
			type    : String,
			default : "label"
		},
		width : {
			type    : Number,
			default : 100
		},
		height : {
			type    : Number,
			default : 150
		},
		cssClasses : {
			default : "",
			type    : String
		},
		styles : {
			type    : Object,
			default : () => {}
		},
		plugins : {
			type    : Array,
			default : () => []
		},
		data : {
			type    : Array,
			default : () => []
		},
		numberOfWeeks : {
			type    : Number,
			default : 4
		}
	},
	data() {
		return {
			chartOptions : {
				responsive          : true,
				maintainAspectRatio : false,
				plugins             : { legend: { display: false } }
			},
			weeks  : this.numberOfWeeks,
			map    : {},
			labels : [],
			values : []
		};
	},
	mounted(){
		if ( this.weeks > this.data.length ){
			this.weeks = this.data.length;
		}
		this.prepareChartData();
	},
	computed : {
		chartData() {
			return {
				labels   : this.labels,
				datasets : [
					{
						label           : "Installs",
						backgroundColor : "#5e72e4",
						data            : this.values
					}
				]
			};
		},
		months() {
			var months = 1;
			if ( this.weeks > 4 ){
				// 0.23 is the magic number to get the number of months from the number of weeks
				months = Math.ceil( this.weeks * 0.23 );
			}
			return months;
		},
		installsInRange() {
			var installs = 0;
			for ( let i = 0; i < this.values.length; i++ ) {
				installs += this.values[ i ];
			}
			return installs;
		}
	},
	methods : {
		prepareChartData() {
			var app = this;
			// convert the data to a map of weekOfDate => count
			for ( let j = 0; j < app.data.length; j++ ) {
				// make sure the date is in UTC so we don't have issues with timezones
				var weekOfDate = new Date( app.data[ j ].weekOfDate ).toLocaleString( "en-US", { timeZone: "UTC" } );
				app.map[ moment( new Date( weekOfDate ) ).format( "MM-DD-YY" ) ]  = app.data[ j ].count;
			}

			var today = new Date();
			var lastSunday = new Date( today.setDate( today.getDate() - ( today.getDay()||7 ) ) );
			var initialSunday = new Date( lastSunday.setDate( lastSunday.getDate() - ( app.weeks * 7 ) ) );

			// loop through the weeks and set the labels and values
			for ( let i = 0; i < app.weeks; i++ ) {
				var value = 0;
				var formattedDate =  moment( initialSunday.setDate( initialSunday.getDate() + 7 ) ).format( "MM-DD-YY" );
				if ( app.map[ formattedDate ] !== undefined ) {
					value = app.map[ formattedDate ];
				}
				app.labels.push( formattedDate );
				app.values.push( value );
			}
		}
	}
};
</script>
