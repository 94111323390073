<template>
	<div
		id="public-profile"
		class="tw-max-w-7xl tw-mx-auto tw-p-3 mt--80 tw-space-y-3"
	>
		<section aria-labelledby="profile-overview-title">
			<div class="tw-rounded-lg tw-bg-white tw-overflow-hidden tw-shadow">
				<h2
					class="tw-sr-only"
					id="profile-overview-title"
				>
					Public Profile
				</h2>
				<div class="tw-bg-white tw-p-6">
					<div class="sm:tw-flex sm:tw-items-center sm:tw-justify-between">
						<div class="sm:tw-flex sm:tw-space-x-5">
							<div
								v-if="!isOrg"
								class="tw-flex-shrink-0"
							>
								<img
									:src="user.avatarLink"
									:alt="`${user.fullName}`"
									class="tw-shadow tw-rounded-full tw-w-20 tw-h-20"
								>
							</div>
							<div
								v-else
								class="tw-flex-shrink-0"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									class="tw-flex-shrink-0 tw-rounded-full tw-h-28 tw-w-28 tw-border-2 tw-p-3 tw-text-gray-400"
								><path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
								/></svg>
							</div>
							<div class="tw-mt-4 tw-text-center sm:tw-mt-0 sm:tw-pt-1 sm:tw-text-left">
								<p class="tw-text-xl tw-font-bold tw-text-gray-900 sm:tw-text-2xl">
									{{ !isOrg ? user.fullName : organization.name }}
								</p>
								<p class="tw-text-base tw-font-medium tw-text-gray-900">
									@{{ !isOrg ? user.username : organization.slug }}
								</p>
								<p
									v-if="!isOrg"
									class="tw-text-sm tw-font-medium tw-text-gray-600"
								>
									Member since {{ user.createdDate | formatDate }}
								</p>
								<p
									v-else
									class="tw-text-sm tw-font-medium tw-text-gray-600"
								>
									Created on {{ organization.createdDate | formatDate }}
								</p>
							</div>
						</div>
						<div class="tw-mt-5 tw-justify-center sm:tw-mt-0">
							<div class="tw-block">
								<span
									v-if="isOrg"
									class="tw-inline-flex tw-mb-3 tw-text-xs tw-leading-5 tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded-full tw-bg-blue-300 tw-text-gray-800"
								>
									Organization
								</span>
								<a
									v-if="!isOrg && sameUser"
									href="/myaccount"
									class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
								>
									My Account
								</a>
								<a
									v-if="isOrg && sameUser"
									:href="`/orgs/${organization.slug}`"
									class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
								>
									Dashboard
								</a>
							</div>
							<div
								v-if="!isOrg"
								class="tw-block"
							>
								<div class="tw-mt-4 tw-space-x-3 lg:tw-pb-0 tw-w-4/5 lg:tw-w-full tw-mx-auto tw-flex tw-flex-wrap tw-items-center tw-justify-center">
									<a
										v-if="user.otherURL != ''"
										:href="user.otherURL"
										rel="noopener"
										target="_blank"
										class="tw-text-gray-500"
									>
										<svg
											class="tw-h-6 tw-text-gray-600 hover:tw-text-gray-500"
											role="img"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											xmlns="http://www.w3.org/2000/svg"
											v-tooltip="'Homepage'"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
											/>
										</svg>
									</a>
									<a
										v-if="user.githubURL != ''"
										:href="user.githubURL"
										rel="noopener"
										target="_blank"
										class="tw-text-gray-500"
									>
										<svg
											class="tw-h-6 tw-fill-current tw-text-gray-600 hover:tw-text-gray-500"
											role="img"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											v-tooltip="'Github'"
										>
											<path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
										</svg>
									</a>
									<a
										v-if="user.twitterURL != ''"
										:href="user.twitterURL"
										rel="noopener"
										target="_blank"
										class="tw-text-gray-500"
									>
										<svg
											class="tw-h-6 tw-fill-current tw-text-gray-600 hover:tw-text-gray-500"
											role="img"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											v-tooltip="'Twitter'"
										>
											<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
										</svg>
									</a>
									<a
										v-if="user.blogURL != ''"
										:href="user.blogURL"
										rel="noopener"
										target="_blank"
										class="tw-text-gray-500"
									>
										<svg
											class="tw-h-6 tw-fill-current tw-text-gray-600 hover:tw-text-gray-500"
											role="img"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											v-tooltip="'Blog'"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
											/>
										</svg>
									</a>
									<a
										v-if="user.linkedinURL != ''"
										:href="user.linkedinURL"
										rel="noopener"
										target="_blank"
										class="tw-text-gray-500"
									>
										<svg
											class="tw-h-6 tw-fill-current tw-text-gray-600 hover:tw-text-gray-500"
											role="img"
											viewBox="71 71 370 370"
											xmlns="http://www.w3.org/2000/svg"
											v-tooltip="'LinkedIn'"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"
											/>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tw-border-t tw-border-gray-200 tw-bg-gray-50 tw-flex tw-divide-y tw-divide-gray-200 tw-divide-y-0 sm:tw-divide-x">
					<div
						class="tw-flex-1 tw-px-6 tw-py-5 tw-text-sm tw-font-medium tw-text-center tw-overflow-hidden tw-shadow"
						:class="{'tw-bg-blue-200' : filter == 'listed' && sameUser, 'tw-cursor-pointer hover:tw-bg-blue-100' : sameUser }"
						@click="sameUser ? filterResults('listed') : ''"
					>
						<span class="tw-text-gray-900">
							{{ packagesCount.listed }}
						</span>
						<!-- space -->
						<span class="tw-text-gray-600">
							Public Packages
						</span>
					</div>

					<div
						v-if="sameUser"
						class="tw-flex-1 tw-px-6 tw-py-5 tw-text-sm tw-font-medium hover:tw-bg-blue-100 tw-text-center tw-overflow-hidden tw-shadow tw-cursor-pointer"
						:class="{'tw-bg-blue-200' : filter == 'private' }"
						@click="filterResults('private')"
					>
						<span class="tw-text-gray-900">
							{{ packagesCount.private }}
						</span>
						<!-- space -->
						<span class="tw-text-gray-600">Private Packages</span>
					</div>

					<div
						v-if="sameUser"
						class="tw-flex-1 tw-px-6 tw-py-5 tw-text-sm tw-font-medium hover:tw-bg-blue-100 tw-text-center tw-overflow-hidden tw-shadow tw-cursor-pointer"
						:class="{'tw-bg-blue-200' : filter == 'collaborations' }"
						@click="filterResults('collaborations')"
					>
						<span class="tw-text-gray-900">
							{{ packagesCount.collaborations }}
						</span>
						<!-- space -->
						<span class="tw-text-gray-600">Collaborated Packages</span>
					</div>
				</div>
			</div>
		</section>

		<div class="tw-space-y-3">
			<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
				<div class="tw-col-span-6 sm:tw-col-span-3">
					<label
						for="packageSearch"
						class="tw-sr-only"
					>
						Search
					</label>
					<div class="tw-relative tw-rounded-md tw-shadow-sm">
						<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
							<!-- Heroicon name: solid/search -->
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
						<input
							v-model="search"
							name="packageSearch"
							id="packageSearch"
							type="search"
							class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							@input="debounceSearch"
							placeholder="Search"
						>
						<!-- Heroicon name: x -->
						<div
							v-if="search != ''"
							class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
						>
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
								@click="clearSearch"
							>
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div class="tw-col-span-6 sm:tw-col-span-3">
					<div class="tw-flex">
						<select
							v-model="packageTypeFilter"
							id="type"
							name="type"
							class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-mr-3"
							@change="loadEntries()"
						>
							<option value="all">
								All Types
							</option>
							<option
								v-for="type in packageTypes"
								:key="type.typeSlug"
								:value="type.typeSlug"
							>
								{{ type.typeName }}
							</option>
						</select>
						<button
							type="button"
							class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
							@click="resetFilters"
							v-tooltip="'Reset Filters'"
						>
							<!-- Heroicon name: solid/x -->
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="tw-sr-only">Reset Filters</span>
						</button>
						<a
							v-if="!isOrg"
							href="/myaccount/packages"
						>
							<button
								v-if="sameUser"
								type="button"
								class="tw-w-48 tw-px-4 tw-py-2 tw-ml-3 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
							>
								Manage Packages
							</button>
						</a>
					</div>
				</div>
			</div>

			<div
				v-if="entries.length != 0 && !loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					Showing <span class="tw-font-bold">{{ sameUser ? filter : "" }}</span> packages in {{ packageTypeFilter != 'all' ? 'the ' : '' }}
					<span class="tw-font-bold">{{ packageTypeFilter }}</span>
					type{{ packageTypeFilter == 'all' ? 's' : '' }}
				</div>
				<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
					Page: {{ pagination.page }}
				</div>
			</div>

			<div
				v-else-if="loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					<div
						class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
					>
						<div
							class="tw-btn-spinner tw-mt-1"
						/>
						<span class="tw-ml-2">Loading...</span>
					</div>
				</div>
			</div>

			<div
				v-if="entries.length > 0"
				aria-labelledby="package-cards"
			>
				<div class="tw-rounded-lg tw-bg-transparent tw-overflow-hidden sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-6">
					<h2
						class="tw-sr-only"
						id="package-cards"
					>
						Packages
					</h2>

					<div
						v-for="entry in entries"
						is="PackageCard"
						:key="entry.entryID"
						:this-package="entry"
						:same-user="sameUser"
						:is-org="isOrg"
						class="tw-relative tw-group tw-bg-white tw-rounded-lg tw-shadow tw-p-6"
					/>
				</div>
			</div>
			<div
				v-else-if="entries.length == 0 && !loading"
				class="tw-bg-white tw-rounded-lg tw-shadow tw-pb-6 tw-text-center"
			>
				<svg
					class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<p class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
					No Packages found
				</p>
			</div>
		</div>

		<Pagination
			:total-pages="pagination.totalPages"
			:total-records="pagination.records"
			:max-rows="pagination.maxRow"
			:current-page="pagination.page"
			:loader="loadEntries"
			:loading="loading"
		/>
	</div>
</template>

<script>
import PackageCard from "./PackageCard";
import debounce from "lodash/debounce";

export default {
	props : {
		user : {
			type     : Object,
			required : false,
			default  : function() {
				return { username: "" };
			}
		},
		plan : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		sameUser : {
			type     : Boolean,
			required : true,
			default  : false
		},
		enterpriseMode : {
			type     : Boolean,
			required : false,
			default  : false
		},
		packagesCount : {
			type     : Object,
			required : true,
			default  : function() {
				return {
					listed         : 0,
					private        : 0,
					collaborations : 0
				};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		packageTypes : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		}
	},
	components : { PackageCard },
	data() {
		return {
			entries           : [],
			search            : "",
			loading           : false,
			errors            : [],
			filter            : "listed",
			statusFilter      : "all",
			packageTypeFilter : "all",
			pagination        : {
				startRow  	: 0,
				maxRow    	: 0,
				records   	: 0,
				page      	: 1,
				totalPages	: 1
			},
		};
	},
	mounted(){
		this.$nextTick( () => {
			this.loadEntries( 1 );
		} );
	},
	computed : {},
	methods 	: {
		loadEntries( page ) {
			var app = this;
			var pageNum = page || 1;
			app.loading = true;
			app.$http.get( !app.isOrg ? `/api/v1/users/${this.user.username}/entries` : `/api/v1/organizations/${app.organization.slug}/entries`, {
				params : {
					filter     : app.sameUser ? app.filter : "listed",
					status     : app.statusFilter,
					type       : app.packageTypeFilter,
					search     : app.search,
					visibility : app.filter,
					page       : pageNum
				}
			}
			).then( function( response ) {
				app.loading = false;
				if ( response.error ) {
					app.entries = [];
					app.errors.push( response.data.messages );
					return;
				}
				app.entries = response.data.data;
				app.pagination 	= response.data.pagination;
			} )
				.catch( function( response ) {
					app.errors.push( response.data.messages );
				} );
		},
		debounceSearch : debounce( function() {
			this.loadEntries();
		}, 300 ),
		filterResults( filter ) {
			let app = this;
			app.filter = filter;
			app.loadEntries();
		},
		resetFilters() {
			let app = this;
			app.search = "";
			app.statusFilter = "all";
			app.packageTypeFilter = "all";
			app.loadEntries();
		},
		clearSearch() {
			this.search = "";
			this.loadEntries();
		}
	}
};

</script>

<style scoped lang="scss">
	#public-profile {
		.mt--80{
			margin-top: -80px;
		}
	}
</style>