<template>
	<div>
		<div v-click-outside="() => (display = false)">
			<div class="tw-hidden lg:tw-block">
				<button
					type="button"
					class="tw-group tw-w-full tw-bg-gray-100 tw-rounded-md tw-px-3.5 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
					id="options-menu"
					aria-expanded="false"
					aria-haspopup="true"
					@click="showLarge()"
				>
					<span
						v-if="!isOrg"
						class="tw-flex tw-w-full tw-justify-between tw-items-center"
					>
						<span
							class="tw-flex tw-min-w-0 tw-items-center tw-justify-between tw-space-x-3"
						>
							<Avatar
								:email="user.email"
								:px="40"
								:size="10"
							/>
							<span class="tw-flex-1 tw-min-w-0 tw-text-left">
								<span
									class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate tw-block"
								>
									{{ user.fullName }}
								</span>
								<span class="tw-text-gray-500 tw-text-sm tw-truncate">
									@{{ user.username }}
								</span>
							</span>
						</span>
						<!-- Heroicon name: solid/selector -->
						<svg
							class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
					<span
						v-else
						class="tw-flex tw-w-full tw-justify-between tw-items-center"
					>
						<span class="tw-flex tw-min-w-0 tw-items-center tw-justify-between tw-space-x-3">
							<svg
								class="tw-flex-shrink-0 tw-h-10 tw-w-10 tw-text-gray-400 group-hover:tw-text-gray-500"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
								/>
							</svg>
							<span class="tw-flex-1 tw-min-w-0 tw-text-left">
								<span class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate tw-block">
									Organization
								</span>
								<span class="tw-text-gray-500 tw-text-sm tw-truncate">
									{{ organization.name }}
								</span>
							</span>
						</span>
						<!-- Heroicon name: solid/selector -->
						<svg
							class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
				</button>
			</div>

			<div class="lg:tw-hidden">
				<button
					class="tw-max-w-xs tw-bg-white tw-flex tw-items-center tw-text-sm tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					id="user-menu"
					aria-haspopup="true"
					@click="showSmall()"
				>
					<span class="tw-sr-only">Open user menu</span>
					<div v-if="!isOrg">
						<Avatar
							:email="user.email"
							:px="40"
							:size="8"
						/>
					</div>
					<div v-else>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="tw-h-5 tw-w-5 tw-inline-block tw-rounded-full tw-border"
							viewBox="0 0 20 20"
							fill="none"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
							/>
						</svg>
					</div>
				</button>
			</div>

			<transition
				enter-active-class="tw-transition ease-out duration-200"
				enter-class="tw-opacity-0 tw-transform tw-scale-95"
				enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
				leave-active-class="tw-transition tw-ease-in tw-duration-75"
				leave-class="tw-opacity-100 tw-transform tw-scale-100"
				leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
			>
				<div v-if="display">
					<div
						:class="displayClass"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="options-menu"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<a
								href="/myaccount"
								class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
								title="My Account"
							>
								<svg
									v-if="!isOrg"
									xmlns="http://www.w3.org/2000/svg"
									class="tw-h-5 tw-w-5 tw-inline-block tw-mr-3"
									:class="{ 'tw-font-bold': !isOrg }"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
								<Avatar
									v-if="isOrg"
									:email="user.email"
									:px="40"
									:size="5"
									custom-class="tw-inline-block tw-mr-3"
								/>
								{{ user.username }}
							</a>

							<a
								v-for="org in orgs.orgs"
								:key="org.organizationId"
								:href="'/orgs/' + org.slug"
								class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
								:title="org.name"
							>
								<svg
									v-if="org.slug == organization.slug"
									xmlns="http://www.w3.org/2000/svg"
									class="tw-h-5 tw-w-5 tw-inline-block tw-mr-3"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
								<svg
									v-else
									xmlns="http://www.w3.org/2000/svg"
									class="tw-h-5 tw-w-5 tw-inline-block tw-mr-3"
									viewBox="0 0 20 20"
									fill="none"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
									/>
								</svg>
								{{ org.name }}
								<svg
									v-if="org.isComplimentary"
									class="tw-w-4 tw-h-4 tw-inline-block tw-text-yellow-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									v-tooltip="'Complimentary Organization'"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
									/>
								</svg>
							</a>
						</div>

						<div
							class="tw-py-1"
							role="none"
						>
							<a
								href="/myaccount/organizations"
								class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								Manage Organizations
							</a>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
	props : {
		user : {
			type     : Object,
			required : true
		},
		orgs : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	directives : { ClickOutside },
	data() {
		return {
			small :
				"tw-mx-3 tw-origin-top-right tw-absolute tw-right-0 tw-top-9 tw-w-64 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200",
			large :
				"tw-z-10 tw-mx-3 tw-origin-top tw-absolute tw-right-0 tw-left-0 tw-mt-1 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200 focus:tw-outline-none",
			displayClass : "",
			display      : false
		};
	},
	methods : {
		showSmall() {
			this.displayClass = this.small;
			this.display = !this.display;
		},
		showLarge() {
			this.displayClass = this.large;
			this.display = !this.display;
		}
	}
};
</script>
