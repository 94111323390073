<template>
	<div>
		<add-packages
			:organization="organization"
			:team="team"
			:show-slideover="showAddPackages"
			@interface="showAddPackages = $event"
		/>
		<div
			v-if="teamPackages.length == 0"
			class="tw-shadow tw-bg-white tw-p-3 tw-rounded-lg"
		>
			<div class="tw-px-4 tw-py-5 sm:tw-p-6">
				<p class="tw-text-md tw-font-bold tw-text-gray-500">
					No packages yet
				</p>
				<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
					<p class="tw-text-sm">
						Currently, there are no packages in this team.
					</p>
					<p
						v-if="canManagePackages"
						class="tw-text-sm"
					>
						Add packages
						<span
							class="tw-text-blue-600 hover:tw-text-blue-500 tw-cursor-pointer"
							@click="showAddPackages = true"
						>
							now!
						</span>
					</p>
				</div>
			</div>
		</div>
		<div
			v-else
			class="tw-mt-5"
		>
			<div class="tw-flex tw-gap-3">
				<label
					for="teamMembersSearch"
					class="tw-sr-only"
				>
					Search
				</label>
				<div class="tw-w-full tw-relative tw-rounded-md tw-shadow-sm">
					<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
						<!-- Heroicon name: solid/search -->
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
					<input
						v-model="search"
						name="teamMembersSearch"
						id="teamMembersSearch"
						type="search"
						class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						placeholder="Search"
					>
					<!-- Heroicon name: x -->
					<div
						v-if="search != ''"
						class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
					>
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
							@click="clearSearch"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>

				<div class="tw-flex-shrink-0">
					<button
						type="button"
						class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
						@click="resetFilters"
						v-tooltip="'Reset Filters'"
					>
						<!-- Heroicon name: solid/x -->
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="tw-sr-only">Reset Filters</span>
					</button>
				</div>
				<div class="tw-flex-shrink-0">
					<button
						v-if="canManagePackages"
						type="button"
						class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
						@click="showAddPackages = true"
					>
						Add Packages
					</button>
				</div>
			</div>

			<div
				v-if="filteredTeamPackages.length != 0 && !loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					<span v-if="filteredTeamPackages.length == teamPackages.length">
						Showing <span class="tw-font-bold">all</span> packages
					</span>
					<span v-else>
						{{ filteredTeamPackages.length }} {{ filteredTeamPackages.length == 1 ? "package" : "packages" }} found with keyword:
						<span class="tw-font-bold">{{ search }}</span>
					</span>
				</div>
				<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
					Total Packages: {{ teamPackages.length }}
				</div>
			</div>

			<div
				v-else-if="loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					<div
						class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
					>
						<div
							class="tw-btn-spinner tw-mt-1"
						/>
						<span class="tw-ml-2">Loading...</span>
					</div>
				</div>
			</div>

			<div class="tw-bg-white">
				<!-- Packages table (small breakpoint and up) -->
				<div class="tw-mt-3">
					<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
						<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
							<thead>
								<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										<span class="lg:tw-pl-2">Package</span>
									</th>
									<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center">
										Type
									</th>
									<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Last Updated
									</th>
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Publish Access
									</th>
									<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Status
									</th>
									<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
										Visibility
									</th>
									<th class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
								</tr>
							</thead>
							<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
								<tr
									v-if="filteredTeamPackages.length === 0 && !loading"
									class="tw-bg-gray-50"
								>
									<td
										colspan="7"
										class="tw-pb-6 tw-text-center"
									>
										<svg
											class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<p class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
											No packages found
										</p>
									</td>
								</tr>
								<tr
									v-for="(thisPackage,index) in filteredTeamPackages"
									is="PackageRow"
									:this-package="thisPackage"
									:organization-slug="organization.slug"
									:team-id="team.teamId"
									:can-manage-packages="canManagePackages"
									:key="thisPackage.teamEntryId+index"
									class="hover:tw-bg-gray-50"
								/>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PackageRow from "./PackageRow";
import AddPackages from "./AddPackages";

export default {
	props : {
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		team : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : {
		PackageRow,
		AddPackages
	},
	data() {
		return {
			menu            : [ { label: "Members" } ],
			memberToEdit    : {},
			showAddPackages : false,
			teamPackages    : this.team.entries,
			search          : "",
			loading         : false,
			error           : "",
			pagination      : {
				startRow  	: 0,
				maxRow    	: 0,
				records   	: 0,
				page      	: 1,
				totalPages	: 1
			}
		};
	},
	mounted(){
		var app = this;
	},
	computed : {
		filteredTeamPackages(){
			return this.teamPackages.filter( teamPackage => this.localSearch( teamPackage ) );
		},
		canManagePackages(){
			return this.member.role.role == "Super Admin" || this.member.role.role == "Admin";
		}
	},
	methods : {
		clearSearch() {
			let app = this;
			app.search = "";
		},
		resetFilters() {
			let app = this;
			app.search = "";
		},
		localSearch( teamPackage ) {
			var app = this;
			if ( ( teamPackage.entry.title.toLowerCase().includes( app.search.toLowerCase() ) || teamPackage.entry.slug.toLowerCase().includes( app.search.toLowerCase() ) ) || app.search == "" ) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>