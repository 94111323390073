<template>
	<div>
		<page-header
			v-if="canPublishNewPackages"
			:menu="menu"
			:primary-button="{ label: 'Create', type: 'button', url: '' }"
			:execute-method="true"
			:method="showModal"
		/>
		<page-header
			v-else
			:menu="menu"
		/>
		<new-package-modal
			v-if="showNewPackageModal"
			:is-org="isOrg"
			:organization="organization"
			:show-dialog="showNewPackageModal"
			@interface="showNewPackageModal = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div>
				<dl class="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-4">
					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-20 tw-cursor-pointer"
						:class="
							mainFilter == 'all'
								? 'tw-border-2 tw-bg-blue-200 tw-border-blue-600'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('all')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-sm tw-font-medium tw-truncate"
											:class="
												mainFilter == 'all'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500 '
											"
										>
											All Packages
										</dt>
										<dd>
											<div class="tw-text-2xl tw-font-bold tw-text-gray-900">
												{{ packagesCount.all }}
											</div>
										</dd>
									</dl>
								</div>
								<div class="tw-flex-shrink-0 tw-mt-3">
									<svg
										class="tw-h-10 tw-w-10 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>

					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-20 tw-cursor-pointer"
						:class="
							mainFilter == 'public'
								? 'tw-border-2 tw-bg-blue-200 tw-border-blue-600'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('public')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-sm tw-font-medium tw-truncate"
											:class="
												mainFilter == 'public'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500 '
											"
										>
											Public Packages
										</dt>
										<dd>
											<div class="tw-text-2xl tw-font-bold tw-text-gray-900">
												{{ packagesCount.public }}
											</div>
										</dd>
									</dl>
								</div>
								<div class="tw-flex-shrink-0 tw-mt-3">
									<svg
										class="tw-h-10 tw-w-10 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>

					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-20 tw-cursor-pointer"
						:class="
							mainFilter == 'private'
								? 'tw-border-2 tw-bg-blue-200 tw-border-blue-600'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('private')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-sm tw-font-medium tw-truncate"
											:class="
												mainFilter == 'private'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500 '
											"
										>
											Private Packages
										</dt>
										<dd>
											<div class="tw-text-2xl tw-font-bold tw-text-gray-900">
												{{ packagesCount.private }}
											</div>
										</dd>
									</dl>
								</div>
								<div class="tw-flex-shrink-0 tw-mt-3">
									<svg
										class="tw-h-10 tw-w-10 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
											clip-rule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>

					<div
						class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-h-20 tw-cursor-pointer"
						:class="
							mainFilter == 'collaborations'
								? 'tw-border-2 tw-bg-blue-200 tw-border-blue-600'
								: 'tw-bg-gray-100 hover:tw-bg-opacity-75'
						"
						@click="filterResults('collaborations')"
					>
						<div class="tw-px-4 tw-py-3 sm:tw-p-3">
							<div class="tw-flex tw-items-center">
								<div class="tw-ml-2 tw-w-0 tw-flex-1">
									<dl>
										<dt
											class="tw-text-sm tw-font-medium tw-truncate"
											:class="
												mainFilter == 'collaborations'
													? 'tw-font-bold tw-text-blue-600'
													: 'tw-text-gray-500 '
											"
										>
											Collaborated Packages
										</dt>
										<dd>
											<div class="tw-text-2xl tw-font-bold tw-text-gray-900">
												{{ packagesCount.collaborations }}
											</div>
										</dd>
									</dl>
								</div>
								<div class="tw-flex-shrink-0 tw-mt-3">
									<svg
										class="tw-h-10 tw-w-10 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</dl>
			</div>

			<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
				<div class="tw-col-span-6 sm:tw-col-span-3">
					<label
						for="packageSearch"
						class="tw-sr-only"
					>
						Search
					</label>
					<div class="tw-relative tw-rounded-md tw-shadow-sm">
						<div
							class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none"
						>
							<!-- Heroicon name: solid/search -->
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
						<input
							v-model="search"
							name="packageSearch"
							id="packageSearch"
							type="search"
							class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							@input="debounceSearch"
							placeholder="Search"
						/>
						<!-- Heroicon name: x -->
						<div
							v-if="search != ''"
							class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
						>
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
								@click="clearSearch"
							>
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div class="tw-col-span-6 sm:tw-col-span-3">
					<div class="tw-flex tw-gap-3">
						<select
							v-model="statusFilter"
							id="status"
							name="status"
							class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							@change="loadPackages()"
						>
							<option value="all">
								All Statuses
							</option>
							<option value="published">
								Published
							</option>
							<option value="unpublished">
								Unpublished
							</option>
						</select>
						<select
							v-model="packageTypeFilter"
							id="type"
							name="type"
							class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							@change="loadPackages()"
						>
							<option value="all">
								All Types
							</option>
							<option
								v-for="type in packageTypes"
								:key="type.typeSlug"
								:value="type.typeSlug"
							>
								{{ type.typeName }}
							</option>
						</select>
						<button
							type="button"
							class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
							@click="resetFilters"
							v-tooltip="'Reset Filters'"
						>
							<!-- Heroicon name: solid/x -->
							<svg
								class="tw-h-5 tw-w-5 tw-text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="tw-sr-only">Reset Filters</span>
						</button>
					</div>
				</div>
			</div>

			<div
				v-if="packages.length != 0 && !loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					Showing
					<span
						class="tw-font-bold"
					>{{ mainFilter }}
						{{ statusFilter != "all" ? " " + statusFilter : "" }}</span>
					packages in {{ packageTypeFilter != "all" ? "the " : "" }}
					<span class="tw-font-bold">{{ packageTypeFilter }} </span> type{{
						packageTypeFilter == "all" ? "s" : ""
					}}
				</div>
				<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
					Page: {{ pagination.page }}
				</div>
			</div>

			<div
				v-else-if="loading"
				class="tw-flex tw-mt-3"
			>
				<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
					<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
						<div class="tw-btn-spinner tw-mt-1" />
						<span class="tw-ml-2">Loading...</span>
					</div>
				</div>
			</div>

			<div class="tw-bg-white">
				<!-- Packages table (small breakpoint and up) -->
				<div class="tw-overflow-x-scroll lg:tw-overflow-x-hidden tw-mt-3">
					<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
						<table
							class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100"
						>
							<thead>
								<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
									<th
										class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										<span class="lg:tw-pl-2">Package</span>
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
									>
										Type
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Collaborators
									</th>
									<th
										class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Last updated
									</th>
									<th
										class="md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Status
									</th>
									<th
										class="md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									>
										Visibility
									</th>
									<th
										class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									/>
								</tr>
							</thead>
							<tbody
								class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
							>
								<!-- TODO: Add loader when changing filters and loading packages -->
								<tr
									v-if="false"
									class="tw-bg-gray-50"
								>
									<td
										colspan="7"
										class="tw-pb-6 tw-text-center"
									>
										<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
											<div class="tw-btn-spinner tw-mt-1" />
											<span class="tw-ml-2">Loading...</span>
										</div>
									</td>
								</tr>
								<tr
									v-else-if="packages.length === 0 && !loading"
									class="tw-bg-gray-50"
								>
									<td
										colspan="7"
										class="tw-pb-6 tw-text-center"
									>
										<svg
											class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
										<p
											class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate"
										>
											No Packages found
										</p>
									</td>
								</tr>
								<tr
									v-for="thisPackage in packages"
									is="PackageRow"
									:this-package="thisPackage"
									:key="thisPackage.entryID"
									:is-org="isOrg"
									:can-publish-new-packages="canPublishNewPackages"
									:can-manage-packages="canManagePackages"
									:organization="organization"
									class="hover:tw-bg-gray-50"
								/>
							</tbody>
						</table>
					</div>
				</div>

				<Pagination
					:total-pages="pagination.totalPages"
					:total-records="pagination.records"
					:max-rows="pagination.maxRow"
					:current-page="pagination.page"
					:loader="loadPackages"
					:loading="loading"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import NewPackageModal from "./NewPackageModal";
import PackageRow from "./PackageRow";
import debounce from "lodash/debounce";

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		member : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return { name: "" };
			}
		},
		packagesCount : {
			type     : Object,
			required : true,
			default  : function() {
				return {
					all            : 0,
					public         : 0,
					private        : 0,
					collaborations : 0
				};
			}
		},
		packageTypes : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : {
		PackageRow,
		NewPackageModal
	},
	data() {
		return {
			menu                : [ { label: "Packages" } ],
			packages            : [],
			search              : "",
			loading             : false,
			error               : "",
			mainFilter          : "all",
			statusFilter        : "all",
			packageTypeFilter   : "all",
			showNewPackageModal : false,
			notification        : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			},
			pagination : {
				startRow   : 0,
				maxRow     : 0,
				records    : 0,
				page       : 1,
				totalPages : 1
			}
		};
	},
	computed : {
		canPublishNewPackages() {
			return (
				!this.isOrg ||
				( this.isOrg &&
					( this.member.role.role == "Super Admin" ||
						this.member.canCreateNewPackages ) )
			);
		},
		canManagePackages() {
			return (
				!this.isOrg || ( this.isOrg && this.member.role.role == "Super Admin" )
			);
		}
	},
	mounted() {
		var app = this;
		app.loadPackages();
	},
	methods : {
		loadPackages( page = 1 ) {
			var app = this;
			var pageNum = page;
			app.loading = true;
			var url = !app.isOrg
				? `/api/v1/users/${app.user.username}/entries`
				: `/api/v1/organizations/${app.organization.slug}/entries`;
			app.$http
				.get( url, {
					params : {
						filter : app.mainFilter,
						status : app.statusFilter,
						type   : app.packageTypeFilter,
						search : app.search,
						page   : pageNum
					}
				} )
				.then( function( response ) {
					app.loading = false;
					if ( response.data.error ) {
						app.packages = [];
						app.error = response.data.messages;
						return;
					}
					app.packages = response.data.data;
					app.pagination = response.data.pagination;
				} )
				.catch( function( response ) {
					app.error = response.messages;
				} );
		},
		debounceSearch : debounce( function() {
			let app = this;
			app.loadPackages();
		}, 300 ),
		filterResults( filter ) {
			let app = this;
			app.mainFilter = filter;
			app.loadPackages();
		},
		clearSearch() {
			let app = this;
			app.search = "";
			app.loadPackages();
		},
		resetFilters() {
			let app = this;
			app.search = "";
			app.statusFilter = "all";
			app.packageTypeFilter = "all";
			app.loadPackages();
		},
		showModal() {
			this.showNewPackageModal = true;
		}
	}
};
</script>
