<template>
	<div>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-mt-5 tw-gap-3 tw-grid tw-grid-cols-6">
			<div class="tw-col-span-6 sm:tw-col-span-3">
				<label
					for="versionsSearch"
					class="tw-sr-only"
				>
					Search
				</label>
				<div class="tw-relative tw-rounded-md tw-shadow-sm">
					<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
						<!-- Heroicon name: solid/search -->
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
					<input
						v-model="keyword"
						name="versionsSearch"
						id="versionsSearch"
						type="search"
						class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						@input="debounceSearch"
						placeholder="Search"
					>
					<!-- Heroicon name: x -->
					<div
						v-if="keyword != ''"
						class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
					>
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
							@click="clearSearch"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
			<div class="tw-col-span-6 sm:tw-col-span-3">
				<div class="tw-flex tw-gap-3">
					<select
						v-model="filter"
						id="status"
						name="status"
						class="tw-block focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						@change="loadVersions()"
					>
						<option value="all">
							All Versions
						</option>
						<option value="stable">
							Stable
						</option>
						<option value="snapshot">
							Snapshot
						</option>
					</select>
					<button
						type="button"
						class="tw-inline-flex tw-justify-center tw-px-3.5 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
						@click="resetFilters"
						v-tooltip="'Reset Filters'"
					>
						<!-- Heroicon name: solid/x -->
						<svg
							class="tw-h-5 tw-w-5 tw-text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="tw-sr-only">Reset Filters</span>
					</button>
					<a :href="!isOrg ? `/myaccount/package/${thisPackage.slug}/newVersion` : `/orgs/${organization.slug}/package/${thisPackage.slug}/newVersion`">
						<button
							type="button"
							class="tw-w-48 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
						>
							Create New Version
						</button>
					</a>
				</div>
			</div>
		</div>

		<div
			v-if="versions.length != 0 && !loading"
			class="tw-flex tw-mt-3"
		>
			<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
				Showing <span class="tw-font-bold">{{ filter }}</span> versions
			</div>
			<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
				Page: {{ pagination.page }}
			</div>
		</div>

		<div
			v-else-if="loading"
			class="tw-flex tw-mt-3"
		>
			<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
				<div
					class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
				>
					<div
						class="tw-btn-spinner tw-mt-1"
					/>
					<span class="tw-ml-2">Loading...</span>
				</div>
			</div>
		</div>

		<div class="tw-bg-white">
			<!-- Packages table (small breakpoint and up) -->
			<div class="tw-mt-3">
				<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
					<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
						<thead>
							<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
								<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
									<span class="lg:tw-pl-2">Version</span>
								</th>
								<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
									Created
								</th>
								<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase">
									Last Update
								</th>
								<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
									User
								</th>
								<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
									Stable
								</th>
								<th class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
							</tr>
						</thead>
						<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
							<tr
								v-if="versions.length === 0 && !loading"
								class="tw-bg-gray-50"
							>
								<td
									colspan="7"
									class="tw-pb-6 tw-text-center"
								>
									<svg
										class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
										/>
									</svg>
									<p class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
										No versions were found
									</p>
								</td>
							</tr>
							<tr
								v-for="(version,index) in versions"
								is="VersionRow"
								:current-version="currentVersion.version"
								:version="version"
								:this-package="thisPackage"
								:organization="organization"
								:is-org="isOrg"
								:key="version.version+index"
								class="hover:tw-bg-gray-50"
							/>
						</tbody>
					</table>
				</div>
			</div>

			<Pagination
				:total-pages="pagination.totalPages"
				:total-records="pagination.records"
				:max-rows="pagination.maxRow"
				:current-page="pagination.page"
				:loader="loadVersions"
				:loading="loading"
			/>
		</div>
	</div>
</template>

<script>
import VersionRow from "./VersionRow";
import debounce from "lodash/debounce";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { VersionRow },
	data() {
		return {
			versions       : [],
			currentVersion : {},
			loading        : false,
			error          : "",
			keyword        : "",
			filter         : "all",
			notification   : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			},
			pagination : {
				startRow  	: 0,
				maxRow    	: 0,
				records   	: 0,
				page      	: 1,
				totalPages	: 1
			}
		};
	},
	mounted(){
		var app = this;
		app.currentVersion = app.thisPackage.latestVersion;
		app.loadVersions();
	},
	methods : {
		loadVersions( page = 1 ) {
			let app = this;
			app.loading = true;
			var pageNum = page || 1;
			var params = { filter: app.filter, page: pageNum };
			if ( app.keyword != "" ){
				params[ "keyword" ] = app.keyword;
			}
			app.$http.get(
				"/api/v1/entry/" + app.thisPackage.slug + "/versions",
				{ params: params }
			)
				.then( response => {
					app.loading = false;
					if ( !response.data.data.error ) {
						app.versions = response.data.data;
						app.pagination 	= response.data.pagination;
					} else {
						app.error = "The versions list couldn't be retrieved. Please try again";
					}
				} )
				.catch( err => {
					app.loading = false;
					app.error = "The versions list couldn't be retrieved. Please try again";
					// Show error
				} );
		},
		debounceSearch : debounce( function() {
			let app = this;
			app.loadVersions();
		}, 300 ),
		filterResults( filter ) {
			let app = this;
			app.filter = filter;
			app.loadVersions();
		},
		clearSearch() {
			let app = this;
			app.keyword = "";
			app.loadVersions();
		},
		resetFilters() {
			let app = this;
			app.keyword = "";
			app.filter = "all";
			app.loadVersions();
		}
	}
};
</script>