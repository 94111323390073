<template>
	<div class="tw-px-6 tw-py-3">
		<label
			for="title"
			class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
		>
			Package Owner
		</label>

		<div class="tw-flex tw-justify-between">
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
						<span class="tw-inline-block tw-relative">
							<Avatar
								:email="thisPackage.user.email"
								:px="40"
								:size="10"
							/>
						</span>
					</div>
				</div>
				<div class="tw-ml-4">
					<a
						:href="'/@'+thisPackage.user.username"
						class="tw-inline-block"
					>
						<div class="tw-text-sm tw-font-medium tw-text-gray-900">
							{{ thisPackage.user.fullName }}
						</div>
						<div class="tw-text-sm tw-text-gray-500">
							@{{ thisPackage.user.username }}
						</div>
					</a>
				</div>
			</div>

			<span
				class="tw-ml-4 tw-flex-shrink-0"
			>
				<span
					class="tw-cursor-pointer tw-text-blue-600 hover:tw-text-blue-500 tw-font-medium tw-text-sm"
					@click="toggleTransfer"
				>
					{{ !transferingPackage ? 'Transfer Package' : 'Cancel' }}
				</span>
			</span>
		</div>

		<transition
			enter-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
			enter-class="tw-translate-x-full"
			enter-to-class="tw-translate-x-0"
			leave-active-class="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
			leave-class="tw-translate-x-0"
			leave-to-class="tw-translate-x-full"
		>
			<div
				v-if="transferingPackage"
				class="tw-mt-3 tw-text-sm tw-space-x-2 tw-text-gray-500"
			>
				<svg
					class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 tw-inline-block"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke="currentColor"
					fill="none"
					aria-hidden="true"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<span>
					Use the input below to change the owner of this package.
				</span>

				<multiselect
					v-model="newOwner"
					id="newOwner"
					class="tw-mt-3"
					placeholder="Search for a user"
					open-direction="bottom"
					track-by="username"
					label="username"
					@search-change="debounceFindUsers"
					:options="users"
					:allow-empty="false"
					:internal-search="false"
					:loading="loading"
				>
					<template
						slot="singleLabel"
						slot-scope="{ option: { username, fname, lname, avatarLink } }"
					>
						<img
							:src="avatarLink"
							:alt="`${fname} ${lname}`"
							class="tw-rounded-full tw-mr-3 tw-inline-block"
						>
						{{ username }} ({{ fname }} {{ lname }})
					</template>
					<template
						slot="option"
						slot-scope="{ option: { username, fname, lname, avatarLink } }"
					>
						<img
							:src="avatarLink"
							:alt="`${fname} ${lname}`"
							class="tw-rounded-full tw-mr-3 tw-inline-block"
						>
						{{ username }} ({{ fname }} {{ lname }})
					</template>
				</multiselect>

				<div class="tw-space-x-3 tw-flex tw-justify-end tw-mt-3">
					<button
						v-if="newOwner"
						type="button"
						@click.prevent="newOwner = null"
						class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
					>
						Clear Lookup
					</button>
					<action-button
						label="Save"
						:loading="loading"
						:method="changeOwner"
						:disabled="!newOwner || loading"
					/>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import _ from "lodash";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		transferingPackage : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { Multiselect },
	data() {
		return {
			users    : [],
			newOwner : null,
			loading  : false
		};
	},
	methods : {
		changeOwner() {
			var app = this;
			app.loading = true;
			app.$parent.$parent.notification.show = false;
			app.$http.post(
				"/admin/packages/changeOwner", {
					entryID  : app.thisPackage.entryID,
					username : app.newOwner.username
				}
			)
				.then( response => {
					app.loading = false;
					if ( !response.data.error ) {
						app.newOwner = null;
						app.thisPackage.user = response.data.data;
						app.$parent.$parent.notification = {
							type    : "success",
							title   : "Success",
							message : "Package Ownership Changed Successfully!",
							show    : true
						};
						app.$parent.updateFlag();
					} else {
						console.error( response );
						app.$parent.errors.push( "There was an error changing the package ownership. Please try again" );
					}
				} )
				.catch( err => {
					console.error( err );
					app.loading = false;
				} );
		},
		debounceFindUsers : debounce( function( query ) {
			var app = this;
			app.findUsers( query );
		}, 300 ),
		findUsers( query="" ){
			let app = this;
			app.$parent.errors = [];
			app.loading = true;

			app.$http.get(
				"/api/v1/users",
				{ params: { searchTerm: query } }
			)
				.then( response => {
					app.users = response.data.data.results;
					app.loading = false;
				} )
				.catch( err => {
					app.loading = false;
					app.$parent.errors.push( "The users list couldn't be retrieved. Please try again" );
				} );
		},
		toggleTransfer() {
			var app = this;
			app.$parent.transferingPackage = !app.$parent.transferingPackage;
		}
	}
};
</script>