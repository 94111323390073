<template>
	<form class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-rounded-lg tw-overflow-y-scroll">
		<div class="tw-flex-1">
			<!-- Header -->
			<div class="tw-px-4 tw-py-6 tw-bg-gray-100 sm:tw-px-6 tw-border-b tw-border-gray-200">
				<div class="tw-flex tw-items-start tw-justify-between tw-space-x-3">
					<div class="tw-space-y-1">
						<h2
							id="slide-over-heading"
							class="tw-text-lg tw-font-medium tw-text-gray-900"
						>
							{{ editMode ? "Edit" : "Create" }} Organization
						</h2>
						<p
							v-if="!editMode"
							class="tw-text-sm tw-text-gray-500"
						>
							Create a new organization where you can manage your members and teams
						</p>
						<p
							v-else
							class="tw-text-sm tw-text-gray-500"
						>
							Update your organization name or description
						</p>
					</div>
					<div class="tw-h-7 tw-flex tw-items-center">
						<button
							type="button"
							class="tw-bg-gray-100 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
							@click="updateFlag"
						>
							<span class="tw-sr-only">Close panel</span>
							<!-- Heroicon name: outline/x -->
							<svg
								class="tw-h-6 tw-w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>

			<!-- Divider container -->
			<div class="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
				<!-- Organization Name -->
				<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
					<div>
						<label
							for="currentPassword"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Name
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<input
							v-model="name"
							class="tw-block tw-w-full tw-shadow-sm sm:tw-text-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
							type="text"
							name="name"
						>
					</div>
				</div>

				<!-- Organization Slug -->
				<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
					<div>
						<label
							for="currentPassword"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Slug
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<input
							v-model="slug"
							class="tw-block tw-w-full tw-shadow-sm sm:tw-text-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-gray-300 tw-rounded-md"
							:class="{ 'tw-pr-10 tw-border-red-300 tw-text-red-900 tw-placeholder-red-300 focus:tw-outline-none focus:tw-ring-red-500 focus:tw-border-red-500' : !validSlug && !editMode, 'tw-pr-10 tw-border-green-500 focus:tw-outline-none focus:tw-ring-green-700 focus:tw-border-green-900' : validSlug && slug != '' && !editMode, 'tw-opacity-75' : editMode }"
							type="text"
							name="name"
							@input="debounceSlugCheck"
							:disabled="editMode ? true : false"
						>
						<p
							v-if="!validSlug"
							class="tw-mt-2 tw-text-sm tw-text-red-600"
						>
							The organization slug is not valid or it's already in use.
						</p>
					</div>
				</div>

				<!-- Organization Description -->
				<div class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
					<div>
						<label
							for="description"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Description
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<textarea
							v-model="description"
							id="description"
							name="description"
							rows="5"
							placeholder="Description"
							class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
						/>
					</div>
				</div>

				<!-- If admin, then enable the ability to create a complimentary org -->
				<div
					v-if="isAdmin"
					class="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5"
				>
					<div>
						<label
							for="description"
							class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
						>
							Complimentary?
						</label>
					</div>
					<div class="sm:tw-col-span-2">
						<div class="tw-col-span-6 tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500">
							<input
								v-model="isComplimentary"
								id="isComplimentary"
								name="isComplimentary"
								type="checkbox"
								class="focus:tw-ring-blue-500 tw-h-5 tw-w-5 tw-text-blue-600 tw-border-gray-300 tw-rounded"
								value="true"
							>
							<span>
								A complimentary organization gives you the ability to create unlimited members, teams and manage the organization for free. This feature is only for admins.
							</span>
						</div>
					</div>
				</div>
			</div>
			<Alert
				class="tw-mx-3 tw-mb-3"
				type="error"
				:messages="errors"
			/>
		</div>

		<!-- Action buttons -->
		<div class="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-bg-gray-100 tw-py-5 sm:tw-px-6">
			<div class="tw-space-x-3 tw-flex tw-justify-end">
				<button
					type="button"
					@click="updateFlag"
					class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
				>
					Cancel
				</button>
				<action-button
					:label="editMode ? 'Save' : 'Create'"
					:loading="loading"
					:method="saveOrganization"
					:disabled="loading || (!editMode && !validatedSlug)"
				/>
			</div>
		</div>
	</form>
</template>

<script>
import debounce from "lodash/debounce";

export default {
	props : {
		editMode : {
			type     : Boolean,
			required : false,
			default  : false
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isAdmin : {
			type     : Boolean,
			required : true,
			default  : false
		}
	},
	data() {
		return {
			name            : "",
			slug            : "",
			description     : "",
			isComplimentary : false,
			validSlug       : true,
			loading         : false,
			errors          : []
		};
	},
	watch : {
		showSlideover( newVal ) {
			if ( newVal != this.show ) {
				this.showPanel = newVal;
			}
		}
	},
	computed : {
		validatedSlug() {
			return this.slug != "" && this.validSlug;
		}
	},
	mounted(){
		if ( this.editMode ){
			this.populateItem();
		}
	},
	methods : {
		populateItem() {
			let app = this;
			app.name = app.organization.name;
			app.slug = app.organization.slug;
			app.description = app.organization.description;
			app.isComplimentary = app.organization.isComplimentary;
		},
		debounceSlugCheck : debounce( function() {
			this.slugCheck();
		}, 500 ),
		slugCheck() {
			let app = this;
			app.errors = [];
			app.loading = true;
			app.validSlug = true;
			app.$http.get( "/myaccount/organizations/isValidSlug/"+ app.slug )
				.then( ( response ) => {
					if ( response.data.error ) {
						app.validSlug = false;
						app.errors = response.data.messages;
					}
					app.loading = false;
				} )
				.catch( error => {
					app.errors.push( error );
					app.loading = false;
				} );
		},
		saveOrganization() {
			let app = this;
			app.errors = [];
			app.loading = true;
			var url = app.editMode ? "/myaccount/organizations/update" : "/myaccount/organizations/create";
			app.$http.post( url ,
				{
					name            : app.name,
					slug            : app.slug,
					description     : app.description,
					isComplimentary : app.isComplimentary
				}
			)
				.then( ( response ) => {
					// relocate to the organizations billing page
					if ( !response.data.error ){
						app.updateFlag();
						if ( app.editMode ) {
							app.updateUI( response.data.data );
							app.$parent.$parent.notification = {
								type    : "success",
								title   : "Success",
								message : "The organization was updated successfully!",
								show    : true
							};
						} else {
							window.location = "/orgs/"+app.slug+"/settings/billing";
						}
					} else {
						console.log( response );
						app.loading = false;
						app.errors.push( response.data.messages[0] );
					}
				} )
				.catch( error => {
					console.log( error );
					app.errors.push( error.response.data.messages[0] );
					app.loading = false;
				} );
		},
		updateUI( organization ) {
			var app = this;
			app.$parent.$parent.orgs.forEach( function( value, index, arr ){
				if ( value.organizationId == organization.organizationId ) {
					// Remove row
					app.$parent.$parent.orgs.splice( index, 1 );
					// Add it back with the updated values
					app.$parent.$parent.orgs.splice( index, 0, organization );
				}
			} );
		},
		updateFlag : function() {
			this.showPanel = false;
			this.$parent.updateFlag();
		}
	}
};
</script>