<template>
	<button
		@click="emitGlobalClickEvent()"
		class="tw-px-4 tw-border-r tw-border-gray-200 tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-500 lg:tw-hidden"
	>
		<span class="tw-sr-only">Open sidebar</span>
		<!-- Heroicon name: menu-alt-1 -->
		<svg
			class="tw-h-6 tw-w-6"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			aria-hidden="true"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M4 6h16M4 12h8m-8 6h16"
			/>
		</svg>
	</button>
</template>

<script>
import { EventBus } from "../../app.js";

export default {
	props   : { sidebar: { required: true, type: String } },
	methods : {
		emitGlobalClickEvent() {
			switch ( this.sidebar ) {
			case "user":
				EventBus.$emit( "openUserSideBar" );
				break;
			case "org":
				EventBus.$emit( "openOrgSideBar" );
				break;
			}
		}
	}
};
</script>
