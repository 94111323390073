<template>
	<tr>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
						<span class="tw-inline-block tw-relative">
							<a
								:href="'/@'+member.user.username"
								class="tw-inline-block"
							>
								<Avatar
									:email="member.user.email"
									:px="40"
									:size="10"
								/>
							</a>
						</span>
					</div>
				</div>
				<div class="tw-ml-4">
					<div class="tw-text-sm tw-font-medium tw-text-gray-900">
						<!-- Badge -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="tw-inline-block tw-h-3 tw-w-3 tw-rounded-full tw-ring-2 tw-ring-white"
							:class="{ 'tw-bg-green-500' : member.isAccepted, 'tw-bg-yellow-400' : !member.isAccepted }"
							fill="none"
							viewBox="0 0 24 24"
							stroke="white"
							v-tooltip="member.isAccepted ? 'Active Member' : 'Pending Invite'"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								:d="member.isAccepted ? 'M5 13l4 4L19 7' : ''"
							/>
						</svg>
						{{ member.user.fullName }}
					</div>
					<div class="tw-text-sm tw-text-gray-500">
						@{{ member.user.username }}
					</div>
				</div>
			</div>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			{{ member.role.role }}
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span
				:class="member.canCreateNewPackages ? 'tw-bg-green-500' : 'tw-bg-red-500'"
				class="tw-w-3 tw-h-3 tw-mr-4 tw-rounded-full tw-inline-block tw-mx-auto tw-cursor-pointer"
				v-tooltip="member.canCreateNewPackages ? 'Yes' : 'No'"
			/>
		</td>
		<td class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
			<span
				v-if="member.teams.length == 0"
				class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800"
			>
				0
			</span>
			<a
				v-for="team in member.teams"
				:key="team.teamId"
				:href="'/orgs/'+organization.slug+'/teams/manage/'+team.teamId"
				class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800 hover:tw-text-gray-700"
			>
				{{ team.name }}
			</a>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(member.createdDate)"
		>
			{{ member.createdDate | formatDate }}
		</td>
		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<a
								:href="'/@'+member.user.username"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: eye -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
									<path
										fill-rule="evenodd"
										d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
										clip-rule="evenodd"
									/>
								</svg>
								View Public Profile
							</a>
							<span
								v-if="canChangeRole"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="showEditMember()"
							>
								<!-- Heroicon name: solid/pencil-alt -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
									<path
										fill-rule="evenodd"
										d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
										clip-rule="evenodd"
									/>
								</svg>
								Edit Member
							</span>
						</div>
						<div
							v-if="canManageBilling && !member.isOwner"
							class="tw-py-1"
							role="none"
						>
							<span
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRemovalDialog"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								Remove from Org
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		organization : {
			type     : Object,
			required : true
		},
		member : {
			type     : Object,
			required : true
		},
		user : {
			type     : Object,
			required : true
		},
		canManageBilling : {
			type     : Boolean,
			required : true,
			default  : false
		},
		canChangeRole : {
			type     : Boolean,
			required : true,
			default  : false
		}
	},
	data() {
		return { showDropdown: false };
	},
	directives : { ClickOutside },
	computed   : {},
	methods    : {
		showEditMember() {
			this.$parent.memberToEdit = this.member;
			this.$parent.showEditMember = true;
		},
		openRemovalDialog() {
			var app = this;
			app.$parent.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to remove this member from the org. This will affect your current billing.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/orgs/${app.organization.slug}/members/delete/${app.member.orgMemberId}`
					)
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.updateUI( app.member.orgMemberId );
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} ).catch( error => {
							app.loading = false;
							app.$parent.errors = "Oops there was an error deleting the member. Please try again";
						} );
				}
			} );
		},
		updateUI( orgMemberId ) {
			var app = this;
			app.$parent.orgMembers = app.$parent.orgMembers.filter(
				m => m.orgMemberId !== orgMemberId
			);
		}
	}
};
</script>
